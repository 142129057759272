import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
  EyeFill,
  PencilSquare,
  PlusCircle,
  Trash3Fill,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import * as Actions from "../../../constants/baseUrl";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import CustomLoader from "../../../components/loader/loader";

const MyGroups = () => {
  const myToken = localStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  // Get data
  const getGroups = () => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}groups`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setGroups(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getGroups();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}groups/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                getGroups();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");

  return (
    <div className="my-groups mb-4">
      {loading ? (
        <CustomLoader />
      ) : (
        <Row className="align-items-center mx-0">
          <Col
            md={12}
            className="heading-text ps-0 d-flex justify-content-between gap-1 align-items-start"
          >
            <div>
              <h4 className="theme-text-2">My Groups</h4>
              <p>
                Check out your {demosAlias ? demosAlias : "demos"} and{" "}
                {coursesAlias ? coursesAlias : "courses"} groups.
              </p>
            </div>
            <div className="d-flex justify-content-md-end px-0 gap-2 text-capitalize">
              <Link
                className="add-button btn d-flex align-items-center"
                to="/groups/add"
              >
                <PlusCircle />
                <span className="d-lg-block text-nowrap d-none">
                  &nbsp; Create group
                </span>
              </Link>
            </div>
          </Col>
          <Col md={12} className="my-3">
            {groups?.length > 0 ? (
              <Table className="striped" bordered responsive>
                <thead>
                  <tr>
                    <th className="bg-theme bg-opacity-50 text-light">
                      Group Name
                    </th>
                    <th className="bg-theme bg-opacity-50 text-light action-wrap">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {groups?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/groups/items/${item.id}`}
                          className="btn-link theme-text text-center me-1"
                        >
                          {item.title}
                        </Link>
                      </td>
                      <td
                        className="d-flex align-items-center justify-content-center gap-2"
                      >
                        <Link
                          to={`/groups/items/${item.id}`}
                          className="btn-link theme-text-2 text-center me-1"
                          title="See list"
                        >
                          <EyeFill />
                        </Link>
                        <Link
                          to={`/groups/edit/${item.id}`}
                          className="btn-link theme-text text-center me-1"
                          title="Edit list"
                        >
                          <PencilSquare />
                        </Link>
                        <Link
                          to={"#"}
                          className="btn-link text-danger text-center p-0"
                          title="Delete list"
                          onClick={() => deleteItem(item.id)}
                        >
                          <Trash3Fill />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No Groups Founds...</p>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MyGroups;
