import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import {
  Filter,
  PencilSquare,
  PlusCircle,
  Share,
  Trash,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import FilterChips from "../../components/filterSidebar/filterChips";
import FilterSidebar from "../../components/filterSidebar/filterSidebar";
import CustomLoader from "../../components/loader/loader";
import RegisterModal from "../../components/register/registerModal";
import SocialShare from "../../components/share/socialShare";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import COURSEIMG from "../../img/course.png";
import "./cards.css";
import { toast } from "react-toastify";

const Demos = () => {
  const entities = "demos";

  const navigate = useNavigate();

  const myToken = localStorage.getItem("token");
  const isValidUser = localStorage.getItem("isValidUser");
  const userData = useUserData();
  const role = userData.role?.toLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const currentDomain = window.location.hostname;

  const siteName = SubDomainRouter("title");
  const demosAlias = SubDomainRouter("demosAlias");
  const uppercaseTitle = demosAlias.toLocaleUpperCase();
  const defaultRegisterForm = SubDomainRouter("defaultRegisterForm");

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [smFilterMenu, setSmFilterMenu] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(8);
  const [modalData, setModalData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [entityObj, setEntityObj] = useState({
    type: "demo",
    searchTerm: "",
    country_type: [],
    subcategory: [],
    star_rating_type: [],
    category: [],
    subcategorylevel1: [],
    entity_types: [],
    subcategorylevel2: [],
    subcategorylevel3: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    type: "demo",
    searchTerm: "",
    country_type: [],
    subcategory: [],
    star_rating_type: [],
    category: [],
    entity_types: [],
    subcategorylevel1: [],
    subcategorylevel2: [],
    subcategorylevel3: [],
  });

  const ifFilterEmpty =
    entityObj?.country_type == "" &&
    entityObj?.subcategorylevel1 == "" &&
    entityObj?.category == "" &&
    entityObj?.subcategory == "" &&
    entityObj?.subcategorylevel2 == "" &&
    entityObj?.star_rating_type == "" &&
    entityObj?.subcategorylevel3 == "" &&
    entityObj?.searchTerm?.trim();

  const populateList = async () => {
    setLoadingFilter(true);
    try {
      if (ifFilterEmpty) {
        const response = await axios.get(`${Actions.BASE_URL}${entities}`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        // Sort data  in ascending order
        const sortedData = response.data.sort((a, b) => {
          const dateDiff = moment(a.start_date).diff(moment(b.start_date));

          // If the dates are equal, compare based on time
          if (dateDiff === 0) {
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          }

          return dateDiff;
        });

        setItems(sortedData);
        setLoading(false);
      } else {
        const response = await axios.post(
          `${Actions.BASE_URL}Search/filters`,
          entityObj,
          {
            headers: {
              Authorization: `Bearer ${myToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        // Sort data  in ascending order
        const sortedData = response.data?.demos?.sort((a, b) => {
          const dateDiff = moment(a.start_date).diff(moment(b.start_date));

          // If the dates are equal, compare based on time
          if (dateDiff === 0) {
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          }

          return dateDiff;
        });

        setItems(sortedData);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityObj]);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}${entities}/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                populateList();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  // Define regular expressions for filtering
  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= items.length
          ? prevDisplayedCards + 4
          : items.length
      );
    };

    return (
      <div className="d-flex mt-5 pt-md-5 pt-3">
        <FilterSidebar
          filteringFrom="demo"
          setEntityObj={setEntityObj}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setSmFilterMenu={setSmFilterMenu}
          smFilterMenu={smFilterMenu}
        />
        <div className="overlay" onClick={() => setSmFilterMenu(false)}></div>
        <Container fluid="lg" className="px-lg-4">
          <Row className="my-4 justify-content-md-start justify-content-center">
            <Col className="mb-3" lg={10} sm={8}>
              <h3
                id="tabelLabel"
                className="pageSubHeading mb-0 text-sm-start text-center text-capitalize"
              >
                Upcoming {demosAlias ? demosAlias : "demos"}
              </h3>
            </Col>
            {role === "admin" ||
            role === "siteadmin" ||
            role === "superadmin" ||
            role === "trainer" ||
            role === "training-coordinator" ? (
              <Col
                lg={2}
                sm={4}
                className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
              >
                <Link
                  to={`/${entities}/add`}
                  type="button"
                  className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
                >
                  <PlusCircle /> &nbsp; Add
                </Link>
              </Col>
            ) : null}
            <FilterChips selectedFilters={selectedFilters} />
            <Col
              md={12}
              className="d-flex align-items-sm-center justify-content-between align-items-start"
            >
              <Button
                variant="link"
                className="filterToggle ms-auto p-0 text-right ms-auto d-lg-none me-lg-0 me-2 d-flex align-items-center justify-content-center btn bg-transparent mb-2"
                onClick={() => setSmFilterMenu(!smFilterMenu)}
              >
                <Filter />
              </Button>
            </Col>

            {loadingFilter ? (
              <CustomLoader />
            ) : (
              <>
                {items?.length !== 0 ? (
                  items.slice(0, displayedCards)?.map((item) => (
                    <Col
                      xxl={3}
                      xl={4}
                      md={6}
                      sm={10}
                      className="mt-2 mb-3"
                      key={item.id}
                    >
                      <Card className="demoCard">
                        <div className="itemImg">
                          <Link
                            to={`/demos/details/${item.id}`}
                            className="linkImg"
                          >
                            <img
                              src={
                                item.listing_image_url ||
                                item.hero_image_url ||
                                COURSEIMG
                              }
                              className="img-fluid"
                              alt="Load Error"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                        <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                          <div className="card-details text-center">
                            <h3 className="itemName text-left mb-0">
                              <Link
                                to={`/demos/details/${item.id}`}
                                className="detailLink text-left"
                              >
                                {item.title}
                              </Link>
                            </h3>

                            <Card.Text className="card-text mt-1 m-0 pb-0 text-center d-flex flex-wrap align-items-center justify-content-center">
                              <UTCLocalTimeConverter
                                zone="true"
                                showDate
                                utcDateTime={item?.start_date_time}
                              />
                            </Card.Text>
                            <Card.Text className="mb-1 m-0 p-0">
                              <span>
                                (
                                <ConvertMinutesToTime minutes={item.duration} />
                                )
                              </span>
                            </Card.Text>
                            <div className="d-flex gap-2 justify-content-center my-3 align-items-center">
                              <SocialShare
                                url={`https://${currentDomain}/${item?.type}s/details/${item?.id}/`}
                                iconsSize={30}
                                title={item?.title}
                                image={item?.listing_image_url}
                                description={item?.description}
                              />

                              <Link
                                to={`/share/demo/${item.id}`}
                                className="entity-share-btn d-flex align-items-center cursor-pointer"
                                title="Share"
                              >
                                <Share />
                              </Link>
                            </div>
                            <hr className="mt-2 mb-1" />
                            <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                              {item.description}
                            </Card.Text>
                          </div>
                          <div className="itemEdit d-flex flex-column flex-wrap justify-content-center mt-3 gap-2">
                            <div className="d-flex flex-wrap justify-content-center gap-2">
                              {defaultRegisterForm ===
                              "registerAcceleratorForm" ? (
                                <Link
                                  to={`/accelerator/register`}
                                  className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                                  title="register"
                                >
                                  Register
                                </Link>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setModalShow(true);
                                    setModalData(item);
                                  }}
                                  className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                                >
                                  Register
                                </Button>
                              )}
                              <Link
                                to={`/demos/details/${item.id}?demo=reviews`}
                                className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                                title="review"
                              >
                                Reviews
                              </Link>
                              <Link
                                to={`/demos/details/${item.id}`}
                                className="actionBtn btn action d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                              >
                                View More
                              </Link>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center gap-2">
                              {role === "admin" ||
                              role === "siteadmin" ||
                              role === "superadmin" ||
                              item.userId?.toLowerCase() === myUserId ? (
                                <>
                                  <Link
                                    to={`/demos/edit/${item.id}`}
                                    className="actionBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                                  >
                                    <span className="d-sm-block d-none">
                                      Edit
                                    </span>
                                    <PencilSquare className="d-sm-none d-block" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="actionDltBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                                    onClick={() => deleteItem(item.id)}
                                  >
                                    <span className="d-sm-block d-none">
                                      Delete
                                    </span>
                                    <Trash className="d-sm-none d-block" />
                                  </Link>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No data found</p>
                )}
              </>
            )}
          </Row>
          {!loading && !loadingFilter && displayedCards < items.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={() =>
                  isValidUser && myToken
                    ? handleShowMore()
                    : navigate("/my-profile")
                }
                className="btn actionBtnMore action cursor-pointer w-auto add-button"
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}

      <RegisterModal
        show={modalShow}
        setshow={setModalShow}
        modaldata={modalData}
      />
    </>
  );
};

export default withRouter(Demos);
