import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import {
  Filter,
  PencilSquare,
  PlusCircle,
  Share,
  Trash,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Actions from "../../constants/baseUrl";
import COURSEIMG from "../../img/course.png";
import FilterChips from "../filterSidebar/filterChips";
import FilterSidebar from "../filterSidebar/filterSidebar";
import CustomLoader from "../loader/loader";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";
import "./cards.css";
import entityData from "./config.json";

export default function InstituteListing() {
  const entityName = entityData?.entity;
  const entities = entityData?.entities;

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();
  const role = userData.role?.toLowerCase();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [smFilterMenu, setSmFilterMenu] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(8);
  const [categoryKeys, setCategoryKeys] = useState([]);
  const [subCategoryKeys_2, setSubCategoryKeys_2] = useState([]);
  const [subCategoryKeys_1, setSubCategoryKeys_1] = useState([]);
  const [subCategoryKeys, setSubCategoryKeys] = useState([]);
  const [starRatingKeys, setStarRatingKeys] = useState([]);
  const [entityObj, setEntityObj] = useState({
    type: entityName,
    searchTerm: "",
    subcategory: [],
    star_rating_type: [],
    category: [],
    subcategorylevel1: [],
    entity_types: [],
    subcategorylevel2: [],
    subcategorylevel3: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    type: entityName,
    searchTerm: "",
    subcategory: [],
    star_rating_type: [],
    category: [],
    entity_types: [],
    subcategorylevel1: [],
    subcategorylevel2: [],
    subcategorylevel3: [],
  });

  const populateList = async () => {
    setLoadingFilter(true);
    try {
      if (
        entityObj?.subcategorylevel1 == "" &&
        entityObj?.category == "" &&
        entityObj?.subcategory == "" &&
        entityObj?.subcategorylevel2 == "" &&
        entityObj?.star_rating_type == "" &&
        entityObj?.subcategorylevel3 == "" &&
        entityObj?.searchTerm?.trim() == ""
      ) {
        const response = await axios.get(`${Actions.BASE_URL}${entities}`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        // Sort data  in ascending order
        const sortedData = response.data.sort((a, b) =>
          moment(a.start_date).diff(moment(b.start_date))
        );
        setItems(sortedData);
        setLoading(false);
      } else {
        const response = await axios.post(
          `${Actions.BASE_URL}Search/filters`,
          entityObj,
          {
            headers: {
              Authorization: `Bearer ${myToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        // Sort data  in ascending order
        const sortedData = response.data.entities.sort((a, b) =>
          moment(a.start_date).diff(moment(b.start_date))
        );
        setItems(sortedData);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    populateList();
  }, [entityObj]);

  useEffect(() => {
    fetchCourseNames();
    fetchCategory();
    fetchTeachingModes();
    fetchSubCategory();
    fetchStarRating();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}${entities}/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                populateList();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  const fetchCourseNames = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/courseNames`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCategoryKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Category type fetched from ENUM
  const fetchTeachingModes = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/teachingModes`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategoryKeys_2(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // entity category fetched from ENUM
  const fetchCategory = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/paymentTypes`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategoryKeys_1(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // entity category fetched from ENUM
  const fetchSubCategory = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/subcategoryTypes`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategoryKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // rating fetched from ENUM
  const fetchStarRating = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/starRatings`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setStarRatingKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Define regular expressions for filtering
  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= items.length
          ? prevDisplayedCards + 4
          : items.length
      );
    };

    return (
      <div className="d-flex mt-5 pt-md-5 pt-3">
        <FilterSidebar
          filteringFrom={entityName}
          categoryKeys={categoryKeys}
          subCategoryKeys_2={subCategoryKeys_2}
          subCategoryKeys_1={subCategoryKeys_1}
          subCategoryKeys={subCategoryKeys}
          starRatingKeys={starRatingKeys}
          setEntityObj={setEntityObj}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setSmFilterMenu={setSmFilterMenu}
          smFilterMenu={smFilterMenu}
        />
        <div className="overlay" onClick={() => setSmFilterMenu(false)}></div>
        <Container fluid="lg" className="px-lg-4">
          <Row className="my-4 justify-content-md-start justify-content-center">
            <Col className="mb-3" lg={10} sm={8}>
              <h3
                id="tabelLabel"
                className="pageSubHeading mb-0 text-sm-start text-center text-capitalize"
              >
                {entityData?.listingPage_title}
              </h3>
            </Col>
            {["admin", "siteadmin", "superadmin", "trainer", "training-coordinator"].includes(role) ? (
              <Col
                lg={2}
                sm={4}
                className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
              >
                <Link
                  to={`/${entities}/add`}
                  type="button"
                  className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
                >
                  <PlusCircle /> &nbsp; Add
                </Link>
              </Col>
            ) : null}
            <FilterChips selectedFilters={selectedFilters} />
            <Col
              md={12}
              className="d-flex align-items-sm-center justify-content-between align-items-start"
            >
              <Button
                variant="link"
                className="filterToggle ms-auto p-0 text-right ms-auto d-lg-none me-lg-0 me-2 d-flex align-items-center justify-content-center btn bg-transparent mb-2"
                onClick={() => setSmFilterMenu(!smFilterMenu)}
              >
                <Filter />
              </Button>
            </Col>

            {loadingFilter ? (
              <CustomLoader />
            ) : (
              <>
                {items?.length !== 0 ? (
                  items.slice(0, displayedCards)?.map((item) => (
                    <Col
                      xxl={3}
                      xl={4}
                      md={6}
                      sm={10}
                      className="mt-2 mb-3"
                      key={item.id}
                    >
                      <Card className="demoCard">
                        <div className="itemImg">
                          <Link
                            to={`/${entities}/details/${item.id}`}
                            className="linkImg"
                          >
                            <img
                              src={
                                item.listing_image_url ||
                                item.hero_image_url ||
                                COURSEIMG
                              }
                              className="img-fluid"
                              alt="Load Error"
                            />
                          </Link>
                        </div>
                        <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                          <div className="card-details text-center">
                            <h3 className="itemName text-left mb-0">
                              <Link
                                to={`/${entities}/details/${item.id}`}
                                className="detailLink text-left"
                              >
                                {item.name}
                              </Link>
                            </h3>

                            <Link
                              to={`/share/course/${item.id}`}
                              className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                              title="review"
                            >
                              <Share />
                            </Link>
                            <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                              {item.brief_two_liner_bio}
                            </Card.Text>
                          </div>
                          <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                            <Link
                              to={`/${entities}/details/${item.id}`}
                              className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                            >
                              View More
                            </Link>
                            <Link
                              to={`/${entities}/details/${item.id}?demo=reviews`}
                              className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                              title="review"
                            >
                              Reviews
                            </Link>
                            {role === "admin" ||
                              role === "siteadmin" ||
                              role === "superadmin" ||
                              item.userId?.toLowerCase() === myUserId ? (
                              <span className="d-flex gap-2">
                                <Link
                                  to={`/${entities}/edit/${item.id}`}
                                  className="actionBtn actionBtnMain d-flex align-items-center justify-content-center text-center btn action cursor-pointer flex-grow-1"
                                >
                                  <span className="d-sm-block d-none">
                                    Edit
                                  </span>
                                  <PencilSquare className="d-sm-none d-block" />
                                </Link>
                                <Link
                                  to="#"
                                  className="actionDltBtn actionBtnMain d-flex align-items-center justify-content-center text-center btn action cursor-pointer flex-grow-1"
                                  onClick={() => deleteItem(item.id)}
                                >
                                  <span className="d-sm-block d-none">
                                    Delete
                                  </span>
                                  <Trash className="d-sm-none d-block" />
                                </Link>
                              </span>
                            ) : null}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No data found</p>
                )}
              </>
            )}
          </Row>
          {!loading && !loadingFilter && displayedCards < items.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={handleShowMore}
                className="btn actionBtnMore action cursor-pointer w-auto add-button"
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      </div>
    );
  };

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>Institutes | {siteName ? siteName : window.location.host}</title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
}
