import axios from "axios";
import * as Actions from "../../constants/baseUrl";

const myToken = localStorage.getItem("token");

// Create an Axios instance
const createAxiosInstance = () => {
  return axios.create({
    baseURL: Actions.BASE_URL,
    headers: {
      Authorization: `Bearer ${myToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

// Cache object to store promises
const cache = new Map();

// Memoization function
const memoizePromiseFn = (fn) => {
  return async (...args) => {
    const key = JSON.stringify(args);
    if (cache.has(key)) {
      return cache.get(key); // Return cached promise
    }

    const promise = fn(...args).finally(() => {
      cache.delete(key); // Optionally clear cache after completion
    });

    cache.set(key, promise);
    return promise;
  };
};

// API function with caching
const fetchAPI = async (method, url, data = null) => {
  const axiosInstance = createAxiosInstance();

  try {
    const response = await axiosInstance({
      method,
      url,
      data,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching data from API:", error);
    throw error; // Rethrow the error for further handling
  }
};

// Create a memoized version of the API function
const API = memoizePromiseFn(fetchAPI);

export default API;
