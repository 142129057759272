import React from "react";
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { ChevronDown } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";
import "./navbarstyle.css";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

function NavBar() {
  const location = useLocation();

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();
  const role = userData.role?.toLowerCase();

  const homePageRoute = SubDomainRouter("homePageRoute");
  const logoSrc = SubDomainRouter("logo");


  const isHeightMoreThan100vh = window.innerHeight > 100;

  const specRoles =
    ["admin", "siteadmin", "trainer", "training-coordinator", "superadmin"].includes(role);

  const config = useSelector((state) => state.hostSettings.value);

  const navItemsData = config?.header?.navItems ?? [
    { path: "/", label: "Home" },
    { path: "/contact", label: "Contact" },
  ];

  return (
    <Navbar
      expand="lg"
      className={
        isHeightMoreThan100vh
          ? "align-items-center py-0 pad"
          : "align-items-center py-0"
      }
    >
      <Container fluid="lg">
        <Link
          className="navbar-brand"
          to={`${homePageRoute === "/home"
            ? "/"
            : homePageRoute || "/"
            }`}
        >
          <img src={`${logoSrc}`} alt="" />
        </Link>
        <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block d-none">
          <Nav className="mx-auto my-lg-0 my-3 align-items-center">
            {navItemsData.map((item, index) => (
              <Link
                key={index}
                style={{ order: item?.index ?? index }}
                to={item.path}
                className={`nav-link text-capitalize ${location.pathname === item.path ? "active-link" : ""
                  } ${item.path?.includes(homePageRoute)
                    ? "set-first"
                    : ""
                  }`}
              >
                {item.label}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>

        <div className="right d-flex align-items-center gap-sm-3 gap-2">
          <NavDropdown
            className="d-sm-block d-none"
            title={
              <Button className="postTrigger">
                Join As <ChevronDown />
              </Button>
            }
            id="nav-dropdown-dark-example"
          >
            <div className="navbar-nav d-block py-2">
              <Link to="/coordinator/join" className="nav-link mb-2 mx-2">
                Coordinator
              </Link>
              <Link to="/trainer/join" className="nav-link mx-2">
                Instructor
              </Link>
            </div>
          </NavDropdown>
          <NavDropdown
            className="dropDownImg d-flex align-items-center justify-content-center"
            title={
              <img
                src={
                  userData?.photo_image_url ||
                  `https://placehold.co/280x280/42a49f/white?text=${userData?.first_name?.slice(0, 1) ||
                  userData?.type?.slice(0, 1) ||
                  "I"
                  }`
                }
                loading="lazy"
                alt=""
              />
            }
            id="nav-dropdown-dark-example"
          >
            {role === "training-coordinator" ? (
              <Link
                className="dropdown-item"
                to="/coordinator-dashboard/my-trainers"
              >
                My Dashboard
              </Link>
            ) : (
              <Link className="dropdown-item" to="/dashboard/my-events">
                My Dashboard
              </Link>
            )}
            {specRoles || myUserId === "ravi.chinna225@gmail.com" ? (
              <>
                <Link
                  className="dropdown-item"
                  to="/my-contacts/all-contacts"
                >
                  My Contacts
                </Link>
                <Link
                  className="dropdown-item"
                  to="campaigns-dashboard/email-campaign"
                >
                  My Campaigns
                </Link>
              </>
            ) : null}

            {["admin", "siteadmin", "superadmin", "uidev"].includes(role) && (
              <>
                <NavDropdown.Divider />
                <Link className="dropdown-item" to="/admin/content-generator">
                  Admin Dashboard
                </Link>
              </>
            )}

            <Link className="dropdown-item" to="/subscriptions">
              My Subscription
            </Link>

            <Link className="dropdown-item" to="/my-profile">
              Profile
            </Link>
            {role === "admin" ||
              role === "siteadmin" ||
              role === "superadmin" ||
              myUserId === "ravi.chinna225@gmail.com" ? (
              <Link className="dropdown-item" to="/changepassword">
                Change Password
              </Link>
            ) : null}
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => {
                localStorage.removeItem("isValidUser");
                localStorage.clear();
                window.location.href = "/";
              }}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className="dropDownImg d-flex align-items-center justify-content-center d-lg-none d-block"
            title={<Navbar.Toggle />}
            id="nav-dropdown-dark-example"
          >
            {navItemsData.map((item, index) => (
              <div className="d-lg-none" key={item.path}>
                <Link
                  to={item.path}
                  style={{ order: item?.index ?? index }}
                  className={`nav-link text-capitalize ${location.pathname === item.path ? "active-link" : ""
                    } ${item.path?.includes(homePageRoute)
                      ? "set-first"
                      : ""
                    }`}
                >
                  {item.label}
                </Link>
              </div>
            ))}
            <div className="d-sm-none d-block px-2">
              <NavDropdown.Divider />
              <Link
                to="/coordinator/join"
                className="postTrigger btn w-100 mb-2"
              >
                Join As Coordinator
              </Link>
              <Link to="/trainer/join" className="postTrigger btn w-100 mb-2">
                Join As Instructor
              </Link>
            </div>
          </NavDropdown>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;
