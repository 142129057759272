import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DynamicSections from "../../../components/DynamicHomepage/dynamicSections/dynamicSections";
import DynamicOptionsFieldset from "../../../components/dynamicOptionsFieldset/dynamicOptionsFieldset";
import CustomLoader from "../../../components/loader/loader";
import SingleFieldset from "../../../components/SingleFieldset/SingleFieldset";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../../constants/baseUrl";
import withRouter from "../../../constants/withRouter";
import { Plus, Trash } from "react-bootstrap-icons";

function AddEditDynamicHomepage(props) {
  const item_id = props.params.id;
  const actionType = props.params.actionType;

  const navigate = useNavigate();
  const entityType = "homepage";

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const dynamicSection = {
    section_heading1: "",
    entityItems: [{
      title: "",
      path: "",
      listing_image_url: "",
      show: true,
      id: uuidv4(),
    }],
    id: uuidv4(),
  };

  const [reqLoading, setReqLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    page_title: "",
  });

  const [hasHeroSec, setHasHeroSec] = useState(true)
  const [hasBottomSec, setHasBottomSec] = useState(true)

  const [hasCustomData, setCustomData] = useState(false);
  const [styleLinks, setStyleLinks] = useState([{ style_link: "" }]);
  const [scriptLinks, setScriptLinks] = useState([{ script_link: "" }]);
  const [dynamicSections, setSections] = useState([dynamicSection]);

  const [sliderImages, setSliderImages] = useState({ 0: "" });

  const populateList = async (id) => {
    try {
      if (!id) {
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${Actions.BASE_URL}items/${entityType}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const resData = await response.json();
      setSections(resData?.data?.dynamicSections ?? [dynamicSection]);
      setSliderImages(resData?.data?.sliderImages ?? { 0: "" });
      setStyleLinks(resData?.data?.styleLinks);
      setScriptLinks(resData?.data?.scriptLinks);
      setFormData({
        ...resData?.data,
      });
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  useEffect(() => {
    populateList(item_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id]);

  const addField = (setField, field) => {
    setField(field ? [...field, {}] : [{}]);
  };

  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields?.filter((item) => item?.id !== itemId);
    setFields(updatedItems);
  };

  // *--------------------------------

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);

    const reqData = {
      userId: myUserId,
      type: entityType,
      host: window.location.host,
      header: {
        host: window.location.host,
        userId: myUserId,
      },
      data: {
        userId: myUserId,
        ...formData,
        dynamicSections: dynamicSections,
        sliderImages: sliderImages,
        styleLinks: styleLinks,
        scriptLinks: scriptLinks,
      },
    };

    if (item_id && !actionType) {
      axios
        .put(`${Actions.BASE_URL}items/${entityType}/${item_id}`, reqData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/admin/${entityType}s/`);
        })
        .catch((error) => {
          console.error("API request failed:", error);
        })
        .finally(() => {
          setReqLoading(false);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}items`, reqData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/admin/${entityType}s`);
        })
        .catch((error) => {
          console.error("API request failed:", error);
        })
        .finally(() => {
          setReqLoading(false);
        });
    }
  };


  return (
    <Container fluid="lg" className="demosEditSection">
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Row>
          <Form action="" role="form" onSubmit={handleSubmit}>
            <h1 className="text-center text-capitalize">
              {item_id && !actionType
                ? "Edit"
                : item_id && actionType
                  ? "Clone"
                  : "Add"}
              &nbsp; Dynamic Homepage
            </h1>
            <div className="demosEditHeader text-center mb-3 mb-lg-5">
              <p>Add details below</p>
            </div>
            <fieldset className="wizard-fieldset show demosAddForm">
              <Row className="mx-0">
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      autoFocus
                      required
                      type="text"
                      id="page_title"
                      value={formData?.page_title}
                      placeholder="Enter Event Title"
                      name="page_title"
                      onChange={(e) =>
                        handleFieldChange("page_title", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="page_title">
                      Page Title
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center mt-5 mb-2"
                >
                  <h5 className="theme-text-2 flex-grow-1">Hero Section</h5>
                  <Button
                    className={`clickable ms-auto rounded-3 ${hasHeroSec ? "actionDltBtn btn-danger" : 'add-button'}`}
                    onClick={() => {
                      setHasHeroSec(!hasHeroSec)
                    }}
                  >
                    {hasHeroSec ? <Trash /> : 'Add Hero Section'}
                  </Button>
                </Col>
                <hr className="mb-0" />
                {hasHeroSec &&
                  <Col md={12} className="card pt-2">
                    <div>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          required
                          type="text"
                          id="slider_heading"
                          value={formData?.slider_heading}
                          placeholder="Enter Event Title"
                          name="slider_heading"
                          onChange={(e) =>
                            handleFieldChange("slider_heading", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="slider_heading">
                          Slider Heading
                        </Form.Label>
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="slider_sub_heading"
                          value={formData?.slider_sub_heading}
                          placeholder="Enter Event Title"
                          name="slider_sub_heading"
                          onChange={(e) =>
                            handleFieldChange("slider_sub_heading", e.target.value)
                          }
                        />
                        <Form.Label
                          className="formLabel"
                          htmlFor="slider_sub_heading"
                        >
                          Slider Sub-Heading
                        </Form.Label>
                      </Form.Group>
                    </div>

                    <div>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          rows="6"
                          name="slider_description"
                          style={{ height: "auto" }}
                          id="slider_description"
                          value={formData?.slider_description}
                          placeholder="Enter Event Title"
                          onChange={(e) =>
                            handleFieldChange("slider_description", e.target.value)
                          }
                        />
                        <Form.Label
                          className="formLabel"
                          htmlFor="slider_description"
                        >
                          Slider Description
                        </Form.Label>
                      </Form.Group>
                    </div>

                    {/* sliderImages */}
                    <div
                      md={12}
                      className="d-flex justify-content-between align-items-center flex-wrap mt-4 mb-2"
                    >
                      <h6 className="theme-text-2 flex-grow-1">Slider Images</h6>
                      <Button
                        className="clickable add-button ms-auto rounded-circle d-flex"
                        onClick={() => {
                          setSliderImages((prevState) => {
                            const newIndex =
                              Object.keys(prevState)?.length.toString();
                            return {
                              ...prevState,
                              [newIndex]: "",
                            };
                          });
                        }}
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                      <hr className="w-100" />
                    </div>

                    <div className="mt-2">
                      <Row>
                        {sliderImages &&
                          Object.keys(sliderImages)?.map((index) => (
                            <DynamicOptionsFieldset
                              ColNum={12}
                              label={"Slider Image"}
                              key={index}
                              index={index}
                              fields={sliderImages}
                              setFields={setSliderImages}
                            />
                          ))}
                      </Row>
                    </div>

                  </Col>
                }

                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center mt-5 mb-2"
                >
                  <h5 className="theme-text-2 flex-grow-1">Dynamic Sections</h5>
                  <Button
                    className="clickable add-button ms-auto rounded-3 d-flex"
                    onClick={() => {
                      setSections(dynamicSections ? [...dynamicSections, dynamicSection] : [dynamicSection]);
                    }}
                  >
                    Add More Sections
                  </Button>
                </Col>
                <hr className="mb-0" />
                {dynamicSections?.map((field, index) => (
                  <DynamicSections
                    key={index}
                    field={field}
                    index={index}
                    setLoading={setReqLoading}
                    setFields={setSections}
                    onRemove={() =>
                      removeItemById(
                        dynamicSections,
                        setSections,
                        field.id
                      )
                    }
                  />
                ))}

                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center mt-5 mb-2"
                >
                  <h5 className="theme-text-2 flex-grow-1">Bottom Section</h5>
                  <Button
                    className={`clickable ms-auto rounded-3 ${hasBottomSec ? "actionDltBtn btn-danger" : 'add-button'}`}
                    onClick={() => {
                      setHasBottomSec(!hasBottomSec)
                    }}
                  >
                    {hasBottomSec ? <Trash /> : 'Add Bottom Section'}
                  </Button>
                </Col>
                <hr className="mb-0" />
                {hasBottomSec &&
                  <Col md={12} className="card pt-3">
                    <div>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          value={formData?.section_heading2}
                          placeholder="Enter Event Title"
                          name="section_heading2"
                          onChange={(e) =>
                            handleFieldChange("section_heading2", e.target.value)
                          }
                        />
                        <Form.Label
                          className="formLabel"
                          htmlFor="section_heading2"
                        >
                          Bottom Section Heading
                        </Form.Label>
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="sub_heading"
                          value={formData?.sub_heading}
                          placeholder="Enter Event Title"
                          name="sub_heading"
                          onChange={(e) =>
                            handleFieldChange("sub_heading", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="sub_heading">
                          Section Sub-Heading
                        </Form.Label>
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          rows="6"
                          name="description"
                          style={{ height: "auto" }}
                          id="description"
                          value={formData?.description}
                          placeholder="Enter Event Title"
                          onChange={(e) =>
                            handleFieldChange("description", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="description">
                          Description
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>
                }

                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center mt-5 mb-2"
                >
                  <h6 className="theme-text-2 flex-grow-1">Search Tags</h6>
                </Col>
                <hr />
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as="textarea"
                      rows="6"
                      name="keywords"
                      style={{ height: "auto" }}
                      id="keywords"
                      value={formData?.keywords}
                      placeholder="Enter Event Title"
                      onChange={(e) =>
                        handleFieldChange("keywords", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="keywords">
                      Tags / Keywords
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Form.Group className="d-flex align-items-start pt-3">
                  <Form.Check
                    className="ps-0 custom-checkbox"
                    type="checkbox"
                    name="hasCustomData"
                    id={`hasCustomData`}
                    label={" "}
                    checked={hasCustomData}
                    onChange={(e) => setCustomData(!hasCustomData)}
                  />
                  <Form.Label htmlFor="hasCustomData" className="mt-3">
                    Add Custom Code
                  </Form.Label>
                </Form.Group>
                {hasCustomData && (
                  <>
                    <Col md={12} className="mt-3">
                      <h5 className="theme-text-2">Styles</h5>
                      <hr className="mb-2" />
                    </Col>

                    <Col md={12} className="ps-0">
                      {styleLinks?.map((field, index) => (
                        <React.Fragment key={index}>
                          <SingleFieldset
                            fieldKey={"style_link"}
                            type="input"
                            label={"Style Link"}
                            key={index}
                            field={field}
                            onFieldChange={(newField) => {
                              const updatedFields = [...styleLinks];
                              updatedFields[index] = newField;
                              setStyleLinks(updatedFields);
                            }}
                            indexField={index}
                            onRemove={() =>
                              removeItemById(
                                styleLinks,
                                setStyleLinks,
                                field.id
                              )
                            }
                            inputIdPrefix={index}
                          />
                        </React.Fragment>
                      ))}
                      <Button
                        className="clickable add-button mb-3 ms-auto d-flex mt-3"
                        onClick={() => addField(setStyleLinks, styleLinks)}
                      >
                        Add More links
                      </Button>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          rows="8"
                          name="custom_css"
                          value={formData?.custom_css}
                          style={{ height: "auto" }}
                          id="custom_css"
                          placeholder=" Enter your event custom_css"
                          onChange={(e) => {
                            handleFieldChange("custom_css", e.target.value);
                          }}
                        />
                        <Form.Label className="form-label" htmlFor="custom_css">
                          Custom CSS
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mt-3">
                      <h5 className="theme-text-2">Script</h5>
                      <hr className="mb-2" />
                    </Col>
                    <Col md={12} className="ps-0">
                      {scriptLinks?.map((field, index) => (
                        <React.Fragment key={index}>
                          <SingleFieldset
                            fieldKey={"script_link"}
                            type="input"
                            label={"Script Link"}
                            key={index}
                            field={field}
                            onFieldChange={(newField) => {
                              const updatedFields = [...scriptLinks];
                              updatedFields[index] = newField;
                              setScriptLinks(updatedFields);
                            }}
                            indexField={index}
                            onRemove={() =>
                              removeItemById(
                                scriptLinks,
                                setScriptLinks,
                                field.id
                              )
                            }
                            inputIdPrefix={index}
                          />
                        </React.Fragment>
                      ))}
                      <Button
                        className="clickable add-button mb-3 ms-auto d-flex mt-3"
                        onClick={() => addField(setScriptLinks, scriptLinks)}
                      >
                        Add More links
                      </Button>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          rows="8"
                          name="custom_js"
                          value={formData?.custom_js}
                          style={{ height: "auto" }}
                          id="custom_js"
                          placeholder=" Enter your event custom_js"
                          onChange={(e) => {
                            handleFieldChange("custom_js", e.target.value);
                          }}
                        />
                        <Form.Label className="form-label" htmlFor="custom_js">
                          Custom JS
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12} className="mt-3">
                      <h5 className="theme-text-2">HTML Content</h5>
                      <hr className="mb-2" />
                    </Col>
                    <Col md={12}>
                      <span className="d-block text-end text-dark">
                        required<span className="text-danger">*</span>
                      </span>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          rows="8"
                          name="html_content"
                          value={formData?.html_content}
                          style={{ height: "auto" }}
                          required
                          id="html_content"
                          placeholder=" Enter your event html_content"
                          onChange={(e) => {
                            handleFieldChange("html_content", e.target.value);
                          }}
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="html_content"
                        >
                          HTML Content
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </>
                )}

                <Col md={12} className="mt-3">
                  <Button type="submit" className="next demosEditBtn">
                    Submit
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </Row>
      )}
    </Container>
  );
}

export default withRouter(AddEditDynamicHomepage);
