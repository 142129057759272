import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../../components/loader/loader";
import TextFormatter from "../../components/textFormat/textFormat";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import AddTag from "../../components/tagInput/tagInput";
import EmailBodyContent from "../../components/emailTemplate/emailBody";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import SocialShare from "../../components/share/socialShare";
import useUserData from "../../components/useUserDetails/useUserDetails";

const ShareData = (props) => {
  const navigate = useNavigate();

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userDetails = useUserData();

  const currentDomain = window.location.hostname;
  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");
  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");

  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [addedIDs, setAddedIDs] = useState([]);

  const [selectedItemDetails, setSelectedItemDetails] = useState({});
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "email",
    subject: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    Attachments: null,
    Template: null,
    IsHTML: true,
  });
  const [userEmail, setUserEmail] = useState(null);
  const [customMessage, setCustomMessage] = useState("");

  const [emailHeaderContent, setEmailHeaderContent] = useState(undefined);
  const [emailBodyContent, setEmailBodyContent] = useState(undefined);
  const [emailFooterContent, setEmailFooterContent] = useState(undefined);

  const handleFieldChange = (fieldName, fieldValue) => {
    let newValue;

    if (fieldName === "cc" || fieldName === "bcc" || fieldName === "to") {
      newValue = fieldValue
        ?.split(/[, \n]+/)
        ?.map((id) => id?.trim())
        .filter((id) => id?.length > 0);
    } else {
      newValue = fieldValue;
    }

    const newState = {
      ...formData,
      [fieldName]: newValue,
    };

    setFormData(newState);
  };

  const hasMultiTerms = props?.params?.entityType?.toString().includes("_");
  const populateList = async (id, entityType) => {
    try {
      await axios
        .get(
          `${Actions.BASE_URL}${hasMultiTerms ? entityType.replace("_", "/") : entityType + "s"
          }/${id}`,
          {
            headers: {
              Authorization: `Bearer ${myToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => setSelectedItemDetails(response.data));
      setLoading(false);
    } catch (error) {
      console.log("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id, props.params.entityType);
  }, [props.params]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoadingProgress(true);

    const data = {
      ...formData,
      message: `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`,
      to: [...addedIDs, adminEmail, supportEmail, userEmail],
      from: fromEmail,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoadingProgress(false);
        successCampaignAlert(selectedItemDetails?.type);
      })
      .catch((error) => {
        setLoadingProgress(false);
        console.log(error.response);
      });
  };

  const successCampaignAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              {param} Shared Successfully
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                navigate(hasMultiTerms ?
                  `/${selectedItemDetails?.type}/items/details/${selectedItemDetails?.id}/`
                  : `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}/`
                );
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  const shareUrl = hasMultiTerms
    ? `https://${currentDomain}/${selectedItemDetails?.type}/items/details/${selectedItemDetails?.id}/`
    : `https://${currentDomain}/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}/`;

  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);
    setEmailHeaderContent(header_email_content);
    setEmailFooterContent(footer_email_content);

    const newState = {
      ...formData,
      body_header: header_email_content,
      body_footer: footer_email_content,
    };
    setFormData(newState);
    const mappedBodyData = EmailBodyContent(
      [selectedItemDetails],
      customMessage,
      demosAlias,
      coursesAlias
    );
    setEmailBodyContent(mappedBodyData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    coursesAlias,
    customMessage,
    demosAlias,
    emailBodyContent,
    logo,
    selectedItemDetails,
    title,
  ]);

  useEffect(() => {
    setUserEmail(userDetails?.email);
  }, [userDetails]);

  return (
    <>
      <Helmet>
        <title>
          Share{" "}
          {selectedItemDetails?.title ? `| ${selectedItemDetails?.title}` : ""}{" "}
          | &nbsp;{title}
        </title>
      </Helmet>

      <Container fluid="lg" className="mt-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <Form onSubmit={handleSubmit}>
            {loadingProgress ? (
              <>
                <span className="register-loader"> </span>
                <span className="spinnerOverlay" />
              </>
            ) : null}
            <fieldset className="demosAddForm show p-4">
              <h3 className="theme-text-2 mb-3 text-capitalize text-center">
                Share this {selectedItemDetails?.type} with others
              </h3>
              <div className="d-flex gap-3 justify-content-center my-3">
                <SocialShare
                  url={shareUrl}
                  iconsSize={40}
                  title={
                    selectedItemDetails?.title ||
                    selectedItemDetails?.data?.title ||
                    selectedItemDetails?.data?.company_name ||
                    selectedItemDetails?.data?.first_name +
                    " " +
                    selectedItemDetails?.data?.last_name ||
                    ""
                  }
                  image={selectedItemDetails?.listing_image_url || ""}
                  description={
                    selectedItemDetails?.description ||
                    selectedItemDetails?.bio ||
                    selectedItemDetails?.data?.description ||
                    selectedItemDetails?.data?.bio ||
                    ""
                  }
                />
              </div>

              {selectedItemDetails?.title ||
                selectedItemDetails?.review_title ||
                selectedItemDetails?.data?.title ||
                selectedItemDetails?.data?.company_name ||
                selectedItemDetails?.data?.first_name ? (
                <>
                  <Row>
                    <Col md={3}>
                      <Card.Text className="mb-0 fw-bold">
                        {selectedItemDetails?.title
                          ? "Title"
                          : selectedItemDetails?.review_title
                            ? "Review Title"
                            : selectedItemDetails?.data?.title
                              ? "Title"
                              : selectedItemDetails?.data?.company_name
                                ? "Company Name"
                                : selectedItemDetails?.data?.first_name
                                  ? "Name"
                                  : ""}
                      </Card.Text>
                    </Col>
                    <Col md={9}>
                      <Card.Text className="text-muted mb-0">
                        {selectedItemDetails?.title ||
                          selectedItemDetails?.review_title ||
                          selectedItemDetails?.data?.title ||
                          selectedItemDetails?.data?.company_name ||
                          selectedItemDetails?.data?.first_name +
                          " " +
                          selectedItemDetails?.data?.last_name}
                      </Card.Text>
                    </Col>
                  </Row>
                  <hr />
                </>
              ) : null}
              {selectedItemDetails?.description ||
                selectedItemDetails?.review_comments ||
                selectedItemDetails?.data?.description ||
                selectedItemDetails?.data?.bio ? (
                <>
                  <Row>
                    <Col md={3}>
                      <Card.Text className="mb-0 fw-bold">
                        {selectedItemDetails?.description
                          ? "Description"
                          : selectedItemDetails?.data?.bio
                            ? "BIO"
                            : selectedItemDetails?.data?.description
                              ? "Description"
                              : selectedItemDetails?.review_comments
                                ? "Review Comment"
                                : ""}
                      </Card.Text>
                    </Col>
                    <Col md={9}>
                      <Card.Text className="text-muted mb-0">
                        <TextFormatter
                          text={
                            selectedItemDetails?.description ||
                            selectedItemDetails?.review_comments ||
                            selectedItemDetails?.data?.description ||
                            selectedItemDetails?.data?.bio
                          }
                        />
                      </Card.Text>
                    </Col>
                    <hr />
                  </Row>
                </>
              ) : null}
              <Row className="py-3 align-items-end">
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="email"
                      className="italicInput"
                      id="from"
                      required
                      placeholder="From"
                      name="from"
                      disabled
                      value={userEmail}
                    />
                    <Form.Label className="formLabel" htmlFor="from">
                      From
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <AddTag
                    label={"Enter recipient's Email Address and hit enter"}
                    addedTags={addedIDs}
                    setAddedTags={setAddedIDs}
                  />
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      required
                      type="text"
                      className="italicInput"
                      id="subject"
                      placeholder="Enter Event Subject"
                      name="subject"
                      value={formData?.subject || ""}
                      onChange={(e) =>
                        handleFieldChange("subject", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="subject">
                      Subject
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      rows={"4"}
                      placeholder="body_custom_message"
                      className="form-control max-h-auto"
                      name="body_custom_message"
                      value={customMessage}
                      id="body_custom_message"
                      onChange={(e) => setCustomMessage(e.target.value)}
                    />
                    <Form.Label
                      className="form-label"
                      htmlFor="body_custom_message"
                    >
                      Body Custom Message
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Button
                    disabled={!addedIDs || addedIDs?.length === 0}
                    className="add-button cursor-pointer float-end"
                    type="submit"
                  >
                    Share
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        )}
      </Container>
    </>
  );
};

export default withRouter(ShareData);
