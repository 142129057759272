import axios from "axios";
import React, { useEffect, useState } from "react";
import * as Actions from "../../constants/baseUrl";

import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import EmailAcceleratorRegisterBody from "../../components/emailTemplate/registerBody";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";

const AcceleratorRegister = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const logo = SubDomainRouter("logo");
  const title = SubDomainRouter("title");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");
  const fromEmail = SubDomainRouter("fromEmail");

  const [loading, setLoading] = useState(true);
  const userData = useUserData({ setLoading });

  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    message_for_team:
      "Please check out the registration through accelerator registration form",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    linked_in: "",
    educationPrograms: [],
    interestedUniversities: [],
    programType: "",
    acceleratorsDuration: "",
    programPeriod: "",
    earlyAdmissions: "",
    socialPlatform: [],
    agree_terms: true,
  });
  const handleCheckboxChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [[fieldName]]: e.target.checked
        ? [...formData[fieldName], e.target.value]
        : formData[fieldName].filter((item) => item !== e.target.value),
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      phone: userData?.phone,
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setReqLoading(true);

    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const mappedBodyData = EmailAcceleratorRegisterBody(formData);
    const footer_email_content = EmailFooter(logo, title);

    if (
      !formData.first_name ||
      !formData.email ||
      !formData.linked_in ||
      !formData.interestedUniversities.length === 0 ||
      !formData.programPeriod ||
      !formData.acceleratorsDuration ||
      !formData.earlyAdmissions ||
      !formData.agree_terms
    ) {
      return;
    }

    const data = {
      userId: myUserId,
      type: "email",
      subject: "Accelerator registration Form Data",
      from: fromEmail,
      Attachments: null,
      Template: null,
      IsHTML: true,
      to: [adminEmail, supportEmail],
      message: `${header_email_content} ${mappedBodyData} ${footer_email_content}`,
    };
    console.log(data);
    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setReqLoading(false);
        successCampaignAlert();
        resetFields();
      })
      .catch((error) => {
        setReqLoading(false);
        console.log(error);
      });
  };

  const resetFields = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      linked_in: "",
      educationPrograms: [],
      interestedUniversities: [],
      programType: "",
      acceleratorsDuration: "",
      programPeriod: "",
      earlyAdmissions: "",
      socialPlatform: [],
      agree_terms: true,
    });
  };
  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white py-4 px-2 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <p className="text-center lh-1">
              <small>
                Thank You for showing Interest&nbsp;
                <b>
                  {formData?.first_name} {formData?.last_name}
                </b>
                <br />
                We will contact you soon!
              </small>
            </p>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };
  const siteName = SubDomainRouter("title");

  const locations = [
    "U.S. - San Francisco",
    "U.S. - Los Angeles",
    "U.S. - Austin",
    "U.S. - Chicago",
    "U.S. - Philadelphia",
    "U.S. - New York City",
    "U.S. - Boston",
    "U.S. - Miami",
    "U.S. - Other U.S. City",
    "Asia - Hong Kong",
    "Asia - China",
    "Asia - Singapore",
    "Asia - Malaysia",
    "Asia - India",
    "Asia - Japan",
    "Asia - Korea",
    "Asia - Indonesia",
    "Asia - Australia",
    "Asia - Other Asia-Pacific Country",
    "Europe - Germany",
    "Europe - England",
    "Europe - Netherlands",
    "Europe - France",
    "Europe - Other European Country",
    "MEA - Saudi Arabia",
    "MEA - Israel",
    "MEA - South Africa",
    "MEA - Nigeria",
    "MEA - Other MEA Country",
  ];

  const social = [
    "Twitter",
    "University / College Career Fair",
    "LinkedIn",
    "Google",
    "Angelist",
    "University / College Club",
    "Women 2.0",
    "Facebook",
    "Instagram",
    "Indeed",
    "University / College Career Site",
    "John Gannon's Blog",
    "Accathon Capital",
    "JobsDB",
    "Friend or Colleague",
    "Newsletter",
    "Information Session (In Person)",
    "Management Leadership for Tomorrow (MLT)",
    "Lens of Sport",
    "Venture Capital Investment Competition (VCIC)",
    "Information Session (Webinar)",
    "One Wire / Matchbook",
    "Strtup Boost",
  ];
  return (
    <>
      <Helmet>
        <title>Register | {siteName ? siteName : window.location.host}</title>
      </Helmet>

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="ContactUsPage mt-4">
          <Container fluid="lg">
            <Form
              className="ContactUsForm mt-sm-5 mt-4 px-md-2 py-md-4 py-3"
              onSubmit={handleSubmit}
            >
              <h1 className="text-center mb-5">Register</h1>

              <Row>
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="form-floating mb-3">
                    <Form.Control
                      id="first_name"
                      name="first_name"
                      required
                      placeholder="First Name"
                      value={formData?.first_name}
                      onChange={(e) =>
                        setFormData({ ...formData, first_name: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="first_name">First Name</Form.Label>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="form-floating mb-3">
                    <Form.Control
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData?.last_name}
                      onChange={(e) =>
                        setFormData({ ...formData, last_name: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="last_name">Last Name</Form.Label>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="form-floating mb-3">
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      placeholder="email"
                      required
                      value={formData?.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="email">Email</Form.Label>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="form-floating mb-3">
                    <Form.Control
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData?.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="phone">Phone Number</Form.Label>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="form-floating mb-3">
                    <Form.Control
                      type="url"
                      id="linked_in"
                      name="linked_in"
                      placeholder="LinkedIn Profile Link"
                      value={formData?.linked_in}
                      onChange={(e) =>
                        setFormData({ ...formData, linked_in: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="linked_in">
                      LinkedIn Profile Link
                    </Form.Label>
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      I'm interested in attending Venture University because...
                    </Form.Label>
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="I'm looking to join a Venture Capital or Growth Equity fund as an Analyst, Associate, Principal, or Partner"
                      name="interestedUniversities"
                      id="interestedUniversities"
                      value="I'm looking to join a Venture Capital or Growth Equity fund as an Analyst, Associate, Principal, or Partner"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="I'm looking to join a Real Estate Private Equity fund as an Analyst, Associate, Principal, or Partner"
                      name="interestedUniversities"
                      id="interestedUniversities1"
                      value="I'm looking to join a Real Estate Private Equity fund as an Analyst, Associate, Principal, or Partner"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="I'm looking to launch my own fund as an Emerging Fund Manager"
                      name="interestedUniversities"
                      id="interestedUniversities2"
                      value="I'm looking to launch my own fund as an Emerging Fund Manager"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="I'm looking to join or launch an Accelerator, Startup Studio, or Corporate VC"
                      name="interestedUniversities"
                      id="interestedUniversities3"
                      value="I'm looking to join or launch an Accelerator, Startup Studio, or Corporate VC"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="I'm an angel investor or family office looking to professionalize my direct investment strategy in companies and/or become an LP in funds"
                      name="interestedUniversities"
                      id="interestedUniversities4"
                      value="I'm an angel investor or family office looking to professionalize my direct investment strategy in companies and/or become an LP in funds"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="I'm an entrepreneur looking to join or launch a disruptive company and want to think more like a VC / PE investor for my next venture"
                      name="interestedUniversities"
                      id="interestedUniversities5"
                      value="I'm an entrepreneur looking to join or launch a disruptive company and want to think more like a VC / PE investor for my next venture"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="Other"
                      name="interestedUniversities"
                      id="interestedUniversities6"
                      value="Other"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      Which Venture University program are you applying for?
                    </Form.Label>
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="The Venture Capital Investor Accelerator: Full-Time Program (5 Days per Week) - Includes The Education Programs (Venture Capital Masterclass, Advanced VC Modules, Fireside Chats) and the Venture Capital Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      name="programType"
                      id="programType"
                      value="The Venture Capital Investor Accelerator: Full-Time Program (5 Days per Week) - Includes The Education Programs (Venture Capital Masterclass, Advanced VC Modules, Fireside Chats) and the Venture Capital Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          programType: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="The Venture Capital Investor Accelerator: Part-Time Program (~20 Hours per Week) - Includes The Education Programs (Venture Capital Masterclass, Advanced VC Modules, Fireside Chats) and the Venture Capital Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      name="programType"
                      id="programType1"
                      value="The Venture Capital Investor Accelerator: Part-Time Program (~20 Hours per Week) - Includes The Education Programs (Venture Capital Masterclass, Advanced VC Modules, Fireside Chats) and the Venture Capital Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          programType: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="The Real Estate Private Equity Investor Accelerator: Full-Time Program (5 Days per Week) - Includes The Education Programs (Real Estate Private Equity Masterclass, Advanced Real Estate PE Modules, & Fireside Chats) and the Real Estate Private Equity Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      name="programType"
                      id="programType2"
                      value="The Real Estate Private Equity Investor Accelerator: Full-Time Program (5 Days per Week) - Includes The Education Programs (Real Estate Private Equity Masterclass, Advanced Real Estate PE Modules, & Fireside Chats) and the Real Estate Private Equity Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          programType: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="The Real Estate Private Equity Investor Accelerator: Part-Time Program (~20 Hours per Week) - Includes The Education Programs (Real Estate Private Equity Masterclass, Advanced Real Estate PE Modules, & Fireside Chats) and the Real Estate Private Equity Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      name="programType"
                      id="programType3"
                      value="The Real Estate Private Equity Investor Accelerator: Part-Time Program (~20 Hours per Week) - Includes The Education Programs (Real Estate Private Equity Masterclass, Advanced Real Estate PE Modules, & Fireside Chats) and the Real Estate Private Equity Investment Apprenticeship (Profit Sharing Agreement, Career Development, & Networking)."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          programType: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="I'm only applying for one or more of VU's Education Programs below.."
                      name="programType"
                      id="programType4"
                      value="I'm only applying for one or more of VU's Education Programs below."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          programType: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      To apply only to VU's Executive Education Programs, select
                      one or more of the programs below.
                    </Form.Label>
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Venture Capital Masterclass - Live Program (Mon-Fri): In-person at VU's San Francisco or Hong Kong office, or join virtually."
                      name="educationPrograms"
                      id="educationPrograms1"
                      value="The Venture Capital Masterclass - Live Program (Mon-Fri): In-person at VU's San Francisco or Hong Kong office, or join virtually."
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Venture Capital Masterclass - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      value="The Venture Capital Masterclass - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      id="educationPrograms2"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Advanced VC Modules - Live Program (Tue & Thu Nights): In-person at VU's San Francisco or Hong Kong office, or join virtually."
                      value="The Advanced VC Modules - Live Program (Tue & Thu Nights): In-person at VU's San Francisco or Hong Kong office, or join virtually."
                      id="educationPrograms3"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Advanced VC Modules - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      value="The Advanced VC Modules - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      id="educationPrograms4"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Real Estate Private Equity Masterclass - Live Program (Two Weeks, Mon-Thu): In-person in NYC or join virtually."
                      value="The Real Estate Private Equity Masterclass - Live Program (Two Weeks, Mon-Thu): In-person in NYC or join virtually."
                      id="educationPrograms5"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Real Estate Private Equity Masterclass - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      value="The Real Estate Private Equity Masterclass - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      id="educationPrograms6"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Advanced Real Estate Private Equity Modules - Live Program (Tue & Thu Nights): Join virtually."
                      value="The Advanced Real Estate Private Equity Modules - Live Program (Tue & Thu Nights): Join virtually."
                      id="educationPrograms7"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      label="The Advanced Real Estate Private Equity Modules - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      value="The Advanced Real Estate Private Equity Modules - On-Demand Video Course Program: Watch video course content on your own schedule and join 8 weeks of live Q&A sessions on Wednesday nights."
                      id="educationPrograms8"
                      name="educationPrograms"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      For individuals applying to one of VU's Investor
                      Accelerators: How many quarters are you applying for? Gain
                      high quality investment experience over 3-12 months and
                      make ~1-28 investments.{" "}
                    </Form.Label>
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="1 Quarter (3 Months, Make ~2-7 VC Investments, 1-2 Real Estate Investments)"
                      name="acceleratorsDuration"
                      id="acceleratorsDuration"
                      value="1 Quarter (3 Months, Make ~2-7 VC Investments, 1-2 Real Estate Investments)"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          acceleratorsDuration: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="2 Quarters (6 Months, Make ~4-14 VC Investments, 2-3 Real Estate Investments)"
                      name="acceleratorsDuration"
                      id="acceleratorsDuration1"
                      value="2 Quarters (6 Months, Make ~4-14 VC Investments, 2-3 Real Estate Investments)"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          acceleratorsDuration: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="3 Quarters (9 Months, Make ~6-21 VC Investments, 3-5 Real Estate Investments)"
                      name="acceleratorsDuration"
                      id="acceleratorsDuration2"
                      value="3 Quarters (9 Months, Make ~6-21 VC Investments, 3-5 Real Estate Investments)"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          acceleratorsDuration: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      label="4 Quarters (12 Months, Make ~8-28 VC Investments, 4-6 Real Estate Investments)"
                      name="acceleratorsDuration"
                      id="acceleratorsDuration3"
                      value="4 Quarters (12 Months, Make ~8-28 VC Investments, 4-6 Real Estate Investments)"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          acceleratorsDuration: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      Which program periods are you applying for? You can select
                      more than one.
                    </Form.Label>
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod"
                      label="West Cohort: North America / LatAm / Europe - Winter 2024, Jan 15th - Mar 29th"
                      value="West Cohort: North America / LatAm / Europe - Winter 2024, Jan 15th - Mar 29th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod1"
                      label="West Cohort: North America / LatAm / Europe - Spring 2024, Apr 1st - Jun 14th"
                      value="West Cohort: North America / LatAm / Europe - Spring 2024, Apr 1st - Jun 14th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod2"
                      label="West Cohort: North America / LatAm / Europe - Summer 2024, Jun 17th - Aug 30th"
                      value="West Cohort: North America / LatAm / Europe - Summer 2024, Jun 17th - Aug 30th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod3"
                      label="West Cohort: North America / LatAm / Europe - Fall 2024, Sep 30th - Dec 13th"
                      value="West Cohort: North America / LatAm / Europe - Fall 2024, Sep 30th - Dec 13th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod4"
                      label="East Cohort: Asia-Pacific / Middle East / Africa - Winter 2024, Jan 22nd - Apr 5th"
                      value="East Cohort: Asia-Pacific / Middle East / Africa - Winter 2024, Jan 22nd - Apr 5th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod5"
                      label="East Cohort: Asia-Pacific / Middle East / Africa - Spring 2024, Apr 8th - Jun 21st"
                      value="East Cohort: Asia-Pacific / Middle East / Africa - Spring 2024, Apr 8th - Jun 21st"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod6"
                      label="East Cohort: Asia-Pacific / Middle East / Africa - Summer 2024, Jun 24th - Sep 6th"
                      value="East Cohort: Asia-Pacific / Middle East / Africa - Summer 2024, Jun 24th - Sep 6th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="programPeriod"
                      id="programPeriod7"
                      label="East Cohort: Asia-Pacific / Middle East / Africa - Fall 2024, Oct 7th - Dec 20th"
                      value="East Cohort: Asia-Pacific / Middle East / Africa - Fall 2024, Oct 7th - Dec 20th"
                      onChange={(e) => handleCheckboxChange(e, e.target.name)}
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>Early Admissions:</Form.Label>
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      name="earlyAdmissions"
                      id="earlyAdmissions"
                      label="I am applying for Early Admissions.  By selecting Early Admissions you will be moved to the front of the interview line if you're a fit for the program.  If you are extended an Offer of Admission you agree to accept the offer and enroll in the program."
                      value="I am applying for Early Admissions.  By selecting Early Admissions you will be moved to the front of the interview line if you're a fit for the program.  If you are extended an Offer of Admission you agree to accept the offer and enroll in the program."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          earlyAdmissions: e.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      className="mb-2 d-flex align-items-center ps-0 custom-checkbox"
                      type="radio"
                      name="earlyAdmissions"
                      id="earlyAdmissions1"
                      label="I am not applying for Early Admissions."
                      value="I am not applying for Early Admissions."
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          earlyAdmissions: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      Where are you located? Individuals can join VU's programs
                      in-person in VU's San Francisco and Hong Kong offices or
                      100% virtually from anywhere. Select your closest location
                      below.
                    </Form.Label>
                    {locations.map((loc, i) => (
                      <Form.Check
                        key={i}
                        className="d-flex align-items-center ps-0 custom-checkbox"
                        type="radio"
                        name="location"
                        id={`location${i}`}
                        label={loc}
                        value={loc}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            location: e.target.value,
                          });
                        }}
                      />
                    ))}

                    <Form.Check
                      className="d-flex align-items-center ps-0 custom-checkbox mb-2"
                      type="radio"
                      name="location"
                      id="location29"
                      label="other"
                      value="other"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          location: e.target.value,
                        });
                      }}
                    />
                    <div className="form-floating">
                      <Form.Control
                        className="w-full pt-1"
                        disabled={formData.location !== "other"}
                        id="other_location"
                        name="other_location"
                        placeholder="other_location"
                        value={formData?.other_location}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            location: e.target.value,
                          })
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="other_location">
                        Please Specify
                      </Form.Label>
                    </div>
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <FormGroup className="mb-3">
                    <Form.Label>
                      How did you learn about Venture University?
                    </Form.Label>

                    {social.map((soc, i) => (
                      <Form.Check
                        className="d-flex align-items-center ps-0 custom-checkbox"
                        type="checkbox"
                        name="socialPlatform"
                        id={`socialPlatform${i}`}
                        key={i}
                        label={soc}
                        value={soc}
                        onChange={(e) => handleCheckboxChange(e, e.target.name)}
                      />
                    ))}
                    <Form.Check
                      className="d-flex align-items-center ps-0 custom-checkbox mb-2"
                      type="checkbox"
                      name="socialPlatform"
                      id="socialPlatform01"
                      label="other"
                      value="other"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          socialPlatform: e.target.checked
                            ? [...formData.socialPlatform, e.target.value]
                            : formData.socialPlatform.filter(
                              (item) => item !== e.target.value
                            ),
                        });
                      }}
                    />
                    <div className="form-floating">
                      <Form.Control
                        className="w-full pt-1"
                        disabled={!formData.socialPlatform.includes("other")}
                        id="other_location"
                        name="other_location"
                        placeholder="other_location"
                        value={formData?.other_location}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            location: e.target.value,
                          })
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="other_location">
                        Please Specify
                      </Form.Label>
                    </div>
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <Form.Label htmlFor="referral">
                    If someone referred you to Venture University, please write
                    their name below.
                  </Form.Label>
                  <FormGroup className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      id="referral"
                      rows={2}
                      name="referral"
                      placeholder=""
                      value={formData?.referral}
                      onChange={(e) =>
                        setFormData({ ...formData, referral: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="referral">Referral</Form.Label>
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <FormGroup className="mb-3">
                    <Form.Label htmlFor="agree_terms">
                      Individuals accepted into Venture University's Investor
                      Accelerators receive a Profit Sharing Agreement to share
                      in the financial upside from the investments made, which
                      has the potential to return none, some, all, or more than
                      the total cost of the program in the future. Venture
                      University offers professional development programs which
                      includes a cost to participate. Program options and costs
                      can be found at:
                      <Link
                        className="ps-1"
                        to={
                          "https://www.venture.university/program-options-and-tuition"
                        }
                        target="_blank"
                      >
                        https://www.venture.university/program-options-and-tuition.
                      </Link>
                    </Form.Label>
                    <Form.Check
                      className="d-flex align-items-center ps-0 custom-checkbox"
                      type="checkbox"
                      name="agree_terms"
                      id={`agree_terms`}
                      label={"I understand"}
                      checked={formData.agree_terms === true}
                      value={true}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          agree_terms: !formData.agree_terms,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={12} className="d-flex justify-content-end">
                  <Button
                    disabled={
                      !formData.first_name ||
                      !formData.email ||
                      !formData.linked_in ||
                      !formData.interestedUniversities.length === 0 ||
                      !formData.programPeriod ||
                      !formData.acceleratorsDuration ||
                      !formData.earlyAdmissions ||
                      !formData.agree_terms
                    }
                    type="submit"
                    className="add-button px-4 ms-auto w-fit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default AcceleratorRegister;
