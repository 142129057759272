import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../../components/loader/loader";

import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import SponsorEmailBodyContent from "../../components/emailTemplate/emailBodySponsor";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";

const RequestSponsorship = (props) => {
  const navigate = useNavigate();
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");
  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");

  const [loading, setLoading] = useState(true);
  const userData = useUserData();

  const [loadingProgress, setLoadingProgress] = useState(false);
  const [addedIDs, setAddedIDs] = useState("");
  const [selectedItemDetails, setSelectedItemDetails] = useState({});
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "email",
    subject: ``,
    first_name: "",
    last_name: "",
    email: "",
    to_first_name: "",
    to_last_name: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    Attachments: null,
    Template: null,
    IsHTML: true,
  });
  const [customMessage, setCustomMessage] = useState("");

  const [emailHeaderContent, setEmailHeaderContent] = useState(undefined);
  const [emailBodyContent, setEmailBodyContent] = useState(undefined);
  const [emailFooterContent, setEmailFooterContent] = useState(undefined);

  const handleFieldChange = (fieldName, fieldValue) => {
    let newValue;

    if (fieldName === "cc" || fieldName === "bcc" || fieldName === "to") {
      newValue = fieldValue
        ?.split(/[, \n]+/)
        ?.map((id) => id?.trim())
        .filter((id) => id?.length > 0);
    } else {
      newValue = fieldValue;
    }

    const newState = {
      ...formData,
      [fieldName]: newValue,
    };

    setFormData(newState);
  };
  const hasMultiTerms = props?.params?.entityType?.toString().includes("_");

  const populateList = async (id, entityType) => {
    try {
      await axios
        .get(
          `${Actions.BASE_URL}${hasMultiTerms ? entityType.replace("_", "/") : entityType + "s"
          }/${id}`
        )
        .then((response) => setSelectedItemDetails(response.data));
      setLoading(false);
    } catch (error) {
      console.log("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id, props.params.entityType);
  }, [props.params]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingProgress(true);

    const data = {
      ...formData,
      message: `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`,
      to: [addedIDs, adminEmail, supportEmail],
      from: fromEmail,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoadingProgress(false);
        successCampaignAlert(selectedItemDetails?.type);
      })
      .catch((error) => {
        setLoadingProgress(false);
        console.log(error.response);
      });
  };

  const successCampaignAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              {param} Shared Successfully
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                navigate(
                  `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}/`
                );
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };
  const details = formData;

  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);
    setEmailHeaderContent(header_email_content);
    setEmailFooterContent(footer_email_content);

    const newState = {
      ...formData,
      body_header: header_email_content,
      body_footer: footer_email_content,
    };
    setFormData(newState);
    const mappedBodyData = SponsorEmailBodyContent(
      [selectedItemDetails],
      customMessage,
      details
    );
    setEmailBodyContent(mappedBodyData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    coursesAlias,
    customMessage,
    demosAlias,
    emailBodyContent,
    logo,
    selectedItemDetails,
    title,
  ]);

  useEffect(() => {
    setFormData({
      ...formData,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      phone: userData?.phone,
      industry: userData?.industry,
      whatsapp: userData?.whatsapp,
      subject: `${userData?.first_name} ${userData?.last_name} is requesting you to sponsor / pay (full/partial) for the ${selectedItemDetails?.type} - ${selectedItemDetails?.title}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, selectedItemDetails]);

  useEffect(() => {
    let userMsg = `Hi ${formData?.to_first_name || "[Sponsor/Payer]"},
    
I am interested in the training program/course - "${selectedItemDetails?.title
      }", being offered through ${title} platform.
I request you to sponsor/pay (full or partial) fee for the course. You can click the link below and pay for the training program.
    
Thank you,
    `;

    setCustomMessage(userMsg);
  }, [formData?.to_first_name, selectedItemDetails?.title, title]);

  return (
    <>
      <Helmet>
        <title>
          Request Sponsorship{" "}
          {selectedItemDetails?.title
            ? `| ${selectedItemDetails?.title}`
            : selectedItemDetails?.data?.title
              ? `| ${selectedItemDetails?.data?.title}`
              : ""}{" "}
          | &nbsp;{title}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <Form onSubmit={handleSubmit}>
            {loadingProgress ? (
              <>
                <span className="register-loader"> </span>
                <span className="spinnerOverlay" />
              </>
            ) : null}
            <h3 className="theme-text-2 my-3 text-capitalize text-center">
              Request Sponsorship
            </h3>
            <fieldset className="demosAddForm show p-4">
              <Row className="py-3 align-items-end">
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <div className="form-floating mb-3">
                    <Form.Control
                      id="first_name"
                      name="first_name"
                      required
                      placeholder="First Name"
                      value={formData?.first_name}
                      onChange={(e) =>
                        setFormData({ ...formData, first_name: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="first_name">
                      Your First Name
                    </Form.Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <div className="form-floating mb-3">
                    <Form.Control
                      id="last_name"
                      name="last_name"
                      required
                      placeholder="Last Name"
                      value={formData?.last_name}
                      onChange={(e) =>
                        setFormData({ ...formData, last_name: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="last_name">Your Last Name</Form.Label>
                  </div>
                </Col>
                <Col md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="email"
                      className="italicInput"
                      id="email"
                      required
                      placeholder="From"
                      name="email"
                      value={formData?.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="email">
                      Your Email
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="tel"
                      className="italicInput"
                      id="phone"
                      required
                      placeholder="Phone"
                      name="phone"
                      value={formData?.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="phone">
                      Phone
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <div className="form-floating mb-3">
                    <Form.Control
                      id="to_first_name"
                      name="to_first_name"
                      placeholder="First Name"
                      value={formData?.to_first_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          to_first_name: e.target.value,
                        })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="to_first_name">
                      Payer/Sponsor First Name
                    </Form.Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-floating mb-3">
                    <Form.Control
                      id="to_last_name"
                      name="to_last_name"
                      placeholder="Last Name"
                      value={formData?.to_last_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          to_last_name: e.target.value,
                        })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="to_last_name">
                      Payer/Sponsor Last Name
                    </Form.Label>
                  </div>
                </Col>
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="email"
                      className="italicInput"
                      id="email"
                      required
                      placeholder="From"
                      name="email"
                      value={addedIDs}
                      onChange={(e) => setAddedIDs(e.target.value)}
                    />
                    <Form.Label className="formLabel" htmlFor="email">
                      Payer/Sponsor Email
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      className="italicInput"
                      id="subject"
                      placeholder="Enter Event Subject"
                      name="subject"
                      value={formData?.subject}
                      onChange={(e) =>
                        handleFieldChange("subject", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="subject">
                      Subject
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      required
                      rows={"8"}
                      placeholder="body_custom_message"
                      className="form-control max-h-auto"
                      name="body_custom_message"
                      value={customMessage}
                      id="body_custom_message"
                      onChange={(e) => setCustomMessage(e.target.value)}
                    />
                    <Form.Label
                      className="form-label"
                      htmlFor="body_custom_message"
                    >
                      Your Message
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Button
                    className="add-button cursor-pointer float-end"
                    type="submit"
                  >
                    Share
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        )}
      </Container>
    </>
  );
};

export default withRouter(RequestSponsorship);
