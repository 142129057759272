import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import EnquiryBodyContent from "../../components/emailTemplate/enquiryBody";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import useUserData from "../../components/useUserDetails/useUserDetails";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import { useNavigate } from "react-router-dom";

const Enquiry = (props) => {
  const navigate = useNavigate();

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const userData = useUserData();
  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");
  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");

  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "email",
    subject: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    Attachments: null,
    Template: null,
    IsHTML: true,
  });
  const [selectedItemDetails, setSelectedItemDetails] = useState({});
  const [emailHeaderContent, setEmailHeaderContent] = useState(undefined);
  const [emailBodyContent, setEmailBodyContent] = useState(undefined);
  const [emailFooterContent, setEmailFooterContent] = useState(undefined);

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const hasMultiTerms = props?.params?.entityType?.toString().includes("_");

  const populateList = async (id, entityType) => {
    try {
      const response = await fetch(
        `${Actions.BASE_URL}${hasMultiTerms ? entityType.replace("_", "/") : entityType + "s"
        }/${id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSelectedItemDetails(data);
    } catch (error) {
      console.error("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingProgress(true);

    const data = {
      ...formData,
      to: [adminEmail, supportEmail],
      message: `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`,
      from: fromEmail,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoadingProgress(false);
        successCampaignAlert(selectedItemDetails?.type);
      })
      .catch((error) => {
        setLoadingProgress(false);
        console.log(error.response);
      });
  };

  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 mb-0 text-capitalize">
              We have received your Enquiry
            </h5>
            <p className="text-center m-0">We will connect with you soon!</p>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                navigate(
                  hasMultiTerms ?
                    `/${selectedItemDetails?.type}/items/details/${selectedItemDetails?.id}/` :
                    `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}/`
                );
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    populateList(props.params.id, props.params.entityType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params.id, props.params.entityType]);

  useEffect(() => {
    setSelectedItemDetails({
      ...formData,
      whatsapp: userData?.whatsapp,
      website: userData?.website,
      phone: userData?.phone,
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);
    setEmailHeaderContent(header_email_content);
    setEmailFooterContent(footer_email_content);

    const newState = {
      ...formData,
      body_header: header_email_content,
      body_footer: footer_email_content,
    };
    setFormData(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    coursesAlias,
    demosAlias,
    emailBodyContent,
    logo,
    selectedItemDetails,
    title,
  ]);
  useEffect(() => {
    const mappedBodyData = EnquiryBodyContent(
      [selectedItemDetails],
      formData,
      demosAlias,
      coursesAlias
    );
    setEmailBodyContent(mappedBodyData);
  }, [coursesAlias, demosAlias, formData, selectedItemDetails]);
  return (
    <>
      <Helmet>
        <title>
          Enquiry |{" "}
          {selectedItemDetails?.title
            ? `${selectedItemDetails.title} |`
            : selectedItemDetails?.data?.title
              ? `${selectedItemDetails.data?.title} |`
              : ""}{" "}
          |&nbsp;
          {title}
        </title>
      </Helmet>

      {loadingProgress ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="py-5 mt-5">
          <h4 className="theme-text-2 text-center text-capitalize pt-3">
            Enquiry
          </h4>
          <Form
            className="ContactUsForm mt-4 py-lg-5 px-md-4 px-md-3 py-3"
            onSubmit={handleSubmit}
          >
            <h5 className="theme-text text-center text-capitalize">
              {selectedItemDetails?.title || selectedItemDetails?.data?.title}
            </h5>
            {selectedItemDetails?.data?.description ||
              selectedItemDetails?.description ? (
              <>
                <span className="text-overflow-desc mb-0 d-inline">
                  {selectedItemDetails?.description ||
                    selectedItemDetails?.data?.description}
                </span>
                &nbsp;
                <Button
                  className="theme-text-2 btn-link p-0 bg-transparent border-0 d-inline ms-1"
                  onClick={() => {
                    navigate(
                      `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}`
                    );
                  }}
                >
                  Read More
                </Button>
              </>
            ) : null}
            <Row className="mx-0 pt-4">
              <Col lg={6}>
                <div className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    required
                    id="FirstName"
                    name="FirstName"
                    placeholder="First Name"
                    value={formData?.first_name}
                    onChange={(e) =>
                      handleFieldChange("first_name", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="FirstName">First Name</Form.Label>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    required
                    id="LastName"
                    name="LastName"
                    placeholder="Last Name"
                    value={formData?.last_name}
                    onChange={(e) =>
                      handleFieldChange("last_name", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="LastName">Last Name</Form.Label>
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-floating mb-3">
                  <Form.Control
                    required
                    type="email"
                    id="Email"
                    name="Email"
                    placeholder="Email"
                    value={formData?.email}
                    onChange={(e) => handleFieldChange("email", e.target.value)}
                  ></Form.Control>
                  <Form.Label htmlFor="Email">Email</Form.Label>
                </div>
              </Col>
              <Col md={12}>
                <div className="form-floating mb-3">
                  <Form.Control
                    as="textarea"
                    className="form-control textArea"
                    id="subject"
                    rows={1}
                    name="subject"
                    placeholder="Subject"
                    value={formData?.subject}
                    onChange={(e) =>
                      handleFieldChange("subject", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="subject">Subject</Form.Label>
                </div>
              </Col>
              <Col md={12}>
                <div className="form-floating mb-3">
                  <Form.Control
                    as="textarea"
                    className="form-control textArea max-h-auto"
                    id="message"
                    name="message"
                    rows={4}
                    placeholder="Message"
                    value={formData?.message}
                    onChange={(e) =>
                      handleFieldChange("message", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="message">Message</Form.Label>
                </div>
              </Col>
              <Col md={12}>
                <Accordion>
                  <Accordion.Item eventKey="0" className="border-0">
                    <Accordion.Header className="border-top">
                      <h6 className="theme-text-2">More Details</h6>
                    </Accordion.Header>
                    <Accordion.Body className="px-0">
                      <Row className="mx-0">
                        <Col lg={6}>
                          <div className="form-floating mb-3">
                            <Form.Control
                              type="tel"
                              id="PhoneNumber"
                              name="PhoneNumber"
                              placeholder="Phone Number"
                              value={formData?.phone}
                              onChange={(e) =>
                                handleFieldChange("phone", e.target.value)
                              }
                            ></Form.Control>
                            <Form.Label htmlFor="PhoneNumber">
                              Phone Number
                            </Form.Label>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-floating mb-3">
                            <Form.Control
                              type="tel"
                              id="whatsapp"
                              name="whatsapp"
                              placeholder="whatsapp"
                              value={formData?.whatsapp}
                              onChange={(e) =>
                                handleFieldChange("whatsapp", e.target.value)
                              }
                            ></Form.Control>
                            <Form.Label htmlFor="whatsapp">Whatsapp</Form.Label>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="form-floating mb-3">
                            <Form.Control
                              type="url"
                              id="website"
                              name="website"
                              placeholder="Website"
                              value={formData?.website}
                              onChange={(e) =>
                                handleFieldChange("website", e.target.value)
                              }
                            ></Form.Control>
                            <Form.Label htmlFor="Website">Website</Form.Label>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-floating mb-3">
                            <Form.Control
                              as="textarea"
                              className="form-control textArea max-h-auto"
                              type="text"
                              id="bio"
                              rows={3}
                              name="bio"
                              placeholder="Add BIO"
                              value={formData?.bio}
                              onChange={(e) =>
                                handleFieldChange("bio", e.target.value)
                              }
                            ></Form.Control>
                            <Form.Label htmlFor="bio">Add BIO</Form.Label>
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>

              <Col md={12} className="ContactBottom pt-3">
                <Button className="ContactSubmitBtn add-button" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};

export default withRouter(Enquiry);
