import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Check2Circle, ClipboardCheckFill } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import EmailBodyContent from "../../components/emailTemplate/emailBody";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import AddTag from "../../components/tagInput/tagInput";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";

const NewsletterCampaign = (props) => {
  const entityType = props.params.entityType;
  const dayCount = props.params.dayCount;

  const myToken = localStorage.getItem("token");

  const numOfDays = () => {
    const numericValue = parseInt(dayCount);

    if (!isNaN(numericValue) && numericValue > 0) {
      return numericValue;
    }

    if (typeof dayCount === "string") {
      switch (dayCount) {
        case "daily":
          return 1;
        case "weekly":
          return 7;
        case "biweekly":
          return 14;
        case "monthly":
          return 30;
        default:
      }
    }
  };

  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const [demosData, setDemosData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [copySuccess, setCopySuccess] = useState(null);
  const [formData, setFormData] = useState({
    campaign_name: `${entityType} ${numOfDays()} Days Newsletter`,
    description: "My Newsletter Description",
    userId: myUserId,
    type: "campaign",
    emaillists: [],
    entityRelation: [],
    subject: `${numOfDays() === 1
      ? "Daily"
      : numOfDays() === 7
        ? "Weekly"
        : numOfDays() === 30
          ? "Monthly"
          : numOfDays() + " Days"
      } ${entityType} Newsletter`,
    body_header: "",
    body: "",
    body_footer: "",
    body_plain_text: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    scheduled_date: "",
    scheduled_time: `${new Date().getUTCHours()}:${new Date().getUTCMinutes()}`,

    campaign_sent_status: "",
    tags: [],
    comments: "",
  });
  const [emailLists, setEmailLists] = useState([]);
  const [selectedEmailList, setSelectedEmailList] = useState([]);
  const [addedIDs, setAddedIDs] = useState([]);

  useEffect(() => {
    // Get Emaillists
    const getEmaillists = () => {
      axios
        .get(`${Actions.BASE_URL}emaillists/users/${myUserId}`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setEmailLists(res.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    };

    getEmaillists();
  }, []);

  const handleEmailListSelection = async (selectedListName) => {
    setSelectedEmailList(selectedListName);
  };

  useEffect(() => {
    // Fetch demos data
    if (entityType === "demos") {
      setLoading(true);
      axios
        .get(`${Actions.BASE_URL}demos/days/${numOfDays()}`)
        .then((response) => {
          setDemosData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching courses:", error);
          setLoading(false);
        });
    } else if (entityType === "courses") {
      // Fetch courses data
      axios
        .get(`${Actions.BASE_URL}courses/days/${numOfDays()}`)
        .then((response) => {
          setCoursesData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching courses:", error);
          setLoading(false);
        });
    }
  }, [entityType]);

  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);

    const selectedItemDetails = [...demosData, ...coursesData];

    const mappedBodyData = EmailBodyContent(
      selectedItemDetails,
      null,
      demosAlias,
      coursesAlias
    );

    const newState = {
      ...formData,
      body_header: header_email_content,
      body: header_email_content + mappedBodyData + footer_email_content,
      body_footer: footer_email_content,
    };

    setFormData(newState);
  }, [coursesAlias, coursesData, demosAlias, demosData, logo, title]);

  const successCampaignAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              {param} sent successfully
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  // Copy
  const handleCopyClick = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopySuccess(true);
      })
      .catch(() => {
        setCopySuccess(false);
      });

    setTimeout(() => {
      setCopySuccess(null);
    }, 1400);
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    let newValue;

    if (fieldName === "cc" || fieldName === "bcc" || fieldName === "to") {
      newValue = fieldValue
        ?.split(/[, \n]+/)
        ?.map((id) => id?.trim())
        .filter((id) => id?.length > 0);
    } else {
      newValue = fieldValue;
    }

    const newState = {
      ...formData,
      [fieldName]: newValue,
    };

    setFormData(newState);
  };

  // handle submit function if required
  const handleSubmit = () => {
    setLoadingData(true);

    const data = {
      ...formData,
      emaillists: [...selectedEmailList],
      body: formData.body,
      to: [...addedIDs],
      from: fromEmail,
    };

    axios
      .post(`${Actions.BASE_URL}campaigns`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoadingData(false);
        successCampaignAlert("newsletter");
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error.response);
      });
  };

  return (
    <Container fluid="lg" className="demosAddFormSection demosEditSection">
      {loadingData ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="demosAddFormHeader text-center">
            <h2 className="addFormHeading theme-text-2">Newsletter Campaign</h2>
          </div>

          {coursesData.length > 0 || demosData.length > 0 ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className="wizard-fieldset show demosAddForm">
                <Col md={12} className="mb-3">
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-2">
                    <Select
                      className="text-capitalize form-control select-email"
                      required
                      isMulti
                      placeholder="Select an existing email list"
                      options={emailLists?.map((email) => ({
                        value: email.id,
                        label: email.list_name,
                      }))}
                      onChange={(selectedOptions) =>
                        handleEmailListSelection(
                          selectedOptions
                            ? selectedOptions?.map((option) => option.value)
                            : []
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={12}
                  className="d-flex align-items-center justify-content-center gap-2 mb-2"
                >
                  <hr width="25%" className="my-1" />
                  Or
                  <hr width="25%" className="my-1" />
                </Col>

                <Col md={12} className="mb-3">
                  <small className="text-start d-block text-dark d-block mb-1">
                    Please enter emails and press Enter
                    <span className="text-danger">*</span>
                  </small>
                  <AddTag
                    label={"To"}
                    addedTags={addedIDs}
                    setAddedTags={setAddedIDs}
                  />
                </Col>

                <Col md={12} className="pb-3">
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      className="italicInput"
                      id="subject"
                      required
                      placeholder="Enter Event Subject"
                      name="subject"
                      value={formData?.subject || ""}
                      onChange={(e) =>
                        handleFieldChange("subject", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="subject">
                      Subject
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="position-relative">
                  <Button
                    variant="link"
                    className="theme-text py-1 px-2 bg-light rounded-2 text-decoration-none position-absolute top-0 end-0 d-flex align-items-center ms-auto w-fit z-3"
                    onClick={() => handleCopyClick(formData?.body, 1)}
                  >
                    {copySuccess === null && (
                      <span className="text-success z-3 end-100 bg-white">
                        Copy&nbsp;
                      </span>
                    )}
                    {copySuccess === true && (
                      <span className="text-success z-3 end-100 bg-white">
                        Copied!&nbsp;
                      </span>
                    )}
                    {copySuccess === false && (
                      <span className="text-danger z-3 end-100 bg-white">
                        Copy failed&nbsp;
                      </span>
                    )}
                    <ClipboardCheckFill />
                  </Button>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      rows={"40"}
                      placeholder="body"
                      className="form-control max-h-auto"
                      name="body"
                      value={formData?.body}
                      id="body"
                      onChange={(e) =>
                        setFormData({ ...formData, body: e.target.value })
                      }
                    />
                    <Form.Label className="form-label" htmlFor="body">
                      Template for {entityType}
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col
                  md={12}
                  className="mt-3"
                  disabled={
                    selectedEmailList?.length === 0 && addedIDs?.length === 0
                  }
                >
                  <Button
                    type="submit"
                    className="demosEditBtn ms-auto d-block"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <h3 className="mt-5 pt-5 text-center text-capitalize">
              No {entityType} found!
            </h3>
          )}
        </>
      )}
    </Container>
  );
};

export default withRouter(NewsletterCampaign);
