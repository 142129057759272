import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as Actions from "../../constants/baseUrl";
import "./add.css";
import "./form.css";

import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Toast,
} from "react-bootstrap";
import { Clock, Send, XCircleFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VideoUploader from "../../components/VideoUploader/VideoUploader ";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import BatchForm from "../../components/batchForm/batchForm";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import DynamicRelationFieldSet from "../../components/dynamicRelationFieldSet/dynamicRelationFieldSet";
import DynamicFieldSetURL from "../../components/dynamicUrlField/dynamicUrlField";
import DynamicFileUploadFieldSets from "../../components/fileUploadFieldSet/fileUploadFieldSet";
import ThemeInpGroup from "../../components/homeSegments/ThemeInpGroup";
import DynamicLocationFieldSet from "../../components/locationFieldSet/locationFieldSet";
import ScheduleDateModalCourse from "../../components/scheduleDateModal/scheduleDateModalCourse";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import AddTag from "../../components/tagInput/tagInput";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import { convertLocalToUTC } from "../../helper/timezoneConversions";
import courseData from "./course-config.json";

export default function AddCourse() {
  const configData = useSelector((state) => state.hostSettings.value);
  const entityName = courseData.entity;
  const entities = courseData.entities;

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);
  const userData = useUserData();

  const [entityTypesData, setEntityTypesData] = useState([]);
  const [countryTypesData, setCountryTypesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [starRatings, setStarRatings] = useState([]);
  const [courseNamesData, setCourseNamesData] = useState([]);
  const [state, setState] = useState({
    userId: myUserId,
    type: entityName,
    entity_type: entityName,
    tags: [],
    entityRelation: [],
    social_links: [],
    attachments: [],
    locations: [],
    approval_status: "",
    country: "global",
    category: "",
    amount: "",
    currency: "",
    subcategory: "",
    subcategorylevel1: "",
    subcategorylevel2: "",
    comments: "",
    title: "",
    start_date: "",
    start_time: "",
    duration: "",
    description: "",
    meeting_id: "",
    rsvp_redirect_url: "",
    video_url: "",
    share_link: "",
    online_join_link: "",
    star_rating_type: "",
    instructor_first_name: "",
    instructor_last_name: "",
    instructor_email: "",
    instructor_phone: "",
    instructor_whatsapp: "",
    instructor_bio: "",
    organizer_first_name: "",
    organizer_last_name: "",
    organizer_company_name: "",
    organizer_phone: "",
    organizer_whatsapp: "",
    organizer_email: "",
    organizer_website: "",
    listing_image_url: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
  });
  const [addedTags, setAddedTags] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [step, setStep] = useState(1);
  const [showErrorToast, setShowErrorToast] = useState(false);
  // Attachment Data
  const [documentData, setDocumentData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [refLinksData, setRefLinksData] = useState([]);
  const [instructorSameAsOrganizer, setInstructorSameAsOrganizer] =
    useState(false);
  const [needDetailedImage, setNeedDetailedImage] = useState(false);

  const batchFields = [
    {
      courseName: "",
      date: "",
      startTime: "",
      duration: "",
      teaching_mode: "",
      trainer: "",
    },
  ];

  const [batchData, setBatchData] = useState(batchFields);

  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [scheduleModalShow, setScheduleModalShow] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...state,
      [fieldName]: fieldValue,
    };
    setState(newState);
  };

  const handleCheckboxChange = () => {
    setInstructorSameAsOrganizer(!instructorSameAsOrganizer);
  };

  // * ==========================================================================

  // * ==========================================================================
  const handleAIGenerate = (e, input) => {
    e.preventDefault();
    setLoadingAI(true);
    const data = {
      searchText: input,
    };
    setState({
      ...state,
      hero_image_content: "",
      listing_image_content: "",
      hero_image_url: "https://placehold.co/280x180?text=loading+Image",
      listing_image_url: "https://placehold.co/280x180?text=loading+Image",
    });
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create/event`, data, {
          withCredentials: true,
        })

        .then((generated_content) => {
          axios
            .post(`https://api.ameerpetit.com/ai/create/image`, data, {
              withCredentials: true,
            })

            .then((generated_image) => {
              setState({
                ...state,
                title: input,
                description: generated_content.data,
                listing_image_content: generated_image.data,
                listing_image_extension: ".png",
                listing_image_url: "",
                hero_image_content: generated_image.data,
                hero_image_extension: ".png",
                hero_image_url: "",
              });
              // Update the textarea height after setting the content
              updateTextareaHeight();
            })
            .catch((error) => {
              console.error("Error generating code:", error);
              setState({
                ...state,
                title: input,
                description: generated_content.data,
                hero_image_content: "",
                listing_image_extension: "",
                listing_image_content: "",
                hero_image_extension: "",
                hero_image_url:
                  "https://placehold.co/280x180?text=loading+Image",
                listing_image_url:
                  "https://placehold.co/280x180?text=loading+Image",
              });
              toast.error("Error generating code. Please try again.", {
                autoClose: 3000,
                hideProgressBar: true,
                draggable: true,
              });
            })
            .finally(() => {
              setLoadingAI(false);
            });
        })
        .catch((error) => {
          console.error("Error generating code:", error);
          setState({
            title: input,
            description: "",
            hero_image_content: "",
            listing_image_extension: "",
            hero_image_url: "",
            listing_image_content: "",
            hero_image_extension: "",
            listing_image_url: "",
          });
          setLoadingAI(false);
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };
  // img generate

  const handleImageGenerate = (e, input) => {
    e.preventDefault();
    const data = {
      searchText: input,
    };
    setState({
      ...state,
      hero_image_content: "",
      listing_image_content: "",
      hero_image_url: "https://placehold.co/280x180?text=loading+Image",
      listing_image_url: "https://placehold.co/280x180?text=loading+Image",
    });
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create/image`, data, {
          withCredentials: true,
        })

        .then((generated_image) => {
          setState({
            ...state,
            listing_image_content: generated_image.data,
            listing_image_extension: ".png",
            listing_image_url: "",
            hero_image_content: generated_image.data,
            hero_image_extension: ".png",
            hero_image_url: "",
          });
          // Update the textarea height after setting the content
          updateTextareaHeight();
        })
        .catch((error) => {
          console.error("Error generating code:", error);
          setState({
            ...state,
            hero_image_content: "",
            listing_image_extension: "",
            hero_image_url: "",
            listing_image_content: "",
            hero_image_extension: "",
            listing_image_url: "",
          });
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };

  // Function to update the textarea height based on content
  const updateTextareaHeight = () => {
    const textarea = document.getElementById("description");
    if (textarea) {
      // Reset the height to auto to ensure it shrinks if content is removed
      textarea.style.height = "auto";
      // Set the height to the scroll height of the content
      textarea.style.height = `${textarea.scrollHeight + 40}px`;
    }
  };
  // * ==========================================================================

  const handleSubmit = (event) => {
    setLoading(true);
    const addedAttachments = {
      documents: documentData.documents,
      images: imageData.images,
      videos: videoData.videos,
      links: refLinksData.links,
    };
    const { utcTime, utcDate } = convertLocalToUTC(
      state?.start_date,
      state?.start_time,
      state?.timezone?.value
    );

    const data = {
      ...state,
      start_time: utcTime,
      start_date: utcDate,
      tags: addedTags,
      batches: batchData,
      attachments: addedAttachments,
      amount: state.amount,
    };

    axios
      .post(`${Actions.BASE_URL}${entities}`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate(`/share/${entityName}/${res.data.id}/`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 413) {
          // Handle Payload Too Large
          toast.error(
            "Attachments are too large. Please check your attachments and try again."
          );
        } else if (error.response.status === 400) {
          setShowErrorToast(true);
        }
      });
  };
  const titleInputRef = useRef(null);

  useEffect(() => {
    fetchFilterTypes();
    titleInputRef?.current?.focus();
  }, []);

  // Category type fetched from ENUM
  const fetchFilterTypes = async () => {
    try {
      const enums = configData?.common?.enums || {};

      const countryTypeObj = enums?.countryTypes || {};
      const coursesObj = enums?.courseNames || {};
      const teachingModeObj = enums?.teachingModes || {};
      const paymentTypesObj = enums?.paymentTypes || {};
      const subcategoryTypesObj = enums?.subcategoryTypes || {};
      const starRatingsObj = enums?.starRatings || {};

      const mapToObject = (sourceObj) =>
        Object.entries(sourceObj).reduce((acc, [key, value]) => {
          acc[key] = {
            key,
            value,
          };
          return acc;
        }, {});

      setCountryTypesData(mapToObject(countryTypeObj));
      setCourseNamesData(mapToObject(coursesObj));
      setEntityTypesData(mapToObject(teachingModeObj));
      setCategoriesData(mapToObject(paymentTypesObj));
      setSubCategoryData(mapToObject(subcategoryTypesObj));
      setStarRatings(mapToObject(starRatingsObj));
    } catch (error) {
      console.error("Error fetching filter types:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  // Details filled

  const organizerFirstNameRef = useRef(null);
  const organizerLastNameRef = useRef(null);
  const organizerEmailRef = useRef(null);
  const organizerPhoneRef = useRef(null);
  const organizerWhatsappRef = useRef(null);

  useEffect(() => {
    if (instructorSameAsOrganizer) {
      const updateState = {
        ...state,
        organizer_first_name: state.instructor_first_name,
        organizer_last_name: state.instructor_last_name,
        organizer_email: state.instructor_email,
        organizer_phone: state.instructor_phone,
        organizer_whatsapp: state.instructor_whatsapp,
      };

      setState(updateState);
    }
  }, [instructorSameAsOrganizer]);

  // Checking field values

  const step1Fields = [
    "title",
    "country",
    "category",
    "subcategory",
    "subcategorylevel1",
    "subcategorylevel2",
    "start_date",
    "start_time",
    "duration",
    "meeting_provider",
  ];

  const areFieldsFilled = (fieldNames, state) => {
    return fieldNames.every((fieldName) => state[fieldName]);
  };

  const isStep1FieldsFilled = areFieldsFilled(step1Fields, state);

  const areDetailsFilled = () => {
    return (
      (state.instructor_first_name &&
        state.instructor_last_name &&
        state.instructor_email &&
        state.instructor_phone) ||
      (organizerFirstNameRef?.current?.value &&
        organizerLastNameRef?.current?.value &&
        organizerEmailRef?.current?.value &&
        organizerPhoneRef?.current?.value)
    );
  };

  const instructorDetails = () => {
    return (
      state.instructor_first_name &&
      state.instructor_last_name &&
      state.instructor_email &&
      state.instructor_phone
    );
  };

  const handleNext = () => {
    const isSubcategoryPaid = state.subcategorylevel1 === "paid";
    const isStep1FieldsFilled = step1Fields.every((field) => state[field]);

    if (
      isStep1FieldsFilled &&
      (!isSubcategoryPaid ||
        (isSubcategoryPaid && state.currency && state.amount))
    ) {
      setStep((prevStep) => prevStep + 1);
      setHasValue(false);
      setIsValid(true);
      return;
    }
    setHasValue(true);
    setIsValid(true);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const addField = (setField, field) => {
    setField([...field, {}]);
  };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };
  // Timezone user
  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
  }, []);

  const courseAlias = SubDomainRouter("courseAlias");
  const instructorAlias = SubDomainRouter("instructorAlias");

  // auto fill userData

  useEffect(() => {
    setState({
      ...state,
      instructor_first_name: userData?.first_name,
      instructor_last_name: userData?.last_name,
      instructor_phone: userData?.phone,
      instructor_whatsapp: userData?.website,
      instructor_email: userData?.email,
      instructor_bio: userData?.bio,
      organizer_first_name: userData?.first_name,
      organizer_last_name: userData?.last_name,
      organizer_company_name: userData?.industry,
      organizer_phone: userData?.phone,
      organizer_whatsapp: userData?.website,
      organizer_email: userData?.email,
      organizer_bio: userData?.bio,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="demosAddFormSection demosEditSection">
      {loading || loadingAI ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Container fluid="lg" className="formMain create my-3">
        <div className="demosAddFormHeader text-center">
          <h2 className="addFormHeading text-capitalize">
            Add a {courseAlias ? courseAlias : "course"}
          </h2>
          <div className="demosEditHeader text-center mb-3 mb-lg-5">
            <h5>Add Details Below:</h5>
            <ul className="demosEditList">
              <li>
                <button
                  className={
                    step === 1 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(1)}
                >
                  <span>1</span>
                </button>
              </li>
              <li>
                <button
                  className={
                    step === 2 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(2)}
                  disabled={!isStep1FieldsFilled}
                >
                  <span>2</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled || !areDetailsFilled()}
                  className={
                    step === 3 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(3)}
                >
                  <span>3</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled || !areDetailsFilled()}
                  className={
                    step === 4 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(4)}
                >
                  <span>4</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled || !areDetailsFilled()}
                  className={
                    step === 5 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(5)}
                >
                  <span>5</span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {step === 1 && (
          <div className="chatAI h-fit p-0 demosAddForm min-h-fit w-100 mb-0">
            <p className="opacity-75 text-center mb-1">
              Enter command to generate content
            </p>
            <ThemeInpGroup
              handleSubmit={handleAIGenerate}
              icon={<Send />}
              loading={loadingAI}
              inputPlaceholder={
                "To generate Content: Type the title or keywords."
              }
            />
            <FormGroup className="d-flex align-items-start pt-3">
              <Form.Check
                className="ps-0 custom-checkbox"
                type="checkbox"
                name="needDetailedImage"
                id={`needDetailedImage`}
                label={" "}
                checked={needDetailedImage}
                onChange={(e) => setNeedDetailedImage(!needDetailedImage)}
              />
              <Form.Label htmlFor="needDetailedImage" className="mt-3">
                Generate Detailed Image With Custom Prompts
              </Form.Label>
            </FormGroup>

            {needDetailedImage && (
              <>
                <p className="opacity-75 text-center my-1">
                  Enter Detailed Prompt below to generate Image
                </p>
                <ThemeInpGroup
                  handleSubmit={handleImageGenerate}
                  icon={<Send />}
                  inputPlaceholder={
                    "To generate Detailed Image: Type description or keywords."
                  }
                />
              </>
            )}
          </div>
        )}

        <Form
          id="add-demo-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-section addFormSection event-detail">
            {step === 1 && (
              <fieldset className="wizard-fieldset show demosAddForm position-relative overflow-hidden">
                <Row className="mx-0 align-items-end">
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className={`italicInput ${
                          isValid && hasValue && !state.title
                            ? "border-danger"
                            : ""
                        }`}
                        id="title"
                        required
                        value={state.title}
                        placeholder="Enter Event Title"
                        name="title"
                        ref={titleInputRef}
                        onChange={(e) =>
                          handleFieldChange("title", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="title">
                        Title
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* Data Append from schedule popup */}
                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3 position-relative">
                      <Form.Control
                        type="button"
                        value={state.start_date || "YYYY/MM/DD"}
                        className={
                          isValid && hasValue && !state.start_date
                            ? "border-danger"
                            : ""
                        }
                        required
                        name="start_date"
                        id="start_date"
                        onClick={() => setScheduleModalShow(true)}
                      />
                      <Form.Label className="form-label" htmlFor="start_date">
                        Start Date
                      </Form.Label>
                      <Toast
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        autohide
                        delay={3000}
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: 0,
                          zIndex: "99",
                        }}
                      >
                        <Toast.Header closeButton={false}>
                          <strong className="mr-auto text-danger">
                            <Clock />
                            &nbsp;Error
                          </strong>
                        </Toast.Header>
                        <Toast.Body>
                          Invalid date. Please select a date not earlier than
                          today.
                        </Toast.Body>
                      </Toast>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        className={`form-control ${
                          isValid && hasValue && !state.duration
                            ? "border-danger"
                            : ""
                        }`}
                        id="duration"
                        required
                        value={
                          state.duration
                            ? ConvertMinutesToTime({ minutes: state.duration })
                            : "Select Your Event Duration"
                        }
                        name="duration"
                        onClick={() => setScheduleModalShow(true)}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        className={
                          isValid && hasValue && !state.start_time
                            ? "border-danger"
                            : ""
                        }
                        required
                        onClick={() => setScheduleModalShow(true)}
                        name="start_time"
                        value={state?.start_time || "Select Your Event Time"}
                        id="start_time"
                      />
                      <Form.Label className="form-label" htmlFor="start_time">
                        Event Time
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <div className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        className={`timezone px-2 z-3 ${
                          isValid && hasValue && !state.start_time
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        onClick={() => setScheduleModalShow(true)}
                        id="timezone"
                        value={
                          selectedTimeZone?.value || "Select Your Timezone"
                        }
                        placeholder="Select your time zone"
                      />
                    </div>
                  </Col>
                  {/* Data Append from schedule popup */}
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="country"
                        className={`form-control form-select text-capitalize ${
                          isValid && hasValue && !state.country
                            ? "border-danger"
                            : ""
                        }`}
                        value={state.country}
                        name="country"
                        onChange={(e) =>
                          handleFieldChange("country", e.target.value)
                        }
                      >
                        {Object.keys(countryTypesData)?.map((paramType) => (
                          <option
                            key={countryTypesData[paramType]?.key}
                            value={countryTypesData[paramType]?.value}
                          >
                            {countryTypesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Label className="form-label" htmlFor="country">
                        Country
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="meeting_provider"
                        className={`${
                          isValid && hasValue && !state.meeting_provider
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        value={state?.meeting_provider}
                        name="meeting_provider"
                        onChange={(e) =>
                          handleFieldChange("meeting_provider", e.target.value)
                        }
                      >
                        <option value="">Meeting Provider</option>
                        <option value="zoom">Zoom</option>
                        <option value="google_meet">Google Meet</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <span className="d-block text-end text-dark">
                      required<span className="text-danger">*</span>
                    </span>
                    <Form.Group className="form-floating">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="description"
                        value={state.description}
                        style={{ height: "auto" }}
                        required
                        id="description"
                        placeholder=" Enter your event description"
                        onChange={(e) => {
                          handleFieldChange("description", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="description">
                        Description
                      </Form.Label>
                    </Form.Group>
                    <small
                      className={`d-block mb-3 ${
                        state.description.length > 1900
                          ? "text-danger"
                          : "text-muted"
                      }
                      `}
                    >
                      <span className="text-danger">*</span>
                      {state.description.length}/1900 characters
                    </small>
                  </Col>

                  <Col md={6}>
                    <BannerUploaderEdit
                      key={state.hero_image_url}
                      label="Big Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="hero_image"
                      placehold="https://placehold.co/1024x200"
                      state={state}
                      setState={setState}
                      imageURL={state.hero_image_url}
                      imageContent={state.hero_image_content}
                      imageExtension={state.hero_image_extension}
                    />
                  </Col>

                  <Col md={6}>
                    <BannerUploaderEdit
                      key={state.listing_image_url}
                      label="Small Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="listing_image"
                      placehold="https://placehold.co/280x180"
                      state={state}
                      setState={setState}
                      imageURL={state.listing_image_url}
                      imageContent={state.listing_image_content}
                      imageExtension={state.listing_image_extension}
                    />
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        id="video_url"
                        name="video_url"
                        value={state.video_url}
                        type="url"
                        placeholder="Video Url"
                        className="form-control input-md italicInput"
                        onChange={(e) =>
                          handleFieldChange("video_url", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="video_url">
                        Video Url
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="category"
                        className={`form-control form-select ${
                          isValid && hasValue && !state.category
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        value={state.category}
                        name="category"
                        onChange={(e) =>
                          handleFieldChange("category", e.target.value)
                        }
                      >
                        <option value="">Category</option>
                        {Object.keys(courseNamesData)?.map((paramType) => (
                          <option
                            key={courseNamesData[paramType]?.key}
                            value={courseNamesData[paramType]?.value}
                          >
                            {courseNamesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="subcategory"
                        className={`form-control form-select ${
                          isValid && hasValue && !state.subcategory
                            ? "border-danger"
                            : ""
                        }`}
                        value={state.subcategory}
                        required
                        name="subcategory"
                        multiple=""
                        onChange={(e) =>
                          handleFieldChange("subcategory", e.target.value)
                        }
                      >
                        <option value="">Learning Level</option>
                        {Object.keys(subCategoryData)?.map((paramType) => (
                          <option
                            key={subCategoryData[paramType]?.key}
                            value={subCategoryData[paramType]?.value}
                          >
                            {subCategoryData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating  mb-3">
                      <Form.Select
                        id="subcategorylevel1"
                        className={`form-control text-capitalize ${
                          isValid && hasValue && !state.subcategorylevel1
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        value={state.subcategorylevel1}
                        name="subcategorylevel1"
                        multiple=""
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel1", e.target.value)
                        }
                      >
                        <option value="">Select Payment Option</option>
                        {Object.keys(categoriesData)?.map((paramType) => (
                          <option
                            key={categoriesData[paramType]?.key}
                            value={categoriesData[paramType]?.value}
                          >
                            {categoriesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        className={`form-control ${
                          isValid && hasValue && !state.subcategorylevel2
                            ? "border-danger"
                            : ""
                        }`}
                        aria-label="event type"
                        required
                        value={state.subcategorylevel2}
                        id="subcategorylevel2"
                        name="subcategorylevel2"
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel2", e.target.value)
                        }
                      >
                        <option value="">Mode</option>
                        {Object.keys(entityTypesData)?.map((paramType) => (
                          <option
                            key={entityTypesData[paramType]?.key}
                            value={entityTypesData[paramType]?.value}
                          >
                            {entityTypesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {state.subcategorylevel1.toLowerCase() === "paid" && (
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <div className="d-flex gap-3">
                        <Form.Group className="form-floating w-25 mb-3">
                          <select
                            id="currency"
                            required
                            className={`form-control form-select text-uppercase ${
                              isValid && hasValue && !state.currency
                                ? "border-danger"
                                : ""
                            }`}
                            name="currency"
                            value={state.currency}
                            onChange={(e) =>
                              handleFieldChange("currency", e.target.value)
                            }
                          >
                            <option value="">Currency</option>
                            <option value="usd">usd</option>
                            <option value="cad">cad</option>
                            <option value="inr">inr</option>
                            <option value="eur">eur</option>
                            <option value="gbp">gbp</option>
                          </select>
                        </Form.Group>
                        <Form.Group className="form-floating mb-3 flex-grow-1">
                          <Form.Control
                            id="amount"
                            name="amount"
                            type="number"
                            required
                            value={state.amount}
                            onChange={(e) =>
                              handleFieldChange("amount", e.target.value)
                            }
                            placeholder="Amount"
                            className={`form-control input-md italicInput ${
                              isValid && hasValue && !state.amount
                                ? "border-danger"
                                : ""
                            }`}
                          />
                          <Form.Label className="form-label" htmlFor="amount">
                            Enter Amount
                          </Form.Label>
                          <span className="position-absolute end-0 h-100 top-0 d-flex align-items-center pe-3">
                            .00
                          </span>
                        </Form.Group>
                      </div>
                    </Col>
                  )}

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control form-select fromcontrol"
                        id="star_rating_type"
                        value={state.star_rating_type}
                        name="star_rating_type"
                        onChange={(e) =>
                          handleFieldChange("star_rating_type", e.target.value)
                        }
                      >
                        <option value="">
                          How Do You Rate This{" "}
                          {courseAlias ? courseAlias : "course"}?
                        </option>
                        {Object.keys(starRatings)?.map((paramType) => (
                          <option
                            key={starRatings[paramType]?.key}
                            value={starRatings[paramType]?.value}
                          >
                            {starRatings[paramType]?.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={12}>
                    <AddTag
                      label={"Tags"}
                      addedTags={addedTags}
                      setAddedTags={setAddedTags}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="next demosEditBtn "
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 2 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col sm={12} className="form-section-header mt-4 mb-3">
                    <h4 className="theme-text-2 text-capitalize">
                      {instructorAlias} Details
                    </h4>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        className="form-control italicInput"
                        name="instructor_first_name"
                        value={state.instructor_first_name}
                        id="instructor_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_first_name"
                      >
                        First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="form-control italicInput"
                        value={state.instructor_last_name}
                        name="instructor_last_name"
                        id="instructor_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_last_name"
                      >
                        Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Phone #"
                        className="form-control italicInput"
                        name="instructor_phone"
                        value={state.instructor_phone}
                        id="instructor_phone"
                        onChange={(e) =>
                          handleFieldChange("instructor_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_phone"
                      >
                        Phone #
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="WhatsApp"
                        className="form-control italicInput"
                        value={state.instructor_whatsapp}
                        name="instructor_whatsapp"
                        id="instructor_whatsapp"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_whatsapp"
                      >
                        WhatsApp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="email"
                        className="form-control italicInput"
                        name="instructor_email"
                        value={state.instructor_email}
                        id="instructor_email"
                        onChange={(e) =>
                          handleFieldChange("instructor_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_email"
                      >
                        Email Address
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="instructor_bio"
                        value={state.instructor_bio}
                        id="instructor_bio"
                        placeholder="Enter Bio of Organization"
                        onChange={(e) =>
                          handleFieldChange("instructor_bio", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_bio"
                      >
                        Brief Bio
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mx-0">
                  <div className="form-section-header mt-4 mb-3">
                    <hr />
                    <h4 className="theme-text-2">Organizer Details</h4>
                  </div>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_first_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_first_name"
                        ref={organizerFirstNameRef}
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_first_name
                            : state.organizer_first_name
                        }
                        id="organizer_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_first_name"
                      >
                        Organizer First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_last_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        ref={organizerLastNameRef}
                        name="organizer_last_name"
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_last_name
                            : state.organizer_last_name
                        }
                        id="organizer_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_last_name"
                      >
                        Organizer Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_company_name"
                        placeholder="Organizer Company"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_company_name"
                        value={state.organizer_company_name}
                        id="organizer_company_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_company_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_company_name"
                      >
                        Organizer Company Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Organizer Email"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        ref={organizerEmailRef}
                        name="organizer_email"
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_email
                            : state.organizer_email
                        }
                        id="organizer_email"
                        onChange={(e) =>
                          handleFieldChange("organizer_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_email"
                      >
                        Organizer Email:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        className="form-control"
                        name="whatsapp"
                        id="organizer_whatsapp"
                        ref={organizerWhatsappRef}
                        placeholder="organizer_whatsapp"
                        disabled={instructorSameAsOrganizer ? true : false}
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_whatsapp
                            : state.organizer_whatsapp
                        }
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_whatsapp"
                      >
                        Organizer Whatsapp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Phone"
                        className="form-control"
                        name="organizer_phone"
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_phone
                            : state.organizer_phone
                        }
                        id="organizer_phone"
                        ref={organizerPhoneRef}
                        disabled={instructorSameAsOrganizer ? true : false}
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange("organizer_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_phone"
                      >
                        Organizer Phone
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="Website"
                        value={state.organizer_website}
                        className="form-control"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_website"
                        id="organizer_website"
                        onChange={(e) =>
                          handleFieldChange("organizer_website", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_website"
                      >
                        Organizer Website
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <div className="custom-checkbox form-check ps-0">
                      <label>
                        <input
                          disabled={!instructorDetails()}
                          type="checkbox"
                          className="form-check-input"
                          checked={instructorSameAsOrganizer}
                          onChange={handleCheckboxChange}
                        />
                        <span className="form-check-label">
                          Same as Instructor
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0" className="border-0">
                    <Accordion.Header className="theme-text-2">
                      <h4 className="theme-text-2">Sponsor Details</h4>
                    </Accordion.Header>
                    <Accordion.Body className="px-0">
                      <Row className="mx-0">
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_first_name"
                              placeholder="Sponsor Name"
                              className="form-control italicInput"
                              name="sponsor_first_name"
                              id="sponsor_first_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_first_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_first_name"
                            >
                              Sponsor First Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_last_name"
                              placeholder="Sponsor Name"
                              className="form-control italicInput"
                              name="sponsor_last_name"
                              id="sponsor_last_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_last_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_last_name"
                            >
                              Sponsor Last Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_company_name"
                              placeholder="Sponsor Company"
                              className="form-control italicInput"
                              name="sponsor_company_name"
                              id="sponsor_company_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_company_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_company_name"
                            >
                              Sponsor Company Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_logo_url"
                              placeholder="Sponsor LOGO"
                              className="form-control italicInput"
                              name="sponsor_logo_url"
                              id="sponsor_logo_url"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_logo_url",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_logo_url"
                            >
                              Sponsor Logo URL
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="email"
                              placeholder="Sponsor Email"
                              className="form-control italicInput"
                              name="sponsor_email"
                              id="sponsor_email"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_email",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_email"
                            >
                              Sponsor Email:
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="number"
                              className="form-control"
                              name="whatsapp"
                              id="sponsor_whatsapp"
                              placeholder="sponsor_whatsapp"
                              maxLength="15"
                              minLength="10"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_whatsapp",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_whatsapp"
                            >
                              Sponsor Whatsapp
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="number"
                              placeholder="Phone"
                              className="form-control"
                              name="sponsor_phone"
                              id="sponsor_phone"
                              maxLength="15"
                              minLength="10"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_phone",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_phone"
                            >
                              Sponsor Phone
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="url"
                              placeholder="Website"
                              className="form-control"
                              name="sponsor_website"
                              id="sponsor_website"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_website",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_website"
                            >
                              Sponsor Website
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Row className="mx-0">
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      disabled={!areDetailsFilled()}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 3 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2">Batch Details</h5>
                    <p>Please add any new batches starting soon.</p>
                  </Col>
                  <Col md={12}>
                    {batchData?.map((field, index) => (
                      <React.Fragment key={index}>
                        <BatchForm
                          isCourse={true}
                          key={index}
                          field={field}
                          onFieldChange={(newField) => {
                            const updatedFields = [...batchData];
                            updatedFields[index] = newField;
                            setBatchData(updatedFields);
                          }}
                          indexField={index}
                          onRemove={() =>
                            removeItemById(batchData, setBatchData, field.id)
                          }
                          inputIdPrefix={index}
                        />
                        <hr />
                      </React.Fragment>
                    ))}
                    <Button
                      className="clickable add-button mb-3 ms-auto d-flex"
                      onClick={() => addField(setBatchData, batchData)}
                    >
                      Add More Batches
                    </Button>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 4 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="https://zoom.us/"
                        className="form-control italicInput wizard-form-error"
                        name="online_join_link"
                        id="online_join_link"
                        onChange={(e) =>
                          handleFieldChange("online_join_link", e.target.value)
                        }
                        disabled
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="online_join_link"
                      >
                        Add your own event link
                      </Form.Label>
                      <span className="theme-text-2 d-block">
                        <small>*Only Premium Users Can Add Custom Link</small>
                      </span>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2">Social Links</h5>
                  </Col>
                  <DynamicRelationFieldSet
                    onFieldChange={(fields) => {
                      const dataArray = fields?.map((field) => ({
                        [field.type]: field.value,
                      }));
                      handleFieldChange("social_links", dataArray);
                    }}
                    fieldLabels={{
                      valueInput: "Social Link",
                    }}
                    selectOptions={[
                      { value: "facebook", label: "Facebook" },
                      { value: "instagram", label: "Instagram" },
                      { value: "twitter", label: "Twitter" },
                      { value: "linkedIn", label: "LinkedIn" },
                      { value: "website", label: "Website" },
                      { value: "others", label: "Others" },
                    ]}
                  />

                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2">Location / Venue</h5>
                  </Col>
                  <DynamicLocationFieldSet
                    onFieldChange={(fields) => {
                      handleFieldChange("locations", fields);
                    }}
                  />

                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 5 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2 mb-0">
                      Add any documents, brochures, fliers, images, videos and
                      links related to the {entityName} below
                    </h5>
                  </Col>

                  <Col md={12}>
                    <h6 className="theme-text-2 mt-3">Documents:</h6>
                  </Col>
                  <DynamicFileUploadFieldSets
                    onFieldChange={(documents) => {
                      setDocumentData((prevData) =>
                        prevData ? { ...prevData, documents } : documents
                      );
                    }}
                    acceptedTypes="application/pdf,application/msword,text/plain"
                    inputIdPrefix="documentUpload"
                    noteLabel="PDF/DOC/TXT"
                    fileCategory="document"
                  />

                  <Col md={12}>
                    <hr />
                    <h6 className="theme-text-2 mt-3 mb-0">Images</h6>
                  </Col>

                  <DynamicFileUploadFieldSets
                    onFieldChange={(images) => {
                      setImageData((prevData) =>
                        prevData ? { ...prevData, images } : images
                      );
                    }}
                    acceptedTypes="image/png,image/jpeg,image/jpg"
                    inputIdPrefix="imgUpload"
                    noteLabel="PNG/JPG/JPEG"
                    fileCategory="image"
                  />

                  <Col md={12}>
                    <hr />
                    <h6 className="theme-text-2 mt-3 mb-0">Videos</h6>
                  </Col>
                  <VideoUploader
                    onVideoChange={(videos) => {
                      setVideoData((prevData) =>
                        prevData ? { ...prevData, videos } : { videos }
                      );
                    }}
                    state={videoData}
                    inputIdPrefix="vidUpload"
                    fileCategory="video"
                  />

                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2 mt-3 mb-0">Links</h5>
                  </Col>
                  <DynamicFieldSetURL
                    onFieldChange={(links) => {
                      setRefLinksData((prevData) =>
                        prevData ? { ...prevData, links } : links
                      );
                    }}
                    category="link"
                  />
                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2 my-3 text-capitalize">
                      Add comment for admin
                    </h5>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="comments"
                        value={state.comments}
                        id="comments"
                        placeholder=" Enter your comment"
                        onChange={(e) =>
                          handleFieldChange("comments", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="comments">
                        Description
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="position-relative">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      id="sb-button"
                      className="next-button next demosEditBtn"
                    >
                      Submit
                    </Button>

                    <Toast
                      show={showErrorToast}
                      onClose={() => setShowErrorToast(false)}
                      autohide
                      delay={3200}
                      className="bg-white opacity-1 position-absolute"
                      style={{
                        bottom: "calc(100% + 10px)",
                        right: "10px",
                        zIndex: "999",
                      }}
                    >
                      <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                          <XCircleFill />
                          &nbsp;Error
                        </strong>
                      </Toast.Header>
                      <Toast.Body className="text-dark">
                        Fill all the (
                        <span className="text-danger">required*</span>) fields
                        to submit
                      </Toast.Body>
                    </Toast>
                  </Col>
                </Row>
              </fieldset>
            )}
          </div>
        </Form>
      </Container>

      <ScheduleDateModalCourse
        entityType={courseData.entities}
        show={scheduleModalShow}
        setShow={setScheduleModalShow}
        entity={entityName}
        state={state}
        setState={setState}
        onHide={() => {
          setScheduleModalShow(false);
        }}
      />
    </div>
  );
}
