import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import "./navbarstyle.css";

function UnAuthNavBar() {
  const location = useLocation();
  const logoSrc = SubDomainRouter("logo");
  const homePageRoute = SubDomainRouter("homePageRoute");

  const isHeightMoreThan100vh = window.innerHeight > 100;
  const config = useSelector((state) => state.hostSettings.value);

  const navItemsData = config?.header?.navItems ?? [
    { path: "/", label: "Home" },
    { path: "/contact", label: "Contact" },
  ];

  return (
    <>
      <Navbar
        expand="lg"
        className={
          isHeightMoreThan100vh
            ? "align-items-center py-0 pad"
            : "align-items-center py-0"
        }
      >
        <Container fluid="lg">
          <Link
            className="navbar-brand"
            to={`${homePageRoute === "/home" ? "/" : homePageRoute || "/"}`}
          >
            <img src={`${logoSrc}`} alt="" />
          </Link>
          <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block d-none">
            <Nav className="mx-auto my-lg-0 my-3 align-items-center">
              {navItemsData.map(
                (item, index) => (
                  <Link
                    key={item.path}
                    style={{ order: item?.index ?? index }}
                    to={item.path}
                    className={`nav-link text-capitalize ${location.pathname === item.path ? "active-link" : ""} ${item.path?.includes(homePageRoute) ? "set-first" : ""}`}
                  >
                    {item.label}
                  </Link>
                )
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="right d-flex align-items-center gap-sm-3 gap-2">
            <Link className="btn add-button px-4" to="/my-profile">
              Sign In
            </Link>
            <NavDropdown
              className="dropDownImg d-flex align-items-center justify-content-center d-lg-none d-block"
              title={<Navbar.Toggle />}
              id="basic-nav-dropdown"
            >
              {navItemsData.map(
                (item, index) => (
                  <Link
                    key={item.path}
                    style={{ order: item?.index ?? index }}
                    to={item.path}
                    className={`nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${location.pathname === item.path ? "active-link" : ""} ${item.path?.includes(homePageRoute) ? "set-first" : ""}`}
                  >
                    {item.label}
                  </Link>
                )
              )}
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default UnAuthNavBar;
