import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as Actions from "../../constants/baseUrl";
import "./add.css";
import "./form.css";

import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Row,
  Toast,
} from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import VideoUploader from "../VideoUploader/VideoUploader ";
import AddTag from "../tagInput/tagInput";
import BannerUploaderEdit from "../bannerUploaderEdit/bannerUploaderEdit";
import BatchForm from "../batchForm/batchForm";
import DynamicRelationFieldSet from "../dynamicRelationFieldSet/dynamicRelationFieldSet";
import DynamicFieldSetURL from "../dynamicUrlField/dynamicUrlField";
import DynamicFileUploadFieldSets from "../fileUploadFieldSet/fileUploadFieldSet";
import DynamicLocationFieldSet from "../locationFieldSet/locationFieldSet";
import entityData from "./config.json";
import TokenDetails from "../useUserDetails/useTokenDetails";
import { toast } from "react-toastify";

export default function AddInstitute() {
  const entityName = entityData?.entity;
  const entities = entityData?.entities;

  const navigate = useNavigate();

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(false);
  const [entityTypesData, setEntityTypesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [courseNamesData, setCourseNamesData] = useState([]);
  const [state, setState] = useState({
    userId: myUserId,
    type: entityName,
    entity_type: entityName,
    tags: [],
    entityRelation: [],
    social_links: [],
    attachments: [],
    locations: [],
    approval_status: "",
    category: "",
    amount: "",
    currency: "",
    subcategory: "",
    subcategorylevel1: "",
    subcategorylevel2: "",
    comments: "",
    title: "",
    description: "",
    meeting_id: "",
    rsvp_redirect_url: "",
    video_url: "",
    share_link: "",
    online_join_link: "",
    star_rating_type: "",
    instructor_first_name: "",
    instructor_last_name: "",
    instructor_email: "",
    instructor_phone: "",
    instructor_whatsapp: "",
    instructor_bio: "",
    organizer_first_name: "",
    organizer_last_name: "",
    organizer_company_name: "",
    organizer_phone: "",
    organizer_whatsapp: "",
    organizer_email: "",
    organizer_website: "",
    listing_image_url: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
  });
  const [addedTags, setAddedTags] = useState([]);
  const [step, setStep] = useState(1);
  const [showErrorToast, setShowErrorToast] = useState(false);
  // Attachment Data
  const [documentData, setDocumentData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [refLinksData, setRefLinksData] = useState([]);
  const [instructorSameAsOrganizer, setInstructorSameAsOrganizer] =
    useState(false);

  const batchFields = [
    {
      courseName: "",
      date: "",
      startTime: "",
      duration: "",
      teaching_mode: "",
      trainer: "",
    },
  ];

  const [batchData, setBatchData] = useState(batchFields);

  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...state,
      [fieldName]: fieldValue,
    };
    setState(newState);
    console.log(state);
  };

  const handleCheckboxChange = () => {
    setInstructorSameAsOrganizer(!instructorSameAsOrganizer);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    const addedAttachments = {
      documents: documentData.documents,
      images: imageData.images,
      videos: videoData.videos,
      links: refLinksData.links,
    };
    const data = {
      ...state,
      tags: addedTags,
      batches: batchData,
      attachments: addedAttachments,
      amount: state.amount,
    };

    axios
      .post(`${Actions.BASE_URL}${entities}`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.id);
        navigate(`/share/${entityName}/${res.data.id}/`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 413) {
          // Handle Payload Too Large
          toast.error(
            "Attachments are too large. Please check your attachments and try again."
          );
        } else if (error.response.status === 400) {
          setShowErrorToast(true);
        }
        console.log(error.response);
      });
  };
  // Get  types
  const getDemoType = () => {
    axios
      .get(`${Actions.BASE_URL}enums/teachingModes`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setEntityTypesData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // get sub categories
  const getSubCategory = () => {
    axios
      .get(`${Actions.BASE_URL}enums/subcategoryTypes`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSubCategoryData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // Get course names
  const getCourseNames = () => {
    axios
      .get(`${Actions.BASE_URL}enums/courseNames`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCourseNamesData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // Get entity Type
  const getCategories = () => {
    axios
      .get(`${Actions.BASE_URL}enums/paymentTypes`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCategoriesData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const titleInputRef = useRef(null);

  useEffect(() => {
    titleInputRef?.current?.focus();

    getDemoType();
    getCategories();
    getSubCategory();
    getCourseNames();
  }, []);

  // Details filled

  const organizerFirstNameRef = useRef(null);
  const organizerLastNameRef = useRef(null);
  const organizerEmailRef = useRef(null);
  const organizerPhoneRef = useRef(null);
  const organizerWhatsappRef = useRef(null);

  useEffect(() => {
    if (instructorSameAsOrganizer) {
      const updateState = {
        ...state,
        organizer_first_name: state.instructor_first_name,
        organizer_last_name: state.instructor_last_name,
        organizer_email: state.instructor_email,
        organizer_phone: state.instructor_phone,
        organizer_whatsapp: state.instructor_whatsapp,
      };

      setState(updateState);
    }
  }, [instructorSameAsOrganizer]);

  // Checking field values

  const step1Fields = [
    "title",
    "category",
    "subcategory",
    "subcategorylevel1",
    "subcategorylevel2",
  ];

  const areFieldsFilled = (fieldNames, state) => {
    return fieldNames.every((fieldName) => state[fieldName]);
  };

  const isStep1FieldsFilled = areFieldsFilled(step1Fields, state);

  const areDetailsFilled = () => {
    return (
      (state.instructor_first_name &&
        state.instructor_last_name &&
        state.instructor_email &&
        state.instructor_phone) ||
      (organizerFirstNameRef?.current?.value &&
        organizerLastNameRef?.current?.value &&
        organizerEmailRef?.current?.value &&
        organizerPhoneRef?.current?.value)
    );
  };

  const instructorDetails = () => {
    return (
      state.instructor_first_name &&
      state.instructor_last_name &&
      state.instructor_email &&
      state.instructor_phone
    );
  };

  const handleNext = () => {
    const isSubcategoryPaid = state.subcategorylevel1 === "paid";
    const isStep1FieldsFilled = step1Fields.every((field) => state[field]);

    if (
      isStep1FieldsFilled &&
      (!isSubcategoryPaid ||
        (isSubcategoryPaid && state.currency && state.amount))
    ) {
      setStep((prevStep) => prevStep + 1);
      setHasValue(false);
      setIsValid(true);
    } else {
      setHasValue(true);
      setIsValid(true);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const addField = (setField, field) => {
    setField([...field, {}]);
  };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  return (
    <div className="demosAddFormSection demosEditSection">
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Container fluid="lg" className="formMain create my-3">
        <div className="demosAddFormHeader text-center">
          <h2 className="addFormHeading text-capitalize">
            {entityData?.addPage_title}
          </h2>
          <div className="demosEditHeader text-center mb-3 mb-lg-5">
            <h5>Add Details Below:</h5>
            <ul className="demosEditList">
              <li>
                <button
                  className={
                    step === 1 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(1)}
                >
                  <span>1</span>
                </button>
              </li>
              <li>
                <button
                  className={
                    step === 2 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(2)}
                  disabled={!isStep1FieldsFilled}
                >
                  <span>2</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled || !areDetailsFilled()}
                  className={
                    step === 3 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(3)}
                >
                  <span>3</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled || !areDetailsFilled()}
                  className={
                    step === 4 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(4)}
                >
                  <span>4</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled || !areDetailsFilled()}
                  className={
                    step === 5 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(5)}
                >
                  <span>5</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <Form
          id="add-demo-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-section addFormSection event-detail">
            {step === 1 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className={`italicInput ${
                          isValid && hasValue && !state.title
                            ? "border-danger"
                            : ""
                        }`}
                        id="title"
                        required
                        value={state.title}
                        placeholder="Enter Event Title"
                        name="title"
                        ref={titleInputRef}
                        onChange={(e) =>
                          handleFieldChange("title", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="title">
                        Title
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="description"
                        value={state.description}
                        id="description"
                        placeholder=" Enter your event description"
                        onChange={(e) =>
                          handleFieldChange("description", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="description">
                        Description
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <BannerUploaderEdit
                      label="Big Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="hero_image"
                      placehold="https://placehold.co/1024x200"
                      state={state}
                      setState={setState}
                      imageURL={state.hero_image_url}
                      imageContent={state.hero_image_content}
                      imageExtension={state.hero_image_extension}
                    />
                  </Col>

                  <Col md={6}>
                    <BannerUploaderEdit
                      label="Small Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="listing_image"
                      placehold="https://placehold.co/280x180"
                      state={state}
                      setState={setState}
                      imageURL={state.listing_image_url}
                      imageContent={state.listing_image_content}
                      imageExtension={state.listing_image_extension}
                    />
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        id="video_url"
                        name="video_url"
                        value={state.video_url}
                        type="text"
                        placeholder="Video Url"
                        className="form-control input-md italicInput"
                        onChange={(e) =>
                          handleFieldChange("video_url", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="video_url">
                        Video Url
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="category"
                        className={`form-control form-select ${
                          isValid && hasValue && !state.category
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        value={state.category}
                        name="category"
                        onChange={(e) =>
                          handleFieldChange("category", e.target.value)
                        }
                      >
                        <option value="">Course</option>
                        {courseNamesData?.map((course, index) => (
                          <option key={course.key} value={course.value}>
                            {course.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="subcategory"
                        className={`form-control form-select ${
                          isValid && hasValue && !state.subcategory
                            ? "border-danger"
                            : ""
                        }`}
                        value={state.subcategory}
                        required
                        name="subcategory"
                        multiple=""
                        onChange={(e) =>
                          handleFieldChange("subcategory", e.target.value)
                        }
                      >
                        <option value="">Learning Level</option>
                        {subCategoryData?.map((course, index) => (
                          <option key={course.key} value={course.value}>
                            {course.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating  mb-3">
                      <Form.Select
                        id="subcategorylevel1"
                        className={`form-control text-capitalize ${
                          isValid && hasValue && !state.subcategorylevel1
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        value={state.subcategorylevel1}
                        name="subcategorylevel1"
                        multiple=""
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel1", e.target.value)
                        }
                      >
                        <option value="">Select Payment Option</option>
                        {categoriesData?.map((course) => (
                          <option key={course.key} value={course.value}>
                            {course.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        className={`form-control ${
                          isValid && hasValue && !state.subcategorylevel2
                            ? "border-danger"
                            : ""
                        }`}
                        aria-label="event type"
                        required
                        value={state.subcategorylevel2}
                        id="subcategorylevel2"
                        name="subcategorylevel2"
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel2", e.target.value)
                        }
                      >
                        <option value="">Teaching Mode</option>
                        {entityTypesData?.map((type, index) => (
                          <option key={index} value={type.value}>
                            {type.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {state.subcategorylevel1.toLowerCase() === "paid" && (
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <div className="d-flex gap-3">
                        <Form.Group className="form-floating w-25 mb-3">
                          <select
                            id="currency"
                            required
                            className={`form-control form-select text-uppercase ${
                              isValid && hasValue && !state.currency
                                ? "border-danger"
                                : ""
                            }`}
                            name="currency"
                            value={state.currency}
                            onChange={(e) =>
                              handleFieldChange("currency", e.target.value)
                            }
                          >
                            <option value="">Currency</option>
                            <option value="usd">usd</option>
                            <option value="cad">cad</option>
                            <option value="inr">inr</option>
                            <option value="eur">eur</option>
                            <option value="gbp">gbp</option>
                          </select>
                        </Form.Group>
                        <Form.Group className="form-floating mb-3 flex-grow-1">
                          <Form.Control
                            id="amount"
                            name="amount"
                            type="number"
                            required
                            value={state.amount}
                            onChange={(e) =>
                              handleFieldChange("amount", e.target.value)
                            }
                            placeholder="Amount"
                            className={`form-control input-md italicInput ${
                              isValid && hasValue && !state.amount
                                ? "border-danger"
                                : ""
                            }`}
                          />
                          <Form.Label className="form-label" htmlFor="amount">
                            Enter Amount
                          </Form.Label>
                          <span className="position-absolute end-0 h-100 top-0 d-flex align-items-center pe-3">
                            .00
                          </span>
                        </Form.Group>
                      </div>
                    </Col>
                  )}

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control form-select fromcontrol"
                        id="star_rating_type"
                        value={state.star_rating_type}
                        name="star_rating_type"
                        onChange={(e) =>
                          handleFieldChange("star_rating_type", e.target.value)
                        }
                      >
                        <option value="">How Do You Rate This Session?</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={12}>
                    <AddTag
                      label={"Tags"}
                      addedTags={addedTags}
                      setAddedTags={setAddedTags}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="next demosEditBtn "
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 2 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col sm={12} className="form-section-header mt-4 mb-3">
                    <h4 className="theme-text-2">Instructor Details</h4>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        className="form-control italicInput"
                        name="instructor_first_name"
                        value={state.instructor_first_name}
                        id="instructor_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_first_name"
                      >
                        First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="form-control italicInput"
                        value={state.instructor_last_name}
                        name="instructor_last_name"
                        id="instructor_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_last_name"
                      >
                        Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Phone #"
                        className="form-control italicInput"
                        name="instructor_phone"
                        value={state.instructor_phone}
                        id="instructor_phone"
                        onChange={(e) =>
                          handleFieldChange("instructor_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_phone"
                      >
                        Phone #
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="WhatsApp"
                        className="form-control italicInput"
                        value={state.instructor_whatsapp}
                        name="instructor_whatsapp"
                        id="instructor_whatsapp"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_whatsapp"
                      >
                        WhatsApp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="email"
                        className="form-control italicInput"
                        name="instructor_email"
                        value={state.instructor_email}
                        id="instructor_email"
                        onChange={(e) =>
                          handleFieldChange("instructor_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_email"
                      >
                        Email Address
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="instructor_bio"
                        value={state.instructor_bio}
                        id="instructor_bio"
                        placeholder="Enter Bio of Organization"
                        onChange={(e) =>
                          handleFieldChange("instructor_bio", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_bio"
                      >
                        Brief Bio
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mx-0">
                  <div className="form-section-header mt-4 mb-3">
                    <hr />
                    <h4 className="theme-text-2">Organizer Details</h4>
                  </div>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_first_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_first_name"
                        ref={organizerFirstNameRef}
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_first_name
                            : state.organizer_first_name
                        }
                        id="organizer_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_first_name"
                      >
                        Organizer First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_last_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        ref={organizerLastNameRef}
                        name="organizer_last_name"
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_last_name
                            : state.organizer_last_name
                        }
                        id="organizer_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_last_name"
                      >
                        Organizer Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_company_name"
                        placeholder="Organizer Company"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_company_name"
                        value={state.organizer_company_name}
                        id="organizer_company_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_company_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_company_name"
                      >
                        Organizer Company Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Organizer Email"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        ref={organizerEmailRef}
                        name="organizer_email"
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_email
                            : state.organizer_email
                        }
                        id="organizer_email"
                        onChange={(e) =>
                          handleFieldChange("organizer_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_email"
                      >
                        Organizer Email:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        className="form-control"
                        name="whatsapp"
                        id="organizer_whatsapp"
                        ref={organizerWhatsappRef}
                        placeholder="organizer_whatsapp"
                        disabled={instructorSameAsOrganizer ? true : false}
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_whatsapp
                            : state.organizer_whatsapp
                        }
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_whatsapp"
                      >
                        Organizer Whatsapp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Phone"
                        className="form-control"
                        name="organizer_phone"
                        value={
                          instructorSameAsOrganizer
                            ? state.instructor_phone
                            : state.organizer_phone
                        }
                        id="organizer_phone"
                        ref={organizerPhoneRef}
                        disabled={instructorSameAsOrganizer ? true : false}
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange("organizer_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_phone"
                      >
                        Organizer Phone
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="Website"
                        value={state.organizer_website}
                        className="form-control"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_website"
                        id="organizer_website"
                        onChange={(e) =>
                          handleFieldChange("organizer_website", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_website"
                      >
                        Organizer Website
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <div className="custom-checkbox form-check ps-0">
                      <label>
                        <input
                          disabled={!instructorDetails()}
                          type="checkbox"
                          className="form-check-input"
                          checked={instructorSameAsOrganizer}
                          onChange={handleCheckboxChange}
                        />
                        <span className="form-check-label">
                          Same as Instructor
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0" className="border-0">
                    <Accordion.Header className="theme-text-2">
                      <h4 className="theme-text-2">Sponsor Details</h4>
                    </Accordion.Header>
                    <Accordion.Body className="px-0">
                      <Row className="mx-0">
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_first_name"
                              placeholder="Sponsor Name"
                              className="form-control italicInput"
                              name="sponsor_first_name"
                              id="sponsor_first_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_first_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_first_name"
                            >
                              Sponsor First Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_last_name"
                              placeholder="Sponsor Name"
                              className="form-control italicInput"
                              name="sponsor_last_name"
                              id="sponsor_last_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_last_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_last_name"
                            >
                              Sponsor Last Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_company_name"
                              placeholder="Sponsor Company"
                              className="form-control italicInput"
                              name="sponsor_company_name"
                              id="sponsor_company_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_company_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_company_name"
                            >
                              Sponsor Company Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_logo_url"
                              placeholder="Sponsor LOGO"
                              className="form-control italicInput"
                              name="sponsor_logo_url"
                              id="sponsor_logo_url"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_logo_url",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_logo_url"
                            >
                              Sponsor Logo URL
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="email"
                              placeholder="Sponsor Email"
                              className="form-control italicInput"
                              name="sponsor_email"
                              id="sponsor_email"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_email",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_email"
                            >
                              Sponsor Email:
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="number"
                              className="form-control"
                              name="whatsapp"
                              id="sponsor_whatsapp"
                              placeholder="sponsor_whatsapp"
                              maxLength="15"
                              minLength="10"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_whatsapp",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_whatsapp"
                            >
                              Sponsor Whatsapp
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="number"
                              placeholder="Phone"
                              className="form-control"
                              name="sponsor_phone"
                              id="sponsor_phone"
                              maxLength="15"
                              minLength="10"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_phone",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_phone"
                            >
                              Sponsor Phone
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="url"
                              placeholder="Website"
                              className="form-control"
                              name="sponsor_website"
                              id="sponsor_website"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_website",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_website"
                            >
                              Sponsor Website
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Row className="mx-0">
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      disabled={!areDetailsFilled()}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 3 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2">Batch Details</h5>
                    <p>Please add any new batches starting soon.</p>
                  </Col>
                  <Col md={12}>
                    {batchData?.map((field, index) => (
                      <React.Fragment key={index}>
                        <BatchForm
                          key={index}
                          field={field}
                          onFieldChange={(newField) => {
                            const updatedFields = [...batchData];
                            updatedFields[index] = newField;
                            setBatchData(updatedFields);
                          }}
                          indexField={index}
                          onRemove={() =>
                            removeItemById(batchData, setBatchData, field.id)
                          }
                          inputIdPrefix={index}
                        />
                        <hr />
                      </React.Fragment>
                    ))}
                    <Button
                      className="clickable add-button mb-3 ms-auto d-flex"
                      onClick={() => addField(setBatchData, batchData)}
                    >
                      Add More Batches
                    </Button>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 4 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="https://zoom.us/"
                        className="form-control italicInput wizard-form-error"
                        name="online_join_link"
                        id="online_join_link"
                        onChange={(e) =>
                          handleFieldChange("online_join_link", e.target.value)
                        }
                        disabled
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="online_join_link"
                      >
                        Add your own event link
                      </Form.Label>
                      <span className="theme-text-2 d-block">
                        <small>*Only Premium Users Can Add Custom Link</small>
                      </span>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2">Social Links</h5>
                  </Col>
                  <DynamicRelationFieldSet
                    onFieldChange={(fields) => {
                      const dataArray = fields?.map((field) => ({
                        [field.type]: field.value,
                      }));
                      handleFieldChange("social_links", dataArray);
                    }}
                    fieldLabels={{
                      valueInput: "Social Link",
                    }}
                    selectOptions={[
                      { value: "facebook", label: "Facebook" },
                      { value: "instagram", label: "Instagram" },
                      { value: "twitter", label: "Twitter" },
                      { value: "linkedIn", label: "LinkedIn" },
                      { value: "website", label: "Website" },
                      { value: "others", label: "Others" },
                    ]}
                  />

                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2">Location / Venue</h5>
                  </Col>
                  <DynamicLocationFieldSet
                    onFieldChange={(fields) => {
                      console.log(fields);
                      handleFieldChange("locations", fields);
                    }}
                  />

                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 5 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2 mb-0">
                      Add any documents, brochures, fliers, images, videos and
                      links related to the {entityName} below
                    </h5>
                  </Col>

                  <Col md={12}>
                    <h6 className="theme-text-2 mt-3">Documents:</h6>
                  </Col>
                  <DynamicFileUploadFieldSets
                    onFieldChange={(documents) => {
                      setDocumentData((prevData) =>
                        prevData ? { ...prevData, documents } : documents
                      );
                    }}
                    acceptedTypes="application/pdf,application/msword,text/plain"
                    inputIdPrefix="documentUpload"
                    noteLabel="PDF/DOC/TXT"
                    fileCategory="document"
                  />

                  <Col md={12}>
                    <hr />
                    <h6 className="theme-text-2 mt-3 mb-0">Images</h6>
                  </Col>

                  <DynamicFileUploadFieldSets
                    onFieldChange={(images) => {
                      setImageData((prevData) =>
                        prevData ? { ...prevData, images } : images
                      );
                    }}
                    acceptedTypes="image/png,image/jpeg,image/jpg"
                    inputIdPrefix="imgUpload"
                    noteLabel="PNG/JPG/JPEG"
                    fileCategory="image"
                  />

                  <Col md={12}>
                    <hr />
                    <h6 className="theme-text-2 mt-3 mb-0">Videos</h6>
                  </Col>
                  <VideoUploader
                    onVideoChange={(videos) => {
                      setVideoData((prevData) =>
                        prevData ? { ...prevData, videos } : { videos }
                      );
                    }}
                    state={videoData}
                    inputIdPrefix="vidUpload"
                    fileCategory="video"
                  />

                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2 mt-3 mb-0">Links</h5>
                  </Col>
                  <DynamicFieldSetURL
                    onFieldChange={(links) => {
                      setRefLinksData((prevData) =>
                        prevData ? { ...prevData, links } : links
                      );
                    }}
                    category="link"
                  />
                  <Col md={12}>
                    <hr />
                    <h5 className="theme-text-2 my-3 text-capitalize">
                      Add comment for admin
                    </h5>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="comments"
                        value={state.comments}
                        id="comments"
                        placeholder=" Enter your comment"
                        onChange={(e) =>
                          handleFieldChange("comments", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="comments">
                        Description
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="position-relative">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      id="sb-button"
                      className="next-button next demosEditBtn"
                    >
                      Submit
                    </Button>

                    <Toast
                      show={showErrorToast}
                      onClose={() => setShowErrorToast(false)}
                      autohide
                      delay={3200}
                      className="bg-white opacity-1 position-absolute"
                      style={{
                        bottom: "calc(100% + 10px)",
                        right: "10px",
                        zIndex: "999",
                      }}
                    >
                      <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                          <XCircleFill />
                          &nbsp;Error
                        </strong>
                      </Toast.Header>
                      <Toast.Body className="text-dark">
                        Fill all the (
                        <span className="text-danger">required*</span>) fields
                        to submit
                      </Toast.Body>
                    </Toast>
                  </Col>
                </Row>
              </fieldset>
            )}
          </div>
        </Form>
      </Container>
    </div>
  );
}
