import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../../constants/withRouter";
import * as Actions from "../../../constants/baseUrl";
import CustomLoader from "../../../components/loader/loader";

const SuperAdminUsersEdit = (props) => {
  const navigate = useNavigate();

  const userId = props?.params?.id;
  const myToken = localStorage.getItem("token");

  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    role: "",
    email: "",
  });

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        await axios
          .get(`${Actions.BASE_URL}configs`, {
            headers: {
              Authorization: `Bearer ${myToken}`,
            },
          })
          .then((response) => {
            setUserRoles(response.data?.appsettingsarrayobjects?.userRole);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoles();
  }, [myToken]);

  useEffect(() => {
    const populateList = async () => {
      try {
        const data = await fetch(`${Actions.BASE_URL}users/${userId}`).then(
          (response) => {
            return response.json();
          }
        );

        setFormData(data);
        setLoading(false);
      } catch (error) {
        console.log("There was an error", error);
        setLoading(false);
      }
    };
    populateList();
  }, [userId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);
    axios.put(`${Actions.BASE_URL}users/${userId}`, formData).then((res) => {
      navigate(`/super-admin-dashboard/users`);
      setReqLoading(false);
    });
  };

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="py-5 mt-5 profile-edit">
          <h3 className="heading-text theme-text-2 text-center pt-3">
            Edit User's Data
          </h3>
          <div className="demosAddForm">
            <Form className="row" onSubmit={handleSubmit}>
              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="Type"
                    value={formData?.first_name}
                    onChange={(e) =>
                      handleFieldChange("first_name", e.target.value)
                    }
                  />
                  <label htmlFor="first_name">First Name</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Type"
                    value={formData?.last_name}
                    onChange={(e) =>
                      handleFieldChange("last_name", e.target.value)
                    }
                  />
                  <label htmlFor="last_name">Last Name</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Select
                    id="role"
                    required
                    onChange={(e) => handleFieldChange("role", e.target.value)}
                  >
                    <option value="">Select Role</option>
                    {userRoles?.map((userRole, index) => (
                      <option
                        value={userRole?.key}
                        key={index}
                        className="text-capitalize"
                      >
                        {userRole.value}
                      </option>
                    ))}
                    <option value={"admin"} className="text-capitalize">
                      Admin
                    </option>
                  </Form.Select>
                  <Form.Label htmlFor="select">Create Account As</Form.Label>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData?.email}
                    onChange={(e) => handleFieldChange("email", e.target.value)}
                  />
                  <label htmlFor="email">Email</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    value={formData?.phone}
                    onChange={(e) => handleFieldChange("phone", e.target.value)}
                  />
                  <label htmlFor="phone">Phone</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="tel"
                    id="whatsapp"
                    name="whatsapp"
                    placeholder="WhatsApp"
                    value={formData?.whatsapp}
                    onChange={(e) =>
                      handleFieldChange("whatsapp", e.target.value)
                    }
                  />
                  <label htmlFor="whatsapp">WhatsApp</label>
                </Form.Group>
              </Col>

              <Col
                lg={12}
                className="d-flex justify-content-between flex-wrap gap-2"
              >
                <Link
                  to={`/super-admin-dashboard/users`}
                  className="btn add-button prev w-auto"
                  title="Cancel edit"
                >
                  Discard
                </Link>
                <Button type="submit" className="add-button next w-auto">
                  Update
                </Button>
              </Col>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default withRouter(SuperAdminUsersEdit);
