import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { PencilSquare, PlusCircle, Share, Trash } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import SocialShare from "../../components/share/socialShare";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import USER from "../../img/user.png";

const ServiceProvider = () => {
  const currentDomain = window.location.hostname;

  const serviceProviderAlias = SubDomainRouter("serviceProviderAlias");
  const myToken = localStorage.getItem("token");
  const role = useUserData().role?.toLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState(null);
  const [displayedCards, setDisplayedCards] = useState(8);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await API("get", "items/service-provider");
      setItemData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}items/service-provider/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                fetchData();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  // Define regular expressions for filtering
  const itemList = (serviceProvider) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= serviceProvider?.length
          ? prevDisplayedCards + 4
          : serviceProvider?.length
      );
    };

    return (
      <Container fluid="lg" className="formMain create my-5 py-5">
        <Row>
          <Col className="mb-3" lg={10} sm={8}>
            <h3
              id="ServiceProvider"
              className="pageSubHeading mb-0 text-sm-start text-center text-capitalize"
            >
              {serviceProviderAlias ? serviceProviderAlias : "ServiceProvider"}
            </h3>
          </Col>
          {role === "admin" ||
            role === "siteadmin" ||
            role === "superadmin" ||
            role === "trainer" ||
            role === "training-coordinator" ? (
            <Col
              lg={2}
              sm={4}
              className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
            >
              <Link
                to={`/service-provider/items/add`}
                type="button"
                className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
              >
                <PlusCircle /> &nbsp; Add
              </Link>
            </Col>
          ) : null}
        </Row>

        <Row className="mx-0">
          {itemData ? (
            itemData?.map((item) => (
              <Col xl={4} lg={6} sm={10} key={item.id} className="mt-2 my-3">
                <Card className="profileCard">
                  <div className="contUser d-flex align-items-end gap-2">
                    <Link
                      to={`/service-provider/items/details/${item.id}`}
                      className="userImage"
                    >
                      <Card.Img
                        src={item?.data?.listing_image_url || USER}
                        alt="Load Error"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = USER;
                        }}
                        className="img-fluid"
                      />
                    </Link>
                    <div className="d-flex align-items-center justify-content-between gap-1">
                      <div className="det-cont flex-grow-1">
                        <Link
                          to={`/service-provider/items/details/${item.id}`}
                          className="username mt-2"
                        >
                          {item.data.first_name}&nbsp;{item.data.last_name}
                        </Link>
                        {item.data.organization_name && (
                          <p className="info specialization mb-1">
                            {item.data.organization_name}
                          </p>
                        )}
                        {item.data.category && (
                          <p className="info specialization mb-1 fw-normal">
                            <span>{`(${item.data.category})`}</span>
                          </p>
                        )}
                      </div>
                      <Link
                        to={`/share/items_service-providers/${item.id}`}
                        className="actionBtn btn action entity-share-btn d-flex align-items-center cursor-pointer p-0 rounded-circle"
                        title="review"
                      >
                        <Share />
                      </Link>
                    </div>
                  </div>
                  <Card.Body className="text-start flex-column d-flex justify-content-between rounded-3">
                    <div className="d-flex gap-2 justify-content-center mb-3 mt-1 align-items-center">
                      <SocialShare
                        url={`https://${currentDomain}/service-provider/items/details/${item?.id}/`}
                        iconsSize={30}
                        title={
                          item?.data?.first_name + " " + item?.data?.last_name
                        }
                        image={item?.data?.listing_image_url}
                        description={item?.data?.description}
                      />
                    </div>
                    <div className="itemEdit d-flex align-items-stretch justify-content-center flex-sm-nowrap gap-2">
                      {role === "admin" ||
                        role === "siteadmin" ||
                        role === "superadmin" ||
                        item?.data?.userId?.toLowerCase() === myUserId ? (
                        <Link
                          to={`/service-provider/items/edit/${item.id}`}
                          className="actionBtn btn d-flex align-items-center justify-content-center text-center w-fit"
                        >
                          <span className="d-sm-block d-none">Edit</span>
                          <PencilSquare className="d-sm-none d-block" />
                        </Link>
                      ) : null}
                      <Link
                        to={`/service-provider/items/details/${item.id}`}
                        className="actionBtn btn action cursor-pointer flex-grow-1"
                      >
                        View Profile
                      </Link>
                      {role === "admin" ||
                        role === "siteadmin" ||
                        role === "superadmin" ||
                        item?.data?.userId?.toLowerCase() === myUserId ? (
                        <Link
                          to="#"
                          className="actionDltBtn btn d-flex align-items-center justify-content-center text-center w-fit"
                          onClick={() => deleteItem(item.id)}
                        >
                          <span className="d-sm-block d-none">Delete</span>
                          <Trash className="d-sm-none d-block" />
                        </Link>
                      ) : null}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>No {serviceProviderAlias} found..</p>
          )}
        </Row>

        {!loading && displayedCards < itemData?.length ? (
          <div className="text-center mb-5">
            <Button
              onClick={handleShowMore}
              className="btn actionBtnMore action cursor-pointer w-auto add-button"
            >
              Show More
            </Button>
          </div>
        ) : null}
      </Container>
    );
  };

  const siteName = SubDomainRouter("title");
  let uppercaseTitle = serviceProviderAlias.toLocaleUpperCase();
  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(itemData)}
    </>
  );
};

export default ServiceProvider;
