import React from "react";
import { Container, Nav } from "react-bootstrap";
import { BookHalf, HddStackFill, PersonVideo3 } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import "./dashboard.css";
import { BiDesktop } from "react-icons/bi";

const MyDashboard = () => {
  const location = useLocation();

  const siteName = SubDomainRouter("title");
  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const eventsAlias = SubDomainRouter("eventsAlias");
  const instructorAlias = SubDomainRouter("instructorAlias");
  const documentId = "1Dto8pMGVV5wl6IPkVzQLryDkzYQ79Ghd1WkYWLBMduM";
  const embedUrl = `https://docs.google.com/document/d/${documentId}/preview`;
  return (
    <>
      <Helmet>
        <title>
          My Dashboard | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <div className="mt-4 pt-5 dashboardSec">
        <div className="d-flex flex-md-row flex-column">
          <div className="dashboard-nav px-0 py-md-5">
            <div className="d-flex flex-md-column pb-md-5 gap-md-0 gap-4 justify-content-center">
              <Nav.Item>
                <Link
                  to="/dashboard/my-events"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/dashboard/my-events" && "active"
                    }`}
                >
                  <BiDesktop /> {eventsAlias ? eventsAlias : "events"}
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to="/dashboard/my-demos"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/dashboard/my-demos" && "active"
                    }`}
                >
                  <PersonVideo3 /> {demosAlias ? demosAlias : "demos"}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/dashboard/my-courses"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/dashboard/my-courses" && "active"
                    }`}
                >
                  <BookHalf /> {coursesAlias ? coursesAlias : "courses"}
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to="/dashboard/my-groups"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/dashboard/my-groups" && "active"
                    }`}
                >
                  <HddStackFill /> My Groups
                </Link>
              </Nav.Item>
            </div>
          </div>
          <Container fluid="lg" className="px-lg-4 pb-5 pt-5 flex-grow-1">
            <Outlet />
            <Link
              to={`/guide/instructor`}
              className="theme-text-2 pt-3 d-block"
              target="_blank"
            >
              How does it work for {instructorAlias}?
            </Link>
          </Container>
        </div>
      </div>
    </>
  );
};

export default MyDashboard;
