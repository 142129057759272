import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CaretUpSquare } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import * as Actions from "../../constants/baseUrl";
import "./subscriptions.css";

const Subscriptions = () => {
  const [loading, setLoading] = useState(true);

  const [domainData, setDomainData] = useState({});
  const [categoryData, setCategoryData] = useState({});

  const siteName = SubDomainRouter("title");
  const fromEmail = SubDomainRouter("fromEmail");

  const myToken = localStorage.getItem("token");

  const populateList = async () => {
    axios
      .get(`${Actions.BASE_URL}configs`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      })
      .then((response) => {
        setLoading(false);

        setCategoryData(response.data?.categorysettings);
        setDomainData(response.data?.appsettings);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Subscriptions | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="subsSec my-5 pt-5">
          <div className="headMain text-center">
            <h2 className="mb-0 mt-3">Subscription Plans</h2>
            <p className="mt-0 theme-text-2">
              Choose Your Monthly Subscription Plan
            </p>
          </div>

          <Row className="cards py-5 justify-content-center">
            <Col
              xl={3}
              lg={4}
              md={6}
              sm={8}
              xs={11}
              className="card py-5 shadow"
            >
              <ul className="list-unstyled text-center">
                <li className="pack mb-2">Basic</li>
                <li id="basic" className="price bottom-bar">
                  {domainData?.basicSubscriptionsCharges}
                </li>
                {Object.keys(categoryData?.basicPackage)?.map((index) => (
                  <li className="bottom-bar py-2" key={index}>
                    {categoryData?.basicPackage[index]}
                  </li>
                ))}
                <Link
                  to={`/pay?package=basic&currency=${domainData?.basicSubscriptionsCharges?.at(
                    0
                  )}&amount=${domainData?.basicSubscriptionsCharges?.substr(
                    1
                  )}&email=${fromEmail}`}
                  className="btn-sub btn"
                >
                  Choose Plan
                </Link>
              </ul>
            </Col>
            <Col
              xl={3}
              lg={4}
              md={6}
              sm={8}
              xs={11}
              className="card py-5 active"
            >
              <ul className="list-unstyled text-center">
                <li className="pack mb-2">Professional</li>
                <li id="professional" className="price bottom-bar">
                  {domainData?.proSubscriptionsCharges}
                </li>
                {Object.keys(categoryData?.proPackage)?.map((index) => (
                  <li className="bottom-bar py-2" key={index}>
                    {categoryData?.proPackage[index]}
                  </li>
                ))}
                <Link
                  to={`/pay?package=basic&currency=${domainData?.proSubscriptionsCharges?.at(
                    0
                  )}&amount=${domainData?.proSubscriptionsCharges?.substr(
                    1
                  )}&email=${fromEmail}`}
                  className="btn-sub btn active-btn"
                >
                  Choose Plan
                </Link>
              </ul>
            </Col>
            <Col
              xl={3}
              lg={4}
              md={6}
              sm={8}
              xs={11}
              className="card py-5 shadow"
            >
              <ul className="list-unstyled text-center">
                <li className="pack mb-2">Master</li>
                <li id="master" className="price bottom-bar">
                  {domainData?.masterSubscriptionsCharges}
                </li>
                {Object.keys(categoryData?.masterPackage)?.map((index) => (
                  <li className="bottom-bar py-2" key={index}>
                    {categoryData?.masterPackage[index]}
                  </li>
                ))}
                <Link
                  to={`/pay?package=basic&currency=${domainData?.masterSubscriptionsCharges?.at(
                    0
                  )}&amount=${domainData?.masterSubscriptionsCharges?.substr(
                    1
                  )}&email=${fromEmail}`}
                  className="btn-sub btn"
                >
                  Choose Plan
                </Link>
              </ul>
            </Col>
            <Col md={12}>
              <Link
                to={`/pay?package=Custom&currency=$&amount=0&email=${fromEmail}`}
                className="theme-text-2"
              >
                <CaretUpSquare /> Create a Custom Plan
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Subscriptions;
