import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import CustomLoader from "../loader/loader";
import { toast } from "react-toastify";

const TelegramListsAddForm = (props) => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const listId = props?.params?.id;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(listId ? true : false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "telegramList",
    list_name: "",
    list_description: "",
  });

  const populateForm = () => {
    axios
      .get(`${Actions.BASE_URL}telegramLists/${listId}`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const response = res.data;
        setFormData({
          ...formData,
          list_name: response?.list_name,
          list_description: response?.list_description,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      });
  };

  useEffect(() => {
    if (listId) {
      populateForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId]);

  const handleDirectFieldChange = (fieldName, fieldValue) => {
    const updatedData = { ...formData };
    updatedData[fieldName] = fieldValue;
    setFormData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (listId) {
      axios
        .put(`${Actions.BASE_URL}telegramLists/${listId}`, formData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/my-contacts/telegram-lists`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}telegramLists`, formData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/my-contacts/telegram-lists`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
        });
    }
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-5 py-5">
          <div className="demosAddFormHeader text-center mt-3">
            <h2 className="addFormHeading theme-text-2">
              Telegram List Details
            </h2>
          </div>
          <fieldset className="wizard-fieldset show demosAddForm py-4">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className="mx-0">
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="List Name"
                      className="form-control italicInput"
                      name="list_name"
                      id="list_name"
                      value={formData?.list_name}
                      onChange={(e) =>
                        handleDirectFieldChange("list_name", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="list_name">
                      List Name
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="List desc"
                      className="form-control min-h-auto"
                      name="list_description"
                      id="list_description"
                      value={formData?.list_description}
                      onChange={(e) =>
                        handleDirectFieldChange(
                          "list_description",
                          e.target.value
                        )
                      }
                    />
                    <Form.Label
                      className="form-label"
                      htmlFor="list_description"
                    >
                      List Description
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <Link
                    to="/my-contacts/telegram-lists"
                    className="previous-button add-button btn demosEditBtn"
                  >
                    Cancel
                  </Link>
                  <Button
                    type="submit"
                    className="next add-button btn demosEditBtn"
                    disabled={!formData.list_name}
                  >
                    {listId ? "Update List" : "Create List"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        </Container>
      )}
    </>
  );
};

export default withRouter(TelegramListsAddForm);
