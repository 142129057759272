import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { Send, Trash } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import DynamicRelationFieldEdit from "../../components/dynamicRelationFieldSet/dynamicRelationFieldEdit";
import ThemeInpGroup from "../../components/homeSegments/ThemeInpGroup";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import AddTag from "../../components/tagInput/tagInput";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";

const AddEditBlog = (props) => {
  const itemId = props.params.id;

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const entities = "blogs";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);

  const userData = useUserData({ setLoading });

  const [formData, setFormData] = useState({
    userId: myUserId,
    createdBy: myUserId,
    type: "",
    filters: {},
    tags: [],
    comments: "",
    host: "",
    category: "",
    subcategory: "",
    subcategorylevel1: "",
    subcategorylevel2: "",
    subcategorylevel3: "",
    entity_type: "blog",
    star_rating_type: "",
    entityRelation: [],
    title: "",
    twoLinerIntro: "",
    blogContent: "",
    author: "",
    authorBio: "",
    blogComments: {},
    phone: "",
    whatsapp: "",
    website: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_content: "",
    hero_image_extension: "",
    online_join_link: "",
    meeting_id: "",
    social_links: [],
  });
  const [addedTags, setAddedTags] = useState([]);
  const [step, setStep] = useState(1);
  const [socialData, setSocialData] = useState([{ type: "", value: "" }]);
  const [needDetailedImage, setNeedDetailedImage] = useState(false);

  useEffect(() => {
    const username = `${
      userData?.first_name
        ? userData?.first_name !== undefined && userData?.first_name
        : ""
    } ${
      userData?.last_name
        ? userData?.last_name !== undefined && userData?.last_name
        : ""
    }`;

    setFormData({
      ...formData,
      phone: userData?.phone,
      whatsapp: userData?.whatsapp,
      author: username === " " ? "" : username,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const populateForm = (id) => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}${entities}/${id}`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData(res.data);
        setAddedTags(res.data.tags);
        setSocialData(res.data.social_links || [{ type: "", value: "" }]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.response);
      });
  };

  useEffect(() => {
    populateForm(props?.params?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.params?.id]);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  // * ==========================================================================
  const handleAIGenerate = (e, input) => {
    e.preventDefault();
    setLoadingAI(true);
    const data = {
      searchText: input,
    };
    setFormData({
      ...formData,
      hero_image_content: "",
      listing_image_content: "",
      hero_image_url: "https://placehold.co/280x180?text=loading+Image",
      listing_image_url: "https://placehold.co/280x180?text=loading+Image",
    });
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create/blog`, data, {
          withCredentials: true,
        })

        .then((generated_content) => {
          axios
            .post(`https://api.ameerpetit.com/ai/create/image`, data, {
              withCredentials: true,
            })

            .then((generated_image) => {
              setFormData({
                ...formData,
                title: input,
                blogContent: generated_content.data,
                listing_image_content: generated_image.data,
                listing_image_extension: ".png",
                listing_image_url: "",
                hero_image_content: generated_image.data,
                hero_image_extension: ".png",
                hero_image_url: "",
              });
              // Update the textarea height after setting the content
              updateTextareaHeight();
            })
            .catch((error) => {
              console.error("Error generating code:", error);
              setFormData({
                ...formData,
                title: input,
                blogContent: generated_content.data,
                hero_image_content: "",
                listing_image_extension: "",
                listing_image_content: "",
                hero_image_extension: "",
                hero_image_url:
                  "https://placehold.co/280x180?text=loading+Image",
                listing_image_url:
                  "https://placehold.co/280x180?text=loading+Image",
              });
              toast.error("Error generating code. Please try again.", {
                autoClose: 3000,
                hideProgressBar: true,
                draggable: true,
              });
            })
            .finally(() => {
              setLoadingAI(false);
            });
        })
        .catch((error) => {
          console.error("Error generating code:", error);
          setFormData({
            ...formData,
            title: input,
            blogContent: "",
            hero_image_content: "",
            listing_image_extension: "",
            hero_image_url: "",
            listing_image_content: "",
            hero_image_extension: "",
            listing_image_url: "",
          });
          setLoadingAI(false);
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };
  // img generate

  const handleImageGenerate = (e, input) => {
    e.preventDefault();
    const data = {
      searchText: input,
    };
    setFormData({
      ...formData,
      hero_image_content: "",
      listing_image_content: "",
      hero_image_url: "https://placehold.co/280x180?text=loading+Image",
      listing_image_url: "https://placehold.co/280x180?text=loading+Image",
    });
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create/image`, data, {
          withCredentials: true,
        })

        .then((generated_image) => {
          setFormData({
            ...formData,
            listing_image_content: generated_image.data,
            listing_image_extension: ".png",
            listing_image_url: "",
            hero_image_content: generated_image.data,
            hero_image_extension: ".png",
            hero_image_url: "",
          });
          // Update the textarea height after setting the content
          updateTextareaHeight();
        })
        .catch((error) => {
          console.error("Error generating code:", error);
          setFormData({
            ...formData,
            hero_image_content: "",
            listing_image_extension: "",
            hero_image_url: "",
            listing_image_content: "",
            hero_image_extension: "",
            listing_image_url: "",
          });
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };

  // Function to update the textarea height based on content
  const updateTextareaHeight = () => {
    const textarea = document.getElementById("blogContent");
    if (textarea) {
      // Reset the height to auto to ensure it shrinks if content is removed
      textarea.style.height = "auto";
      // Set the height to the scroll height of the content
      textarea.style.height = `${textarea.scrollHeight + 40}px`;
    }
  };
  // * ==========================================================================

  const handleSubmit = () => {
    setLoadingReq(true);

    if (itemId) {
      axios
        .put(`${Actions.BASE_URL}${entities}/${itemId}`, formData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/${entities}`);
          setLoading(false);
        })
        .catch((error) => {
          setLoadingReq(false);
        });
    } else {
      const data = {
        ...formData,
        tags: addedTags,
        social_links: socialData,
      };

      axios
        .post(`${Actions.BASE_URL}${entities}`, data, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/share/blog/${res?.data?.id}`);
          setLoadingReq(false);
        })
        .catch((error) => {
          setLoadingReq(false);
        });
    }
  };

  const titleInputRef = useRef(null);

  useEffect(() => {
    titleInputRef?.current?.focus();
  }, []);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const demoAlias = SubDomainRouter("demoAlias");
  const courseAlias = SubDomainRouter("courseAlias");

  return (
    <div className="demosAddFormSection demosEditSection">
      {loadingReq || loadingAI ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-3">
          {/* ! */}

          <div className="demosAddFormHeader text-center">
            <h2 className="addFormHeading text-capitalize">
              {itemId ? "Edit Your Blog" : "Create a Blog"}
            </h2>
            <div className="demosEditHeader text-center mb-3 mb-lg-5">
              <h5>Add Details Below:</h5>
              <ul className="demosEditList">
                <li>
                  <button
                    className={
                      step === 1 ? "active stepstriggers" : "stepstriggers"
                    }
                    onClick={() => setStep(1)}
                  >
                    <span>1</span>
                  </button>
                </li>
                <li>
                  <button
                    className={
                      step === 2 ? "active stepstriggers" : "stepstriggers"
                    }
                    disabled={
                      !formData.blogContent || !formData.title || !formData.type
                    }
                    onClick={() => setStep(2)}
                  >
                    <span>2</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* ! */}
          {step === 1 && (
            <div className="chatAI h-fit p-0 demosAddForm min-h-fit w-100 mb-4">
              <p className="opacity-75 text-center mb-1">
                Enter command to generate content
              </p>
              <ThemeInpGroup
                handleSubmit={handleAIGenerate}
                icon={<Send />}
                loading={loadingAI}
                inputPlaceholder={
                  "To generate Content: Type the title or keywords."
                }
              />

              <FormGroup className="d-flex align-items-start pt-3">
                <Form.Check
                  className="ps-0 custom-checkbox"
                  type="checkbox"
                  name="needDetailedImage"
                  id={`needDetailedImage`}
                  label={" "}
                  checked={needDetailedImage}
                  onChange={(e) => setNeedDetailedImage(!needDetailedImage)}
                />
                <Form.Label htmlFor="needDetailedImage" className="mt-3">
                  Generate Detailed Image With Custom Prompts
                </Form.Label>
              </FormGroup>

              {needDetailedImage && (
                <>
                  <p className="opacity-75 text-center my-1">
                    Enter Detailed Prompt below to generate Image
                  </p>
                  <ThemeInpGroup
                    handleSubmit={handleImageGenerate}
                    icon={<Send />}
                    inputPlaceholder={
                      "To generate Detailed Image: Type description or keywords."
                    }
                  />
                </>
              )}
            </div>
          )}

          <Form
            id="add-demo-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="form-section addFormSection event-detail">
              {step === 1 && (
                <fieldset className="wizard-fieldset show demosAddForm mt-2 position-relative overflow-hidden">
                  <Row className="mx-0">
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="title"
                          required
                          className="italicInput"
                          value={formData?.title}
                          ref={titleInputRef}
                          placeholder="Enter Event Title"
                          name="title"
                          onChange={(e) =>
                            handleFieldChange("title", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="title">
                          Post Title
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="2"
                          name="twoLinerIntro"
                          value={formData?.twoLinerIntro}
                          id="twoLinerIntro"
                          placeholder=" Enter your event twoLinerIntro"
                          onChange={(e) =>
                            handleFieldChange("twoLinerIntro", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          Two Liner Into For Post
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          style={{ height: "auto" }}
                          rows="4"
                          required
                          name="blogContent"
                          value={formData?.blogContent}
                          id="blogContent"
                          placeholder=" Enter your event blogContent"
                          onChange={(e) =>
                            handleFieldChange("blogContent", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="blogContent"
                        >
                          Post Content
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6} className="position-relative">
                      {formData?.hero_image_url ||
                      formData.hero_image_content ? (
                        <Button
                          className="deleteBtn py-1 px-2 btn-danger text-white bg-danger h-auto w-auto position-absolute top-0 end-0 mt-2 me-3 z-3"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              hero_image_url: "",
                              hero_image_content: "",
                            });
                          }}
                        >
                          <Trash />
                        </Button>
                      ) : null}

                      <BannerUploaderEdit
                        key={formData?.hero_image_url}
                        label="Big Banner"
                        accept="image/png, image/jpg, image/jpeg"
                        imageType="hero_image"
                        isBlog={true}
                        placehold="https://placehold.co/720x720"
                        state={formData}
                        setState={setFormData}
                        imageURL={formData?.hero_image_url}
                        imageContent={formData?.hero_image_content}
                        imageExtension={formData?.hero_image_extension}
                      />
                    </Col>

                    <Col md={6} className="position-relative">
                      {formData?.listing_image_url ||
                      formData.listing_image_content ? (
                        <Button
                          className="deleteBtn py-1 px-2 btn-danger text-white bg-danger h-auto w-auto position-absolute top-0 end-0 mt-2 me-3 z-3"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              listing_image_url: "",
                              listing_image_content: "",
                            });
                          }}
                        >
                          <Trash />
                        </Button>
                      ) : null}

                      <BannerUploaderEdit
                        key={formData?.listing_image_url}
                        label="Small Banner"
                        accept="image/png, image/jpg, image/jpeg"
                        imageType="listing_image"
                        placehold="https://placehold.co/280x280"
                        isBlog={true}
                        state={formData}
                        setState={setFormData}
                        imageURL={formData?.listing_image_url}
                        imageContent={formData?.listing_image_content}
                        imageExtension={formData?.listing_image_extension}
                      />
                    </Col>

                    <Col md={12}>
                      <AddTag
                        label={"Tags"}
                        addedTags={addedTags}
                        setAddedTags={setAddedTags}
                      />
                    </Col>

                    <Col md={12}>
                      {" "}
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Select
                        className="form-control text-capitalize"
                        onChange={(e) =>
                          handleFieldChange("type", e.target.value)
                        }
                        value={formData?.type}
                        required
                      >
                        <option value="">
                          What are you writing this blog for?
                        </option>
                        <option value="demo">
                          {demoAlias ? demoAlias : "demo"}
                        </option>
                        <option value="course">
                          {courseAlias ? courseAlias : "course"}
                        </option>
                        <option value="institute">Institute</option>
                        <option value="general">General</option>
                      </Form.Select>
                    </Col>

                    <Col md={12} className="mt-3">
                      {itemId && (
                        <Link
                          to={"/blogs"}
                          className="btn prev demosEditBtn d-inline-block text-decoration-none"
                        >
                          Discard
                        </Link>
                      )}
                      <Button
                        type="button"
                        className="next demosEditBtn "
                        onClick={handleNext}
                        disabled={
                          !formData.blogContent ||
                          !formData.title ||
                          !formData.type
                        }
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
              )}
              {step === 2 && (
                <fieldset className="wizard-fieldset show demosAddForm">
                  <Row className="mx-0">
                    <Col sm={12} className="form-section-header mt-4 mb-3">
                      <h4 className="theme-text-2">Author's Details</h4>
                    </Col>

                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="author"
                          required
                          value={formData?.author}
                          placeholder="Enter Event Title"
                          name="author"
                          onChange={(e) =>
                            handleFieldChange("author", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="author">
                          Author's Name
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="4"
                          name="authorBio"
                          value={formData?.authorBio}
                          id="authorBio"
                          placeholder=" Enter your event authorBio"
                          onChange={(e) =>
                            handleFieldChange("authorBio", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          Author's BIO
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          name="phone"
                          value={formData?.phone}
                          id="phone"
                          placeholder=" Enter your event phone"
                          onChange={(e) =>
                            handleFieldChange("phone", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          Phone No.
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-4">
                        <Form.Control
                          name="whatsapp"
                          value={formData?.whatsapp}
                          id="whatsapp"
                          placeholder=" Enter your event whatsapp"
                          onChange={(e) =>
                            handleFieldChange("whatsapp", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          WhatsApp
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <hr />
                    <Col
                      md={12}
                      className="d-flex justify-content-between gap-3 mt-3"
                    >
                      <h6 className="theme-text-2 flex-grow-1">Social Links</h6>
                      <Button
                        className="clickable add-button mb-3 ms-auto d-flex"
                        onClick={() => {
                          const newField = { type: "", value: "" };
                          setSocialData([...socialData, newField]);
                        }}
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                    </Col>
                    <Col md={12}>
                      {socialData?.map((field, index) => (
                        <DynamicRelationFieldEdit
                          key={index}
                          fields={socialData}
                          field={field}
                          index={index}
                          onFieldChange={(newFields) => {
                            setSocialData(newFields);
                          }}
                          fieldLabels={{
                            valueInput: "Enter Your Social (Profile) Link",
                          }}
                          selectOptions={[
                            { value: "facebook", label: "Facebook" },
                            { value: "instagram", label: "Instagram" },
                            { value: "twitter", label: "Twitter" },
                            { value: "linkedIn", label: "LinkedIn" },
                            { value: "website", label: "Website" },
                            { value: "others", label: "Others" },
                          ]}
                        />
                      ))}
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col md={12} className="mt-3">
                      <Button
                        type="button"
                        className="previous-button demosEditBtn"
                        onClick={handlePrevious}
                      >
                        Previous
                      </Button>
                      <Button type="submit" className="next demosEditBtn">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
              )}
            </div>
          </Form>
        </Container>
      )}
    </div>
  );
};
export default withRouter(AddEditBlog);
