import React from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { HeartPulse } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import StarRating from "../../components/starRating/starRating";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TextFormatter from "../../components/textFormat/textFormat";
import TimePeriod from "../../components/timestampConverters/timePeriodConverter";
import courseData from "./course-config.json";
import { convertUTCToLocal } from "../../helper/timezoneConversions";

const ItemInfo = ({
  entityData,
  displayedCards,
  reviewData,
  targetRef,
  handleShowMore,
}) => {
  const entityName = courseData.entity;
  const courseAlias = SubDomainRouter("courseAlias");
  const instructorAlias = SubDomainRouter("instructorAlias");

  const hasBatch = entityData?.batches?.map((batch) => {
    return batch?.courseName;
  });
  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {courseAlias ? courseAlias : "course"} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Description</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.description && entityData?.description != "" ? (
                <TextFormatter text={entityData?.description} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Duration</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.duration && entityData?.duration != "" ? (
                <ConvertMinutesToTime minutes={entityData?.duration} />
              ) : (
                ""
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Category</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.category && entityData?.category != ""
                ? entityData?.category
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Type</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.subcategorylevel1 &&
                entityData?.subcategorylevel1 != ""
                ? entityData?.subcategorylevel1
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Mode</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.subcategorylevel2 != ""
                ? entityData?.subcategorylevel2
                : "Yet To Decide"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        {entityData?.subcategorylevel1?.toLowerCase() === "paid" && (
          <Link
            to={`/request-sponsor/${entityData?.type}/${entityData?.id}`}
            className="btn-link theme-text-2 text-decoration-none d-flex align-items-center mb-3"
          >
            <HeartPulse />
            &nbsp;
            <span className="text-decoration-underline">
              Request your employer or some one to sponsor / pay for this course
            </span>
          </Link>
        )}

        <Row>
          <Col md={12}>
            <div className="card-text text-muted mb-0 d-flex align-items-center gap-2">
              {entityData?.tags != "" &&
                entityData?.tags?.map((tag, index) => (
                  <h3 className="tag-chip" key={index}>
                    #{tag}
                  </h3>
                ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {instructorAlias} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              {instructorAlias} Name
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.instructor_first_name ||
                entityData?.instructor_last_name
                ? `${entityData?.instructor_first_name} ${entityData?.instructor_last_name}`.trim()
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              {instructorAlias} BIO
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.instructor_bio ? (
                <TextFormatter text={entityData?.instructor_bio} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Organizer Details:</h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Organizer Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_first_name ||
                entityData?.organizer_last_name
                ? `${entityData?.organizer_first_name} ${entityData?.organizer_last_name}`.trim()
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Company Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_company_name &&
                entityData?.organizer_company_name != ""
                ? entityData?.organizer_company_name
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Website</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_website &&
                entityData?.organizer_website.trim() !== "" ? (
                <a
                  href={entityData?.organizer_website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entityData?.organizer_website}
                </a>
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>

      {(entityData?.sponsor_first_name || entityData?.sponsor_company_name) && (
        <Card.Body>
          <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Sponsor Details:</h5>

          {entityData?.sponsor_first_name && entityData.sponsor_first_name !== "" && (
            <>
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Sponsor Name</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {entityData.sponsor_first_name}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
            </>
          )}

          {entityData?.sponsor_company_name && entityData.sponsor_company_name !== "" && (
            <>
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Sponsor Company</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {entityData.sponsor_company_name}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
            </>
          )}

          {entityData?.sponsor_logo_url && entityData.sponsor_logo_url !== "" && (
            <>
              <img src={entityData?.sponsor_logo_url} width={120} alt="Invalid Logo" />
              <hr />
            </>
          )}

          <Row>
            <Col md={3}>
              <Card.Text className="mb-0 fw-bold">Company Name</Card.Text>
            </Col>
            <Col md={9}>
              <Card.Text className="text-muted mb-0">
                {entityData.sponsor_company_name || "Not Available"}
              </Card.Text>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col md={3}>
              <Card.Text className="mb-0 fw-bold">Website</Card.Text>
            </Col>
            <Col md={9}>
              <Card.Text className="text-muted mb-0">
                {entityData.sponsor_website || "Not Available"}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      )}

      {entityData?.batches?.length > 0 && hasBatch?.toString() ? (
        <Card.Body>
          <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Batches Details:</h5>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th className="bg-theme bg-opacity-50 text-light">
                  Course Name
                </th>
                <th className="bg-theme bg-opacity-50 text-light">
                  Starts At
                </th>
                <th className="bg-theme bg-opacity-50 text-light">Duration</th>
                <th className="bg-theme bg-opacity-50 text-light">Trainer</th>
                <th className="bg-theme bg-opacity-50 text-light">
                  Training Options
                </th>
                <th className="bg-theme bg-opacity-50 text-light">Action</th>
              </tr>
            </thead>
            <tbody>
              {entityData?.batches?.map((batch, index) => (
                <tr key={index}>
                  <td>{batch?.courseName}</td>
                  <td>
                    {convertUTCToLocal(batch?.date, batch?.startTime)?.localTime}, &nbsp;
                    {convertUTCToLocal(batch?.date, batch?.startTime)?.localDate}
                  </td>
                  <td>
                    <ConvertMinutesToTime minutes={batch?.duration} />
                  </td>
                  <td>{batch?.trainer}</td>
                  <td>{batch?.teachingMode}</td>
                  <td>
                    <a
                      href={batch?.register_link}
                      className="btn-link theme-text-2 w-fit px-3 d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Register
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      ) : null}

      <Card.Body className="reviews-cont pt-0" id="reviews" ref={targetRef}>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">
            Reviews And Ratings
          </h5>
          <Link
            to={`/reviews/${entityName}/${entityData?.id}/add`}
            className="actionBtn btn add-button cursor-pointer"
          >
            Add Review
          </Link>
        </div>
        {reviewData != "" ? (
          <div className="d-flex gap-3 flex-column mt-2">
            {reviewData?.slice(0, displayedCards)?.map((review, index) => (
              <Card className="detailReviewCard p-0" key={index}>
                <Card.Body>
                  <div className="review-top d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">
                      {review.reviewer_first_name} {review.reviewer_last_name}
                    </h4>
                    <Card.Text className="mb-0 theme-text-2">
                      <small>
                        <TimePeriod createdDate={review.createdDate} />
                      </small>
                    </Card.Text>
                  </div>
                  <StarRating rating={review.star_rating} />
                  <div className="review-content mt-3">
                    <p className="mb-0 h6">{review.review_title}</p>
                    <Card.Text>{review.review_comments}</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <p>No Reviews Yet!</p>
        )}
      </Card.Body>
      {reviewData != "" && (
        <>
          {displayedCards < reviewData?.length ? (
            <div className="text-center mt-2 mb-3">
              <Button
                onClick={handleShowMore}
                className="btn actionBtnMore action cursor-pointer w-auto "
              >
                Show More
              </Button>
            </div>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default ItemInfo;
