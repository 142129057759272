import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { PencilSquare, PlusCircle, Share, Trash } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import SocialShare from "../../components/share/socialShare";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import USER from "../../img/user.png";

const Partners = () => {
  const currentDomain = window.location.hostname;

  const entityType = "partner";

  const partnersAlias = SubDomainRouter("partnersAlias");
  const myToken = localStorage.getItem("token");
  const role = useUserData().role?.toLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState(null);
  const [displayedCards, setDisplayedCards] = useState(8);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await API("get", "items/" + entityType);
      setEntityData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}items/${entityType}/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                fetchData();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) =>
      prevDisplayedCards + 4 <= entityData?.length
        ? prevDisplayedCards + 4
        : entityData?.length
    );
  };

  const siteName = SubDomainRouter("title");
  let uppercaseTitle = partnersAlias.toLocaleUpperCase();
  // rendering cards data
  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-5 py-5">
          <Row>
            <Col className="mb-3" lg={10} sm={8}>
              <h3
                id="Partners"
                className="pageSubHeading mb-0 text-sm-start text-center text-capitalize"
              >
                {partnersAlias ? partnersAlias : `${entityType}s`}
              </h3>
            </Col>
            {["admin", "siteadmin", "superadmin", "trainer", "training-coordinator"].includes(role) ? (
              <Col
                lg={2}
                sm={4}
                className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
              >
                <Link
                  to={`/${entityType}/items/add`}
                  type="button"
                  className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
                >
                  <PlusCircle /> &nbsp; Add
                </Link>
              </Col>
            ) : null}
          </Row>

          <Row className="mx-0">
            {entityData ? (
              entityData?.map((item) => (
                <Col xl={4} lg={6} sm={10} key={item.id} className="mt-2 my-3">
                  <Card className="profileCard">
                    <div className="contUser d-flex align-items-end gap-2">
                      <Link
                        to={`/${entityType}/items/details/${item.id}`}
                        className="userImage"
                      >
                        <Card.Img
                          src={item?.data?.listing_image_url || USER}
                          alt="Load Error"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = USER;
                          }}
                          className="img-fluid"
                        />
                      </Link>
                      <div className="d-flex align-items-center justify-content-between gap-1">
                        <div className="det-cont flex-grow-1">
                          <Link
                            to={`/${entityType}/items/details/${item.id}`}
                            className="username mt-2"
                          >
                            {item.data.first_name}&nbsp;{item.data.last_name}
                          </Link>
                          {item.data.job_title && (
                            <p className="info specialization mb-1">
                              {item.data.job_title}
                            </p>
                          )}
                          <p className="info specialization mb-1 fw-normal">
                            <span>
                              {item.data.bio && item.data.bio.length > 27
                                ? `${item.data.bio.slice(0, 27)}...`
                                : item.data.bio}
                            </span>
                          </p>
                        </div>
                        <Link
                          to={`/share/items_${entityType}/${item.id}`}
                          className="actionBtn btn action entity-share-btn d-flex align-items-center cursor-pointer p-0 rounded-circle"
                          title="share"
                        >
                          <Share />
                        </Link>
                      </div>
                    </div>
                    <Card.Body className="text-start flex-column d-flex justify-content-between rounded-3">
                      <div className="content">
                        <p className="info my-1 text-overflow-desc-sm">
                          <span>
                            {item.bio && item.bio.length > 27
                              ? `${item.bio}`
                              : item.bio}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mb-3 mt-1 align-items-center">
                        <SocialShare
                          url={`https://${currentDomain}/${entityType}/items/details/${item?.id}/`}
                          iconsSize={30}
                          title={
                            item?.data?.first_name + " " + item?.data?.last_name
                          }
                          image={item?.data?.listing_image_url}
                          description={item?.data?.description}
                        />
                      </div>
                      <div className="itemEdit d-flex align-items-stretch justify-content-center flex-sm-nowrap gap-2">
                        {role === "admin" ||
                          role === "siteadmin" ||
                          role === "superadmin" ||
                          item?.userId?.toLowerCase() === myUserId ? (
                          <Link
                            to={`/${entityType}/items/edit/${item.id}`}
                            className="actionBtn btn d-flex align-items-center justify-content-center text-center w-fit"
                          >
                            <span className="d-sm-block d-none">Edit</span>
                            <PencilSquare className="d-sm-none d-block" />
                          </Link>
                        ) : null}
                        <Link
                          to={`/${entityType}/items/details/${item.id}`}
                          className="actionBtn btn action cursor-pointer flex-grow-1"
                        >
                          View Profile
                        </Link>
                        {role === "admin" ||
                          role === "siteadmin" ||
                          role === "superadmin" ||
                          item?.userId?.toLowerCase() === myUserId ? (
                          <Link
                            to="#"
                            className="actionDltBtn btn d-flex align-items-center justify-content-center text-center w-fit"
                            onClick={() => deleteItem(item.id)}
                          >
                            <span className="d-sm-block d-none">Delete</span>
                            <Trash className="d-sm-none d-block" />
                          </Link>
                        ) : null}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p>No {partnersAlias} found..</p>
            )}
          </Row>

          {!loading && displayedCards < entityData?.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={handleShowMore}
                className="btn actionBtnMore action cursor-pointer w-auto add-button"
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      )}
    </>
  );
};

export default Partners;
