import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import Select from "react-select";
import ParticipantsFieldset from "../../components/dynamicFieldset/ParticipantsFieldset";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../components/generalMsgModal/Alerts";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../constants/baseUrl";
import { mainTimeZones } from "../../constants/extras";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const GroupAppointment = () => {
  const url = new URL(window.location.href);
  // Extract the value of the 'project_id' parameter
  const first_name = url?.searchParams?.get("first_name");
  const last_name = url?.searchParams?.get("last_name");
  const email = url?.searchParams?.get("email");

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [support, setSupport] = useState({
    first_name: "",
    last_name: "",
    email: "",
    participant_type: "support",
  });

  useEffect(() => {
    setSupport({
      ...support,
      first_name: first_name ?? "Support",
      last_name: last_name ?? window.location.host,
      email: email ?? `support@${window.location.host}.com`,
    });
  }, [email, first_name, last_name]);

  const [participants, setParticipants] = useState([
    {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      email: "",
      whatsApp: "",
      phone: "",
      linkedIn: "",
      job_title: "",
      bio: "",
      participant_type: "participant",
      type: "general",
    },
  ]);
  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    duration: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    whatsapp: "",
    meeting_title: "",
    start_date: "",
    start_time: "",
  });
  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [eventTime, setEventTime] = useState("");
  const [eventDate, setEventDate] = useState("");

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const modalFields = ["start_date", "start_time", "duration", "meeting_title"];

  const areFieldsFilled = (fieldNames, formData) => {
    return fieldNames.every((fieldName) => formData[fieldName]);
  };

  const isModalFieldsFilled = areFieldsFilled(modalFields, formData);

  //  convert to UTC
  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
  }, []);

  const timeZones = mainTimeZones.map((tz) => ({ label: tz, value: tz }));

  const handleDateChange = (newValue) => {
    setEventDate(newValue);
    const formattedDate = moment(newValue).format("YYYY/MM/DD");
    setFormData((prevData) => ({
      ...prevData,
      start_date: formattedDate,
    }));
  };

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    convertToUTC(eventTime, selectedOption?.value);
  };

  const handleEventTimeChange = (newTime) => {
    setEventTime(newTime);

    if (selectedTimeZone) {
      convertToUTC(newTime, selectedTimeZone.value);
    }
  };

  const convertToUTC = (givenTime, timeZone) => {
    const utcTime = moment.tz(givenTime, timeZone).utc().format("HH:mm");
    setFormData((prevData) => ({
      ...prevData,
      start_time: utcTime,
    }));
  };

  const addField = (setField, field) => {
    setField([...field, {}]);
  };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isModalFieldsFilled) {
      setHasValue(false);
      setIsValid(true);
      setReqLoading(true);

      if (
        participants.length === 0 ||
        !participants[0]?.email ||
        participants[0]?.email === ""
      ) {
        toast.error("Please add participant's details to continue", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
          position: "bottom-center",
        });
        setReqLoading(false);
        return;
      }

      const reqData = {
        participants: [support, ...participants],
        meeting_title: formData.meeting_title,
        meeting_description: formData.meeting_details,
        meeting_id: null,
        start_date: formData.start_date,
        start_time: formData.start_time,
        online_join_link: null,
        duration_in_min: formData.duration,
        userId: myUserId,
        type: "appointment",
        comments: formData?.comments,
      };

      axios
        .post(`${Actions.BASE_URL}appointments`, reqData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          SuccessAlert("Appointment Requested");
        })
        .catch((error) => {
          console.error("API request failed:", error);
          ErrorAlert("Something went wrong");
        })
        .finally(() => {
          setReqLoading(false);
        });
    } else {
      setHasValue(true);
      setIsValid(true);
      toast.error("Please add required details to continue", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
        position: "bottom-center",
      });
    }
  };

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>Contact | {siteName ? siteName : window.location.host}</title>
      </Helmet>

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <div className="ContactUsPage mt-4">
        <Container fluid="lg">
          <Form
            className="ContactUsForm mt-sm-5 mt-4 px-md-2 py-md-4 py-3"
            onSubmit={handleSubmit}
          >
            <h1 className="text-center mb-5">Request An Appointment</h1>

            <Row className="mx-0 align-items-end">
              <Col md={12}>
                <h5 className="pt-1">Appointment Details</h5>
                <hr className="mb-3" />
              </Col>
              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mx-auto mb-3 appointment-modal">
                  <ReactDatePicker
                    placeholderText="Appointment Date"
                    className={`form-control form-select w-100 ${isValid && hasValue && !formData?.start_date
                      ? "border-danger"
                      : ""
                      }`}
                    selected={eventDate}
                    onChange={handleDateChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="position-relative">
                <Form.Group className="form-floating my-3 mx-auto">
                  <Select
                    className="timezone form-control z-3"
                    required
                    id="timezone"
                    options={timeZones}
                    value={selectedTimeZone}
                    onChange={handleTimeZoneChange}
                    placeholder="Select your time zone"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mx-auto mb-3">
                  <ReactDatePicker
                    placeholderText="Appointment Time:"
                    className={`form-control form-select w-100 ${isValid && hasValue && !formData?.start_time
                      ? "border-danger"
                      : ""
                      }`}
                    selected={eventTime}
                    onChange={handleEventTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    dateFormat="h:mm aa"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating position-relative mb-3">
                  <Form.Select
                    aria-label="event type"
                    required
                    className={`form-control ${isValid && hasValue && !formData?.duration
                      ? "border-danger"
                      : ""
                      }`}
                    onChange={(e) =>
                      handleFieldChange("duration", e.target.value)
                    }
                    id="duration"
                    name="duration"
                  >
                    <option value="">Duration</option>
                    <option value="10">10 Minutes</option>
                    <option value="20">20 Minutes</option>
                    <option value="30">30 Minutes</option>
                    <option value="30">40 Minutes</option>
                    <option value="30">50 Minutes</option>
                    <option value="60">1 Hour</option>
                    <option value="120">2 Hours</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    className={
                      isValid && hasValue && !formData?.meeting_title
                        ? "border-danger"
                        : ""
                    }
                    id="meeting_title"
                    rows={1}
                    required
                    value={formData?.meeting_title}
                    placeholder="meeting_title"
                    name="meeting_title"
                    onChange={(e) =>
                      handleFieldChange("meeting_title", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="meeting_title">
                    Meeting Title
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    id="meeting_details"
                    rows={4}
                    value={formData?.meeting_details}
                    placeholder="meeting_details"
                    name="meeting_details"
                    onChange={(e) =>
                      handleFieldChange("meeting_details", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="meeting_details">
                    Please provide detailed context of your appointment
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <h5 className="pt-3">Participants' Details</h5>
                <hr className="mb-3" />
              </Col>

              <Col md={12}>
                {participants?.map((field, index) => (
                  <React.Fragment key={index}>
                    <ParticipantsFieldset
                      key={index}
                      field={field}
                      onFieldChange={(newField) => {
                        const updatedFields = [...participants];
                        updatedFields[index] = newField;
                        setParticipants(updatedFields);
                      }}
                      indexField={index}
                      onRemove={() =>
                        removeItemById(participants, setParticipants, field.id)
                      }
                      inputIdPrefix={index}
                    />
                  </React.Fragment>
                ))}
                <Button
                  className="clickable add-button mb-3 ms-auto d-flex"
                  onClick={() => addField(setParticipants, participants)}
                >
                  Add More Participants
                </Button>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    id="comments"
                    rows={4}
                    value={formData?.comments}
                    placeholder="comments"
                    name="comments"
                    onChange={(e) =>
                      handleFieldChange("comments", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="comments">
                    Your Comments
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Button
                  className="btn add-button ms-auto d-block px-4"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Request Appointment
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default GroupAppointment;
