import React, { useState } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import {
  EnvelopeCheckFill,
  Facebook,
  Instagram,
  Linkedin,
  PhoneFill,
  PinMapFill,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Subscriber from "../homeSegments/Subscriber";
import PostYourNeed from "../postYourNeed/postYourNeed";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import "./Footer.css";

function Footer() {

  const [modalShowQuote, setModalShowQuote] = useState(false);

  const currentDomain = window.location.hostname;

  const homePageRoute = SubDomainRouter("homePageRoute");
  const siteName = SubDomainRouter("title");
  const userReqBtnText = SubDomainRouter("userReqBtnText");
  const logoSrc = SubDomainRouter("logo");

  return (
    <footer className="footerSection">
      <Container fluid="lg">
        {currentDomain === "ameerpetit.com" || currentDomain === "localhost" ? (
          <Row className="mx-0">
            <Col lg={4} md={5} sm={6} className="mb-lg-0 mb-4">
              <Link to={homePageRoute} className="footer-logo text-decoration-none">
                <img src={`${logoSrc}`} alt="" />
              </Link>
              <p>Indian Biggest IT Training Hub & a Trainer Platform</p>
              <ul className="social-list list m-0 list-unstyled d-flex align-items-center gap-sm-3 gap-2">
                <li>
                  <a
                    href="http://facebook.com/ameerpetit"
                    target="_blank"
                    title="facebook"
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank" title="twitter">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="1em"
                      height="1em"
                    >
                      <path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/ameerpetit"
                    target="_blank"
                    title="linkedin"
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ameerpettraining/"
                    target="_blank"
                    title="instagram"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                </li>
              </ul>
            </Col>

            <Col lg={4} md={5} sm={6} className="d-block mb-lg-0 mb-4">
              <div className="loc d-flex mb-ms-4 mb-3 align-items-end">
                <PinMapFill className="theme-text fs-4 mb-1" />
                <p className="mb-0 ms-2">
                  44790 S Grimmer Blvd, <br /> Suite #103, Fremont CA 94538
                </p>
              </div>
              <a
                target="_blank"
                href="tel:5104006332"
                className="phone d-flex text-decoration-none mb-ms-4 mb-3 align-items-end"
                rel="noreferrer"
              >
                <PhoneFill className="theme-text fs-4 mb-1" />
                <p className="mb-0 ms-2">510 400 6332</p>
              </a>
              <a
                target="_blank"
                href="mailto:contact@ameerpetit.com"
                rel="noreferrer"
                className="mail d-flex text-decoration-none mb-4"
              >
                <EnvelopeCheckFill className="theme-text fs-4" />
                <p className="mb-0 ms-2">contact@ameerpetit.com</p>
              </a>
            </Col>

            <Col lg={4} md={6} className="mb-lg-0 mb-4 footerForm">
              <Nav className="mx-0 mb-3 flex-column">
                {localStorage.getItem("isValidUser") === true ||
                  localStorage.getItem("isValidUser") === "true" ? (
                  <Button
                    variant="secondary"
                    className="postTrigger py-2 w-fit my-2"
                    onClick={() => setModalShowQuote(true)}
                  >
                    {userReqBtnText || "Get Quote"}
                  </Button>
                ) : null}
                <Link
                  to={`/how-it-works`}
                  className="nav-link text-dark text-decoration-underline text-capitalize p-0"
                >
                  How It Works?
                </Link>
                <Link
                  to={`/about`}
                  className="nav-link text-dark text-decoration-underline text-capitalize p-0"
                >
                  About
                </Link>
              </Nav>
              <h5 className="mb-3">Subscribe</h5>
              <Subscriber className="mb-2" />
            </Col>
          </Row>
        ) : (
          <Row className="mx-0">
            <Col lg={6} md={8} className="mb-lg-0 mb-4 footerForm">
              <h5 className="mb-3">Subscribe</h5>
              <Subscriber className="mb-2" />
            </Col>
            <Col
              lg="6"
              md="4"
              className="d-flex justify-content-lg-end align-items-end my-2"
            >
              {localStorage.getItem("isValidUser") === true ||
                localStorage.getItem("isValidUser") === "true" ? (
                <Button
                  variant="secondary"
                  className="postTrigger py-2 w-fit"
                  onClick={() => setModalShowQuote(true)}
                >
                  {userReqBtnText || "Get Quote"}
                </Button>
              ) : null}
            </Col>
          </Row>
        )}
      </Container>
      <div className="copyright text-center">
        <p className="m-0 text-white text-center">
          © 2022 - {new Date().getFullYear()}{" "}
          <b>{siteName ? siteName : window.location.host}</b>
        </p>
      </div>

      <PostYourNeed
        show={modalShowQuote}
        onHide={() => setModalShowQuote(false)}
      />
    </footer>
  );
}

export default Footer;
