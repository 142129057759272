import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Actions from "../../constants/baseUrl";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";

const JoinRequestModal = (props) => {

    const whatsappGrpLink = props?.modalData?.path

    const myToken = localStorage.getItem("token");
    const userData = useUserData();
    const tokenDetails = TokenDetails();
    const myUserId = tokenDetails?.Email;

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        city_in_usa: '',
        city_in_ind: '',
        whatsApp_grp_link: whatsappGrpLink,
    });

    const handleFieldChange = (fieldName, fieldValue) => {

        setFormData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue,
        }));
    };

    useEffect(() => {
        setFormData({
            ...formData,
            first_name: userData?.first_name,
            last_name: userData?.last_name,
            email: userData?.email,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);



    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);


        const reqData = {
            userId: myUserId,
            type: 'whatsapp-group_' + window.location.host,
            host: window.location.host,
            header: {
                host: window.location.host,
                userId: myUserId,
            },
            data: {
                userId: myUserId,
                ...formData,
                whatsApp_grp_link: whatsappGrpLink,
                title: props?.modalData?.title,
            },
        };

        axios
            .post(`${Actions.BASE_URL}items`, reqData, {
                headers: {
                    Authorization: `Bearer ${myToken}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setTimeout(() => {
                    window.open(whatsappGrpLink, "_blank");
                }, 1600);
            })
            .catch((error) => {
                console.error("API request failed:", error);
            })
            .finally(() => {
                setLoading(false);
                props?.setshow(false)
            });

    };


    return (
        <>
            {loading ? (
                <>
                    <span className="register-loader"> </span>
                    <span className="spinnerOverlay" />
                </>
            ) : null}

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props?.setshow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="h5">
                        Request to join
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        required
                                        id="first_name"
                                        name="first_name"
                                        value={formData?.first_name}
                                        placeholder="First Name"
                                        onChange={(e) => {
                                            handleFieldChange("first_name", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="first_name">First Name<span className="text-danger">*</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        required
                                        id="last_name"
                                        value={formData?.last_name}
                                        name="last_name"
                                        placeholder="Last Name"
                                        onChange={(e) => {
                                            handleFieldChange("last_name", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="last_name">Last Name<span className="text-danger">*</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        required
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData?.email}
                                        placeholder="email"
                                        onChange={(e) => {
                                            handleFieldChange("email", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="email">Email<span className="text-danger">*</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        required
                                        type="tel"
                                        id="whatsapp"
                                        name="whatsapp"
                                        value={formData?.whatsapp}
                                        placeholder="City"
                                        onChange={(e) => {
                                            handleFieldChange("whatsapp", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="city_in_ind">WhatsApp<span className="text-danger">*</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        required
                                        id="city_in_usa"
                                        name="city_in_usa"
                                        value={formData?.city_in_usa}
                                        placeholder="City"
                                        onChange={(e) => {
                                            handleFieldChange("city_in_usa", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="city_in_usa">City in USA<span className="text-danger">*</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        required
                                        id="city_in_ind"
                                        name="city_in_ind"
                                        value={formData?.city_in_ind}
                                        placeholder="City"
                                        onChange={(e) => {
                                            handleFieldChange("city_in_ind", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="city_in_ind">City in India<span className="text-danger">*</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        type={`url`}
                                        id="social_link"
                                        name="social_link"
                                        value={formData?.social_link}
                                        placeholder="City"
                                        onChange={(e) => {
                                            handleFieldChange("social_link", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="social_link">Social Link (if any)</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className="form-floating">
                                    <Form.Control
                                        id="interested_groups"
                                        name="interested_groups"
                                        value={formData?.interested_groups}
                                        placeholder="Title"
                                        onChange={(e) => {
                                            handleFieldChange("interested_groups", e.target.value);
                                        }}
                                    />
                                    <Form.Label htmlFor="interested_groups">Groups interested to join</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Button type="submit" className="btn add-button float-end">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default JoinRequestModal;
