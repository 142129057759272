import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import {
  Calendar2Date,
  PencilSquare,
  PlusCircle,
  Trash,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import PostsSidebar from "../../components/postsSidebar/postsSidebar";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import FormattedDate from "../../components/timestampConverters/createdDateFormatter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import BlogPlaceholdImg from "../../img/blog.jpg";
import "./blogs.css";

const Blogs = () => {
  const navigate = useNavigate();

  const myToken = localStorage.getItem("token");
  const isValidUser = localStorage.getItem("isValidUser");
  const tokenDetails = TokenDetails();
  const userData = useUserData();
  const role = userData.role;
  const myUserId = tokenDetails?.Email;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(8);
  const entities = "blogs";

  const populateList = async () => {
    setLoadingFilter(true);
    if (items?.length > 0) return;

    try {
      const response = await API("GET", `/${entities}`);
      setItems(response);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}${entities}/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                populateList();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  // Define regular expressions for filtering
  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= items.length
          ? prevDisplayedCards + 4
          : items.length
      );
    };

    return (
      <div className="d-flex my-5 pt-3">
        <Container fluid="lg">
          <Row className="mt-4 justify-content-md-start justify-content-center">
            <Col className="mb-3" lg={10} sm={8}>
              <h3 className="pageSubHeading mb-0 text-sm-start text-center text-capitalize">
                Blogs
              </h3>
            </Col>
            {[
              "admin",
              "siteadmin",
              "superadmin",
              "trainer",
              "training-coordinator",
            ].includes(role) ? (
              <Col
                lg={2}
                sm={4}
                className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
              >
                <Link
                  to={`/${entities}/add`}
                  type="button"
                  className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
                >
                  <PlusCircle /> &nbsp; Add
                </Link>
              </Col>
            ) : null}
          </Row>
          {items?.length === 0 ? (
            <p>No data found</p>
          ) : loadingFilter ? (
            <CustomLoader />
          ) : (
            <Row className="mb-4 align-items-start">
              <Col xxl={8}>
                {items.slice(0, displayedCards)?.map((item) => (
                  <Card
                    className="blog-card flex-md-row p-3 border-0 mb-4"
                    key={item?.id}
                  >
                    <Col
                      md={4}
                      className="blog-card-image p-0 overflow-hidden mb-lg-0 mb-2"
                    >
                      <Image
                        loading="lazy"
                        src={item?.listing_image_url ?? BlogPlaceholdImg}
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    </Col>
                    <Col
                      md={8}
                      className="blog-card-content ps-md-3 text-wrap-balance d-flex justify-content-between flex-column"
                    >
                      <div className="content">
                        <span className="chip semi-bg theme-text-2 rounded-pill text-capitalize mb-3 d-block w-fit">
                          {item?.type}
                        </span>
                        <Link
                          to={`/${entities}/details/${item?.id}`}
                          className="blog-title text-dark text-decoration-none"
                        >
                          <h5 className="blog-title">{item?.title}</h5>
                        </Link>
                        <p className="text-overflow-desc blog-desc">
                          {item?.twoLinerIntro || item?.blogContent}
                        </p>
                      </div>
                      <Row className="blog-time">
                        <Col
                          md={6}
                          className="d-flex mt-3 align-items-center justify-content-between"
                        >
                          <div className="time opacity-75 d-flex align-items-center">
                            <Calendar2Date />
                            &nbsp;
                            <FormattedDate createdDate={item?.createdDate} />
                          </div>
                        </Col>
                        <Col
                          md={6}
                          className="d-flex mt-3 flex-column flex-wrap justify-content-end gap-2"
                        >
                          <div className="d-flex flex-wrap justify-content-end align-items-center gap-2">
                            {role === "admin" ||
                            role === "siteadmin" ||
                            role === "superadmin" ||
                            item?.userId?.toLowerCase() === myUserId ? (
                              <>
                                <Link
                                  to={`/${entities}/edit/${item?.id}`}
                                  className="btn-link p-1 theme-text"
                                  title="Edit Blog"
                                >
                                  <PencilSquare size={20} />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn-link p-1 text-danger"
                                  onClick={() => deleteItem(item?.id)}
                                  title="Delete blog"
                                >
                                  <Trash size={20} />
                                </Link>
                              </>
                            ) : null}
                            <Link
                              to={`/${entities}/details/${item?.id}`}
                              className="add-button btn d-flex align-items-center justify-content-center text-center cursor-pointer"
                            >
                              Read Blog
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                ))}
              </Col>
              <Col xxl={4} className="d-none d-xxl-block">
                <PostsSidebar entityItems={items} />
              </Col>
            </Row>
          )}

          {!loading && !loadingFilter && displayedCards < items.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={() =>
                  isValidUser && myToken
                    ? handleShowMore()
                    : navigate("/my-profile")
                }
                className="btn actionBtnMore action cursor-pointer w-auto add-button"
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      </div>
    );
  };

  const siteName = SubDomainRouter("title");
  return (
    <>
      <Helmet>
        <title>Blogs | {siteName ? siteName : window.location.host}</title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
};

export default Blogs;
