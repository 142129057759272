import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../../constants/baseUrl";

const SuperAdminUsersAdd = () => {
  const navigate = useNavigate();
  const myToken = localStorage.getItem("token");

  const [reqLoading, setReqLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Role: "",
    Email: "",
    Phone: "",
    Password: "Welcome@1",
    host: window.location.host,
  });

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        await axios
          .get(`${Actions.BASE_URL}configs`, {
            headers: {
              Authorization: `Bearer ${myToken}`,
            },
          })
          .then((response) => {
            setUserRoles(response.data?.appsettingsarrayobjects?.userRole);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoles();
  }, [myToken]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);
    const data = {
      ...formData,
      FullName: `${formData.FirstName} ${formData.LastName}`,
      username: `${formData.Email}_${formData.host}`,
    };

    axios
      .post(`${Actions.AUTH_URL}Users/PostUser`, data)
      .then((res) => {
        navigate(`/super-admin-dashboard/users`);
        setReqLoading(false);
      })
      .catch((error) => {
        setReqLoading(false);
      });
  };

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Container fluid="lg" className="py-5 mt-5 profile-edit">
        <h3 className="heading-text theme-text-2 text-center pt-3">
          Add New User
        </h3>
        <div className="demosAddForm">
          <Form className="row" onSubmit={handleSubmit}>
            <Col md={6}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Type"
                  value={formData?.FirstName}
                  onChange={(e) =>
                    handleFieldChange("FirstName", e.target.value)
                  }
                />
                <label htmlFor="first_name">First Name</label>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Type"
                  value={formData?.LastName}
                  onChange={(e) =>
                    handleFieldChange("LastName", e.target.value)
                  }
                />
                <label htmlFor="last_name">Last Name</label>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-floating mb-3">
                <Form.Select
                  id="role"
                  required
                  onChange={(e) => handleFieldChange("Role", e.target.value)}
                >
                  <option value="">Select Role</option>
                  {userRoles?.map((userRole, index) => (
                    <option
                      value={userRole?.key}
                      key={index}
                      className="text-capitalize"
                    >
                      {userRole.value}
                    </option>
                  ))}
                  <option value={"admin"} className="text-capitalize">
                    Admin
                  </option>
                </Form.Select>
                <Form.Label htmlFor="select">Create Account As</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData?.Email}
                  onChange={(e) => handleFieldChange("Email", e.target.value)}
                />
                <label htmlFor="email">Email</label>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  value={formData?.Phone}
                  onChange={(e) => handleFieldChange("Phone", e.target.value)}
                />
                <label htmlFor="phone">Phone</label>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="tel"
                  id="host"
                  name="host"
                  placeholder="host"
                  value={formData?.host}
                  onChange={(e) => handleFieldChange("host", e.target.value)}
                />
                <label htmlFor="host">Host</label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <small>
                The user will be added with a default Password "Welcome@1"
              </small>
            </Col>
            <Col
              lg={12}
              className="d-flex justify-content-between flex-wrap gap-2"
            >
              <Link
                to={`/super-admin-dashboard/users`}
                className="btn add-button prev w-auto"
                title="Cancel edit"
              >
                Discard
              </Link>
              <Button type="submit" className="add-button next w-auto">
                Update
              </Button>
            </Col>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default SuperAdminUsersAdd;
