import React from "react";
import { convertDateTimeToLocal } from "../../helper/timezoneConversions";

const UTCLocalTimeConverter = ({ utcDateTime, zone = false, showDate = false, showTime = true }) => {
  const { localDate, localTime } = convertDateTimeToLocal(utcDateTime);


  const formattedLocalDate = new Date(localDate)?.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: '2-digit' });

  const userTimeZone = Intl.DateTimeFormat(undefined, {
    timeZoneName: "long",
  })
    ?.formatToParts(new Date(`${localDate} ${localTime}`))
    ?.find((part) => part.type === "timeZoneName")?.value;

  return (
    <>
      <span>{showDate && formattedLocalDate} {showTime && showDate ? "-" : null} {showTime && localTime}</span>
      {zone && (
        <span className="flex-grow-1 w-100 m-0 d-block">
          (<b className="text-nowrap">{userTimeZone}</b>)
        </span>
      )}

    </>
  );
};

export default UTCLocalTimeConverter;
